import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { enableRipple } from '@syncfusion/ej2-base';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';//added one
import { JsonPipe } from '@angular/common';
enableRipple(true);
@Component({
  selector: 'app-add-feature',
  templateUrl: './add-feature.component.html',
  styleUrls: ['./add-feature.component.scss'],
  // template:``
})

export class AddFeatureComponent implements OnInit {

  @Input() value;
  @ViewChild('userFeatureTree', { static: false }) userFeatureTree: jqxTreeComponent;
  mainMenuOverall: { default: string[]; Nonshow: string[]; Dashboard: string[]; Reports: { Employee_No_transmission_report: string; Employee_Monthly_Discount_Report: string; Employee_Idle_Status_Report: string; Employee_Day_Off_Report: string; Workout_Of_Zone_Report: string; No_Transmission_EmpOld_report: string; }; Manage: { employeesManage: string; supervisorAdvance: string; Alert_Configuration: string; geofenceConAdvance: string; geofenceAssAdvance: string; simAdvance: string; shiftDetailsManage: string; deviceAdvance: string; profileAdvance: string; Delete: string; }; };
  treeFormation = [];
  source: { datatype: string; datafields: { name: string; }[]; id: string; localdata: any; };
  dataAdapter: any;
  records: any;
  userCheckChange = []

  constructor(private modalController: ModalController,
    private ajaxService: AjaxService,
    private commonService: CommonService, private http: HttpClient
  ) { }

  getBack() {
    this.modalController.dismiss();
  }


  submitData() {
    let defaultArray = [];
    defaultArray = [...this.mainMenuOverall['default'], ...this.mainMenuOverall['Nonshow'],...this.mainMenuOverall['Dashboard']];
    let mainMenuFinalArray = []
    let checkedItem = this.userFeatureTree.getCheckedItems()
    console.log(checkedItem)
    let isMultiTrackPresent = false;
    // 
    for (let i = 0; i < checkedItem.length; i++) {
      if (checkedItem[i].value === "Multi Track") {
        isMultiTrackPresent = true;
        if (!defaultArray.includes(checkedItem[i].value))
          mainMenuFinalArray.push(checkedItem[i].value);
      } else {
        if (!defaultArray.includes(checkedItem[i].value)) {
          mainMenuFinalArray.push(checkedItem[i].value);
        }
      }
    }
    if (!isMultiTrackPresent) {
      defaultArray = defaultArray.filter(item => item !== "Multi Track");
    }
    defaultArray = [...defaultArray, ...mainMenuFinalArray];
    let available = false;

 if(this.value.roleName == "CompanyAdmin"){
      for (let i = 0; i < Object.keys(this.mainMenuOverall["Manage"]).length; i++) {
        if (defaultArray.includes(Object.keys(this.mainMenuOverall["Manage"])[i]))
          available = true;
      }
    }


    if (available == false) {
      defaultArray.splice(defaultArray.indexOf("Manage"), 1);
    }
    this.value["mainMenu"] = JSON.stringify(defaultArray);
    this.value["oldValue"] = JSON.stringify({ mainMenu: this.userCheckChange })
    this.value["userId"] = localStorage.userName;
    this.value["macIp"] = ""
    console.log(mainMenuFinalArray)
    let url = serverUrl.web + "/user/setUserMainMenu";
    this.ajaxService.ajaxPutMethod(url, this.value)
      .subscribe(res => {
        if (res == "success") {
          this.commonService.presentToast("Updated successfully")
          this.modalController.dismiss();
        }
        console.log(res)
      })
  }
  getMainMenuPrefercences() {
    let url = serverUrl.web + `/global/getUserFeutures?companyId=${this.value.companyId}&userId=${this.value.email}`
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.mainMenuOverall = res;
        this.formTreeStructure();
      })
  }

  selectChangedata(event) {
    if (!this.userCheckChange.includes(event.args.element.id) && (!this.mainMenuOverall[event.args.element.id] && !Array.isArray(this.mainMenuOverall[event.args.element.id])) && this.value.email != event.args.element.id)
      this.userCheckChange.push(event.args.element.id)
  }

  formTreeStructure() {
    let treeFormation = []
    treeFormation.push({
      id: this.value.email,
      parentid: -1,
      text: this.value.email.toUpperCase(),
      value: this.value.email.toUpperCase()
    })
    for (let i = 0; i < this.mainMenuOverall.default.length; i++) {
      treeFormation.push({
        id: this.mainMenuOverall.default[i],
        parentid: this.value.email,
        text: this.mainMenuOverall.default[i],
        value: this.mainMenuOverall.default[i]
      })
      if (this.mainMenuOverall[this.mainMenuOverall.default[i]] && !Array.isArray(this.mainMenuOverall[this.mainMenuOverall.default[i]])) {
        for (let j = 0; j < Object.keys(this.mainMenuOverall[this.mainMenuOverall.default[i]]).length; j++) {
          treeFormation.push({
            id: Object.keys(this.mainMenuOverall[this.mainMenuOverall.default[i]])[j],
            parentid: this.mainMenuOverall.default[i],
            text: Object.values(this.mainMenuOverall[this.mainMenuOverall.default[i]])[j],
            value: Object.keys(this.mainMenuOverall[this.mainMenuOverall.default[i]])[j]
          })
        }
      }
    }
    this.treeFormation = treeFormation;
    console.log(treeFormation)
    this.source = {
      datatype: 'json',
      datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
      id: 'id',
      localdata: this.treeFormation
    }
    this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);

    setTimeout(() => {
      this.checkOverAll();
    }, 2000)
  }

  checkOverAll() {
    let main = JSON.parse(this.value.mainMenu)
    for (let i = 0; i < main.length; i++) {
      let selectedData = document.getElementById(main[i])
      if (!this.mainMenuOverall[main[i]] && !Array.isArray(this.mainMenuOverall[main[i]]))
        this.userFeatureTree.checkItem(selectedData, true);
      if (this.mainMenuOverall["default"].includes(main[i]) && main[i] != "Multi Track" && (!this.mainMenuOverall[main[i]] || Array.isArray(this.mainMenuOverall[main[i]])))
        this.userFeatureTree.disableItem(selectedData)
    }


    if (this.value.roleName == "CompanyAdmin") {
      let value = Object.keys(this.mainMenuOverall.Manage);
      let selectedArray = [];

      for (let i = 0; i < value.length; i++) {
        for (let j = 0; j < main.length; j++) {
          if (value[i] === main[j]) {
            selectedArray.push(value[i])
          }
        }
      }
 
      for (let i = 0; i < value.length; i++) {
        let selectedData = document.getElementById(selectedArray[i]);
        this.userFeatureTree.disableItem(selectedData)
      }
    }




    this.userFeatureTree.expandAll();
    this.userCheckChange = [];
    this.commonService.dismissLoader()
  }

  ngOnInit() {
    this.commonService.presentLoader()
    this.getMainMenuPrefercences();
  }


}
