import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-rac',
  templateUrl: './rac.component.html',
  styleUrls: ['./rac.component.scss'],
})
export class RACComponent implements OnInit {

  // @Input() rac;
  token;
  sanToken: SafeResourceUrl;

  constructor(private modalController: ModalController,
    private navParams: NavParams,
    private sanitizer: DomSanitizer) { }

  closeModel() {
    this.modalController.dismiss();
  }
   sanitizedToken(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.token);
  }
  

  ngOnInit() {
    const racParam = this.navParams.get('rac');
    if (racParam) {
      this.token = 'https://ge-fleeton.thegoldenelement.com/#/airportadmin/' + racParam;
      this.sanToken = this.sanitizedToken();
    } else {
      console.error('Invalid or missing rac parameter');
    }
    
  }
  

}
