import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { app, languageInitializer } from 'src/environments/environment';
import { CommonService } from './common.service.js';
// import * as logo from './mylogo.js';
import { PdfLogoService } from './pdf-logo.service.js';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(
    private pdfLogo: PdfLogoService,
    private trans: TranslateService,
    private commonServices: CommonService
  ) { }
  exportExcel(excelData) {
    //Title, Header & Data
    excelData.headers = excelData.headers.map(res => this.trans.instant(res))
    if (languageInitializer.selectedLang == "ar" && excelData.title != "Consolidate summary report") {
      excelData.headers = excelData.headers.reverse();
      excelData.data = excelData.data.map(ra => ra.slice().reverse());
    }
    if (languageInitializer.selectedLang == "ar" && excelData.title == "Consolidate summary report" && excelData.overspeed.length != 0) {
      excelData.headersosp = excelData.headersosp.map(res => this.trans.instant(res))
    }
    if (languageInitializer.selectedLang == "ar" && excelData.title == "Consolidate summary report" && excelData.harshbraking.length != 0) {
      excelData.headershb = excelData.headershb.map(res => this.trans.instant(res))
    }
    if (languageInitializer.selectedLang == "ar" && excelData.title == "Consolidate summary report" && excelData.harshacceleration.length != 0) {
      excelData.headersha = excelData.headersha.map(res => this.trans.instant(res))
    }
    if (languageInitializer.selectedLang == "ar" && excelData.title == "Consolidate summary report" && excelData.drift.length != 0) {
      excelData.headersd = excelData.headersd.map(res => this.trans.instant(res))
    }
    if (languageInitializer.selectedLang == "ar" && excelData.title == "Consolidate summary report" && excelData.seatbelt.length != 0) {
      excelData.headerssb = excelData.headerssb.map(res => this.trans.instant(res))
    }
    let tableData = excelData.data1 && excelData.data1.length > 0 ? excelData.data1 : excelData.data;
    let convertedTableData = [];
    for (let i = 0; i < tableData.length; i++) {
      let row = [];
      for (let key in tableData[i]) {
        row.push(tableData[i][key]);
      }
      convertedTableData.push(row);
    }
    const title = this.trans.instant(excelData.title);
    const header = excelData.headers;
    const data = convertedTableData;
    const dataosp = excelData.overspeed
    const headerosp = excelData.headersosp
    const datahb = excelData.harshbraking
    const headerhb = excelData.headershb
    const dataha = excelData.harshacceleration
    const headerha = excelData.headersha
    const datad = excelData.drift
    const headerd = excelData.headersd
    const datasb = excelData.seatbelt
    const headersb = excelData.headerssb

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    if (title === "Consolidate summary report" || title === "تقرير ملخص") {

      let newWorksheet = workbook.addWorksheet(this.trans.instant('Content'));
      newWorksheet.addRow([""]);
      newWorksheet.addRow([""]);
      newWorksheet.addRow([""]);
      newWorksheet.addRow([""]);
      newWorksheet.addRow([""]);
      newWorksheet.addRow([""]);
      newWorksheet.addRow([this.trans.instant("Summary")]);
      newWorksheet.addRow([this.trans.instant("Harshacceleration")]);
      newWorksheet.addRow([this.trans.instant("Harshbraking")]);
      newWorksheet.addRow([this.trans.instant("Overspeed")]);
      newWorksheet.addRow([this.trans.instant("Seatbelt")]);
      newWorksheet.addRow([this.trans.instant("Drift")]);

      // Styling for the summary data
    }
    if (title === "Consolidate summary report" || title === "تقرير ملخص") {
      let Statistic = workbook.addWorksheet(this.trans.instant('Statistics'));
      Statistic.addRow([this.trans.instant('Report'), this.trans.instant('Consolidate summary report')]);
      Statistic.addRow([this.trans.instant('Group'), localStorage.corpId]);
      Statistic.addRow([this.trans.instant('Interval begining'), excelData.fromdate]);
      Statistic.addRow([this.trans.instant('Interval end'), excelData.todate]);

      Statistic.getColumn(1).width = 20;
      Statistic.getColumn(2).width = 20;
      Statistic.getColumn(3).width = 20;
      Statistic.getColumn(4).width = 20;
    }
    let worksheet;
    if (title === "Consolidate summary report" || title === "تقرير ملخص") {
      worksheet = workbook.addWorksheet(this.trans.instant("Summary"));
    } else {
      worksheet = workbook.addWorksheet(title);
    }



    //Add Row and formatting
    worksheet.mergeCells('C1', 'F4');
    let titleRow = worksheet.getCell('C1');
    if (title === "Consolidate summary report" || title === "تقرير ملخص"){
      titleRow.value = this.trans.instant("Summary")
    }
    else{
      titleRow.value = title
    }
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }



    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        //text :'capitalize',
        size: 12
      }
    })

    // Date
    // worksheet.mergeCells('G2:H2');
    // let d = new Date();
    // let n = d.toLocaleDateString();
    // let date = this.trans.instant("Date") +": " + this.commonServices.numberToArabic(n);
    // let dateCell = worksheet.getCell('G2');
    // dateCell.value = date;
    // dateCell.font = {
    //   name: 'Calibri',
    //   size: 12,
    //   bold: true
    // }
    // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    if (localStorage.pdfDownloadLogo != "hideLogo") {
      let myLogoImage;
      if(localStorage.pdfDownloadLogo.includes("base64")){
        myLogoImage = workbook.addImage({
          base64: localStorage.pdfDownloadLogo,
          extension: 'png',
        });
      }else{
        myLogoImage = workbook.addImage({
          base64: this.pdfLogo.imgdata[localStorage.pdfDownloadLogo],
          extension: 'png',
        });
      }
      
      worksheet.mergeCells('B1:A4');
      let imgCell = worksheet.getCell('B1:C4');
      imgCell.alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.addImage(myLogoImage, 'B1:A4');
    }

    let corp = worksheet.getCell('G1');
    let user = worksheet.getCell('G2');
    let corpval = worksheet.getCell('H1');
    let userval = worksheet.getCell('H2');
    let from = worksheet.getCell('G3');
    let to = worksheet.getCell('G4');
    corp.value = this.trans.instant("Company id");
    user.value = this.trans.instant("User Id");
    corpval.value = localStorage.corpId
    userval.value = localStorage.userName

    if ((title.includes("report") || title.includes("Report")) && title != "Temperature Report") {
      let fromval = worksheet.getCell('H3');
      let toval = worksheet.getCell('H4');
      from.value =this.trans.instant("Form Date") 
      to.value = this.trans.instant("To Date") 
      fromval.value = excelData.fromdate.split(' ')[0]
      toval.value = excelData.todate.split(' ')[0]
    }else if(title == "Temperature Report"){
      let fromval = worksheet.getCell('H3');
        from.value =this.trans.instant("Form Date")
        fromval.value = excelData.fromdate.split(' ')[0]
    }



    //Blank Row 

    worksheet.addRow([]);
    //Adding Data with Conditional Formatting

    if (title === "Consolidate summary report" || title === "تقرير ملخص") {
      let plateData1 = [];
      data.map(d => {
        let plateNumber = d[0];
        let plateInfo = {
          plateno: plateNumber ,
          date: excelData.fromdate + " to " + excelData.todate,
          odometer: 0,
          alertcount: 0,
          maxSpeed: 0,
          runningDuration1: 0,
          stopDuration1: 0,
          idleDuration1: 0,
          towedDuration1: 0,
          runningDuration: 0,
          stopDuration: 0,
          idleDuration: 0,
          towedDuration: 0,
        };

        let plateIndex = plateData1.findIndex(item => item.plateno === plateNumber);

        if (plateIndex === -1) {
          plateData1.push(plateInfo);
          plateIndex = plateData1.length - 1;
        }

        plateData1[plateIndex].count++;
        plateData1[plateIndex].maxSpeed = Math.max(plateData1[plateIndex].maxSpeed, Number(d[4]));
        plateData1[plateIndex].runningDuration += this.convertTimeToSeconds(d[5]);
        plateData1[plateIndex].stopDuration += this.convertTimeToSeconds(d[6]);
        plateData1[plateIndex].idleDuration += this.convertTimeToSeconds(d[7]);
        plateData1[plateIndex].towedDuration += this.convertTimeToSeconds(d[8]);
        plateData1[plateIndex].odometer += Number(d[2]);
        plateData1[plateIndex].alertcount += Number(d[3]);
        plateData1[plateIndex].runningDuration1 = this.convertSecondsToDHMS(plateData1[plateIndex].runningDuration);
        plateData1[plateIndex].stopDuration1 = this.convertSecondsToDHMS(plateData1[plateIndex].stopDuration);
        plateData1[plateIndex].idleDuration1 = this.convertSecondsToDHMS(plateData1[plateIndex].idleDuration);
        plateData1[plateIndex].towedDuration1 = this.convertSecondsToDHMS(plateData1[plateIndex].towedDuration);
      });

      plateData1.map(plate => {
        data.map(d => {
          if (d[0] === plate.plateno) {
            let dataRow = worksheet.addRow(d);
            dataRow.outlineLevel = 1;
            dataRow.hidden = true;
          }
        });

        let groupRow = worksheet.addRow([plate.plateno, plate.date, plate.odometer, plate.alertcount, plate.maxSpeed, plate.runningDuration1, plate.stopDuration1, plate.idleDuration1, plate.towedDuration1]);
        groupRow.outlineLevel = 0;
        groupRow.eachCell({ includeEmpty: true }, (cell) => {

          cell.font = {
            bold: true,
            size: 12
          }
        });

       
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (row.outlineLevel === 1) {
            let groupSymbolCell = worksheet.getCell(`A${rowNumber}`);
            row.hidden = true;
          }
        });

      });
    } else {
      data.map(d => {
        let row = worksheet.addRow(d);
      }
      );
    }


    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    //overspeed consolidate report
    if ((title === "Consolidate summary report" && dataosp.length != 0) || (title === "تقرير ملخص" && dataosp.length != 0)) {

      let Overspeed = workbook.addWorksheet(this.trans.instant('OVERSPEED'));
      Overspeed.mergeCells('C1', 'F4');
      let titleRow = Overspeed.getCell('C1');
      titleRow.value = this.trans.instant("OVERSPEED")
      titleRow.font = {
        name: 'Calibri',
        size: 16,
        underline: 'single',
        bold: true,
        color: { argb: '0085A3' }
      }
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

      //Adding Header Row
      let headerRow = Overspeed.addRow(headerosp);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          //text :'capitalize',
          size: 12
        }
      })

      //Add Image
      if (localStorage.pdfDownloadLogo != "hideLogo") {
        let myLogoImage;
        if(localStorage.pdfDownloadLogo.includes("base64")){
          myLogoImage = workbook.addImage({
            base64: localStorage.pdfDownloadLogo,
            extension: 'png',
          });
        }else{
          myLogoImage = workbook.addImage({
            base64: this.pdfLogo.imgdata[localStorage.pdfDownloadLogo],
            extension: 'png',
          });
        }
        Overspeed.mergeCells('B1:A4');
        let imgCell = Overspeed.getCell('B1:C4');
        imgCell.alignment = { vertical: 'middle', horizontal: 'center' }
        Overspeed.addImage(myLogoImage, 'B1:A4');
      }

      //Blank Row 

      Overspeed.addRow([]);
      //Adding Data with Conditional Formatting

      let plateData1 = [];
      dataosp.map(d => {

        let plateNumber = d[0];
        let plateInfo = {
          plateno: plateNumber ,
          group: "",
          operator: "",
          date: excelData.fromdate + " to " + excelData.todate,
          ViolationPlace: "",
          ViolationCount: 0,
          ViolationSpeed: "",
          ViolationTime: "",
          maxSpeed: 0,
        };

        let plateIndex = plateData1.findIndex(item => item.plateno === plateNumber);

        if (plateIndex === -1) {
          plateData1.push(plateInfo);
          plateIndex = plateData1.length - 1;
        }

        plateData1[plateIndex].count++;
        plateData1[plateIndex].group = d[1]
        plateData1[plateIndex].operator = d[2]
        plateData1[plateIndex].maxSpeed = Math.max(plateData1[plateIndex].maxSpeed, Number(d[6]));
        if (plateData1[plateIndex].ViolationSpeed < Number(d[6])) {
          plateData1[plateIndex].ViolationSpeed = Number(d[6]);
          plateData1[plateIndex].ViolationPlace = d[4];
          plateData1[plateIndex].ViolationTime = d[7];
        }
        plateData1[plateIndex].ViolationCount += Number(d[5]);
      });


      plateData1.map(plate => {
        dataosp.map(d => {
          if (d[0] === plate.plateno) {
            let dataRow = Overspeed.addRow(d);
            dataRow.outlineLevel = 1;
            dataRow.hidden = true;
          }
        });

        let groupRow = Overspeed.addRow([plate.plateno, plate.group, plate.operator, plate.date, plate.ViolationPlace, plate.ViolationCount, plate.ViolationSpeed, plate.ViolationTime, plate.maxSpeed]);
        groupRow.outlineLevel = 0;
        groupRow.eachCell({ includeEmpty: true }, (cell) => {

          cell.font = {
            bold: true,
            size: 12
          }
        });

       
        Overspeed.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (row.outlineLevel === 1) {
            let groupSymbolCell = Overspeed.getCell(`A${rowNumber}`);
            row.hidden = true;
          }
        });

      });

      Overspeed.getColumn(1).width = 20;
      Overspeed.getColumn(2).width = 20;
      Overspeed.getColumn(3).width = 20;
      Overspeed.getColumn(4).width = 30;
      Overspeed.getColumn(5).width = 20;
      Overspeed.getColumn(6).width = 20;
      Overspeed.getColumn(7).width = 20;
      Overspeed.getColumn(8).width = 20;
      Overspeed.getColumn(9).width = 20;
      Overspeed.getColumn(10).width = 20;
      Overspeed.getColumn(11).width = 20;
      Overspeed.getColumn(12).width = 20;
    }
    if ((title === "Consolidate summary report" && datahb.length != 0) || (title === "تقرير ملخص" && datahb.length != 0)) {
      let harshbraking = workbook.addWorksheet(this.trans.instant('HARSHBRAKING'));
      harshbraking.mergeCells('C1', 'F4');
      let titleRow = harshbraking.getCell('C1');
      titleRow.value = this.trans.instant("HARSHBRAKING")
      titleRow.font = {
        name: 'Calibri',
        size: 16,
        underline: 'single',
        bold: true,
        color: { argb: '0085A3' }
      }
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

      //Adding Header Row
      let headerRow = harshbraking.addRow(headerhb);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          //text :'capitalize',
          size: 12
        }
      })

      //Add Image
      if (localStorage.pdfDownloadLogo != "hideLogo") {
        let myLogoImage;
      if(localStorage.pdfDownloadLogo.includes("base64")){
        myLogoImage = workbook.addImage({
          base64: localStorage.pdfDownloadLogo,
          extension: 'png',
        });
      }else{
        myLogoImage = workbook.addImage({
          base64: this.pdfLogo.imgdata[localStorage.pdfDownloadLogo],
          extension: 'png',
        });
      }
        harshbraking.mergeCells('B1:A4');
        let imgCell = harshbraking.getCell('B1:C4');
        imgCell.alignment = { vertical: 'middle', horizontal: 'center' }
        harshbraking.addImage(myLogoImage, 'B1:A4');
      }

      //Blank Row 

      harshbraking.addRow([]);
      //Adding Data with Conditional Formatting
      let plateData1 = [];
      datahb.map(d => {

        let plateNumber = d[0];
        let plateInfo = {
          plateno: plateNumber ,
          group: "",
          operator: "",
          date: excelData.fromdate + " to " + excelData.todate,
          ViolationPlace: "",
          ViolationCount: 0,
          ViolationSpeed: "",
          ViolationTime: "",
          maxSpeed: 0,
        };

        let plateIndex = plateData1.findIndex(item => item.plateno === plateNumber);

        if (plateIndex === -1) {
          plateData1.push(plateInfo);
          plateIndex = plateData1.length - 1;
        }

        plateData1[plateIndex].count++;
        plateData1[plateIndex].group = d[1]
        plateData1[plateIndex].operator = d[2]
        plateData1[plateIndex].maxSpeed = Math.max(plateData1[plateIndex].maxSpeed, Number(d[6]));
        if (plateData1[plateIndex].ViolationSpeed < Number(d[6])) {
          plateData1[plateIndex].ViolationSpeed = Number(d[6]);
          plateData1[plateIndex].ViolationPlace = d[4];
          plateData1[plateIndex].ViolationTime = d[7];
        }
        plateData1[plateIndex].ViolationCount += Number(d[5]);
      });

      plateData1.map(plate => {
        datahb.map(d => {
          if (d[0] === plate.plateno) {
            let dataRow = harshbraking.addRow(d);
            dataRow.outlineLevel = 1;
            dataRow.hidden = true;
          }
        });

        let groupRow = harshbraking.addRow([plate.plateno, plate.group, plate.operator, plate.date, plate.ViolationPlace, plate.ViolationCount, plate.ViolationSpeed, plate.ViolationTime, plate.maxSpeed]);
        groupRow.outlineLevel = 0;
        groupRow.eachCell({ includeEmpty: true }, (cell) => {

          cell.font = {
            bold: true,
            size: 12
          }
        });

        
        harshbraking.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (row.outlineLevel === 1) {
            let groupSymbolCell = harshbraking.getCell(`A${rowNumber}`);
            row.hidden = true;
          }
        });

      });


      harshbraking.getColumn(1).width = 20;
      harshbraking.getColumn(2).width = 20;
      harshbraking.getColumn(3).width = 20;
      harshbraking.getColumn(4).width = 30;
      harshbraking.getColumn(5).width = 20;
      harshbraking.getColumn(6).width = 20;
      harshbraking.getColumn(7).width = 20;
      harshbraking.getColumn(8).width = 20;
      harshbraking.getColumn(9).width = 20;
      harshbraking.getColumn(10).width = 20;
      harshbraking.getColumn(11).width = 20;
      harshbraking.getColumn(12).width = 20;
    }
    if ((title === "Consolidate summary report" && dataha.length != 0) || (title === "تقرير ملخص" && dataha.length != 0)) {
      let Harshaccelerarion = workbook.addWorksheet(this.trans.instant('HARSHACCELERATION'));
      Harshaccelerarion.mergeCells('C1', 'F4');
      let titleRow = Harshaccelerarion.getCell('C1');
      titleRow.value = this.trans.instant("HARSHACCELERATION")
      titleRow.font = {
        name: 'Calibri',
        size: 16,
        underline: 'single',
        bold: true,
        color: { argb: '0085A3' }
      }
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

      //Adding Header Row
      let headerRow = Harshaccelerarion.addRow(headerha);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          //text :'capitalize',
          size: 12
        }
      })

      //Add Image
      if (localStorage.pdfDownloadLogo != "hideLogo") {
        let myLogoImage;
      if(localStorage.pdfDownloadLogo.includes("base64")){
        myLogoImage = workbook.addImage({
          base64: localStorage.pdfDownloadLogo,
          extension: 'png',
        });
      }else{
        myLogoImage = workbook.addImage({
          base64: this.pdfLogo.imgdata[localStorage.pdfDownloadLogo],
          extension: 'png',
        });
      }
        Harshaccelerarion.mergeCells('B1:A4');
        let imgCell = Harshaccelerarion.getCell('B1:C4');
        imgCell.alignment = { vertical: 'middle', horizontal: 'center' }
        Harshaccelerarion.addImage(myLogoImage, 'B1:A4');
      }

      //Blank Row 

      Harshaccelerarion.addRow([]);
      //Adding Data with Conditional Formatting
      let plateData1 = [];
      dataha.map(d => {
        let plateNumber = d[0];
        let plateInfo = {
          plateno: plateNumber ,
          group: "",
          operator: "",
          date: excelData.fromdate + " to " + excelData.todate,
          ViolationPlace: "",
          ViolationCount: 0,
          ViolationSpeed: "",
          ViolationTime: "",
          maxSpeed: 0,
        };

        let plateIndex = plateData1.findIndex(item => item.plateno === plateNumber);

        if (plateIndex === -1) {
          plateData1.push(plateInfo);
          plateIndex = plateData1.length - 1;
        }

        plateData1[plateIndex].count++;
        plateData1[plateIndex].group = d[1]
        plateData1[plateIndex].operator = d[2]
        plateData1[plateIndex].maxSpeed = Math.max(plateData1[plateIndex].maxSpeed, Number(d[6]));
        if (plateData1[plateIndex].ViolationSpeed < Number(d[6])) {
          plateData1[plateIndex].ViolationSpeed = Number(d[6]);
          plateData1[plateIndex].ViolationPlace = d[4];
          plateData1[plateIndex].ViolationTime = d[7];
        }
        plateData1[plateIndex].ViolationCount += Number(d[5]);
      });


      plateData1.map(plate => {
        dataha.map(d => {
          if (d[0] === plate.plateno) {
            let dataRow = Harshaccelerarion.addRow(d);
            dataRow.outlineLevel = 1;
            dataRow.hidden = true;
          }
        });
        let groupRow = Harshaccelerarion.addRow([plate.plateno, plate.group, plate.operator, plate.date, plate.ViolationPlace, plate.ViolationCount, plate.ViolationSpeed, plate.ViolationTime, plate.maxSpeed]);
        groupRow.outlineLevel = 0;
        groupRow.eachCell({ includeEmpty: true }, (cell) => {

          cell.font = {
            bold: true,
            size: 12
          }
        });

       
        Harshaccelerarion.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (row.outlineLevel === 1) {
            let groupSymbolCell = Harshaccelerarion.getCell(`A${rowNumber}`);
            row.hidden = true;
          }
        });

      });


      Harshaccelerarion.getColumn(1).width = 20;
      Harshaccelerarion.getColumn(2).width = 20;
      Harshaccelerarion.getColumn(3).width = 20;
      Harshaccelerarion.getColumn(4).width = 30;
      Harshaccelerarion.getColumn(5).width = 20;
      Harshaccelerarion.getColumn(6).width = 20;
      Harshaccelerarion.getColumn(7).width = 20;
      Harshaccelerarion.getColumn(8).width = 20;
      Harshaccelerarion.getColumn(9).width = 20;
      Harshaccelerarion.getColumn(10).width = 20;
      Harshaccelerarion.getColumn(11).width = 20;
      Harshaccelerarion.getColumn(12).width = 20;
    }
    if ((title === "Consolidate summary report" && datasb.length != 0) || (title === "تقرير ملخص" && datasb.length != 0)) {
      let Seatbelt = workbook.addWorksheet(this.trans.instant('SEATBELT'));
      Seatbelt.mergeCells('C1', 'F4');
      let titleRow = Seatbelt.getCell('C1');
      titleRow.value = this.trans.instant("SEATBELT")
      titleRow.font = {
        name: 'Calibri',
        size: 16,
        underline: 'single',
        bold: true,
        color: { argb: '0085A3' }
      }
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

      //Adding Header Row
      let headerRow = Seatbelt.addRow(headersb);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          //text :'capitalize',
          size: 12
        }
      })

      //Add Image
      if (localStorage.pdfDownloadLogo != "hideLogo") {
        let myLogoImage;
        if(localStorage.pdfDownloadLogo.includes("base64")){
          myLogoImage = workbook.addImage({
            base64: localStorage.pdfDownloadLogo,
            extension: 'png',
          });
        }else{
          myLogoImage = workbook.addImage({
            base64: this.pdfLogo.imgdata[localStorage.pdfDownloadLogo],
            extension: 'png',
          });
        }
        Seatbelt.mergeCells('B1:A4');
        let imgCell = Seatbelt.getCell('B1:C4');
        imgCell.alignment = { vertical: 'middle', horizontal: 'center' }
        Seatbelt.addImage(myLogoImage, 'B1:A4');
      }

      //Blank Row 

      Seatbelt.addRow([]);
      //Adding Data with Conditional Formatting
      let plateData1 = [];
      datasb.map(d => {

        let plateNumber = d[0];
        let plateInfo = {
          plateno: plateNumber,
          group: "",
          operator: "",
          date: excelData.fromdate + " to " + excelData.todate,
          ViolationPlace: "",
          ViolationCount: 0,
          ViolationSpeed: "",
          ViolationTime: "",
          maxSpeed: 0,
        };

        let plateIndex = plateData1.findIndex(item => item.plateno === plateNumber);

        if (plateIndex === -1) {
          plateData1.push(plateInfo);
          plateIndex = plateData1.length - 1;
        }

        plateData1[plateIndex].count++;
        plateData1[plateIndex].group = d[1]
        plateData1[plateIndex].operator = d[2]
        plateData1[plateIndex].maxSpeed = Math.max(plateData1[plateIndex].maxSpeed, Number(d[6]));
        if (plateData1[plateIndex].ViolationSpeed < Number(d[6])) {
          plateData1[plateIndex].ViolationSpeed = Number(d[6]);
          plateData1[plateIndex].ViolationPlace = d[4];
          plateData1[plateIndex].ViolationTime = d[7];
        }
        plateData1[plateIndex].ViolationCount += Number(d[5]);
      });



      plateData1.map(plate => {
        datasb.map(d => {
          if (d[0] === plate.plateno) {
            let dataRow = Seatbelt.addRow(d);
            dataRow.outlineLevel = 1;
            dataRow.hidden = true;
          }
        });
        let groupRow = Seatbelt.addRow([plate.plateno, plate.group, plate.operator, plate.date, plate.ViolationPlace, plate.ViolationCount, plate.ViolationSpeed, plate.ViolationTime, plate.maxSpeed]);
        groupRow.outlineLevel = 0;
        groupRow.eachCell({ includeEmpty: true }, (cell) => {

          cell.font = {
            bold: true,
            size: 12
          }
        });

      
        Seatbelt.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (row.outlineLevel === 1) {
            let groupSymbolCell = Seatbelt.getCell(`A${rowNumber}`);
            row.hidden = true;
          }
        });

      });


      Seatbelt.getColumn(1).width = 20;
      Seatbelt.getColumn(2).width = 20;
      Seatbelt.getColumn(3).width = 20;
      Seatbelt.getColumn(4).width = 30;
      Seatbelt.getColumn(5).width = 20;
      Seatbelt.getColumn(6).width = 20;
      Seatbelt.getColumn(7).width = 20;
      Seatbelt.getColumn(8).width = 20;
      Seatbelt.getColumn(9).width = 20;
      Seatbelt.getColumn(10).width = 20;
      Seatbelt.getColumn(11).width = 20;
      Seatbelt.getColumn(12).width = 20;
    }
    if ((title === "Consolidate summary report" && datad.length != 0) || (title === "تقرير ملخص" && datad.length != 0)) {
      let Drift = workbook.addWorksheet(this.trans.instant('DRIFT'));
      Drift.mergeCells('C1', 'F4');
      let titleRow = Drift.getCell('C1');
      titleRow.value = this.trans.instant("DRIFT")
      titleRow.font = {
        name: 'Calibri',
        size: 16,
        underline: 'single',
        bold: true,
        color: { argb: '0085A3' }
      }
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

      //Adding Header Row
      let headerRow = Drift.addRow(headerd);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          //text :'capitalize',
          size: 12
        }
      })

      //Add Image
      if (localStorage.pdfDownloadLogo != "hideLogo") {
        let myLogoImage;
        if(localStorage.pdfDownloadLogo.includes("base64")){
          myLogoImage = workbook.addImage({
            base64: localStorage.pdfDownloadLogo,
            extension: 'png',
          });
        }else{
          myLogoImage = workbook.addImage({
            base64: this.pdfLogo.imgdata[localStorage.pdfDownloadLogo],
            extension: 'png',
          });
        }
        Drift.mergeCells('B1:A4');
        let imgCell = Drift.getCell('B1:C4');
        imgCell.alignment = { vertical: 'middle', horizontal: 'center' }
        Drift.addImage(myLogoImage, 'B1:A4');
      }

      //Blank Row 

      Drift.addRow([]);
      //Adding Data with Conditional Formatting
      let plateData1 = [];
      datad.map(d => {

        let plateNumber = d[0];
        let plateInfo = {
          plateno: plateNumber ,
          group: "",
          operator: "",
          date: excelData.fromdate + " to " + excelData.todate,
          ViolationPlace: "",
          ViolationCount: 0,
          ViolationSpeed: "",
          ViolationTime: "",
          maxSpeed: 0,
        };

        let plateIndex = plateData1.findIndex(item => item.plateno === plateNumber);

        if (plateIndex === -1) {
          plateData1.push(plateInfo);
          plateIndex = plateData1.length - 1;
        }

        plateData1[plateIndex].count++;
        plateData1[plateIndex].group = d[1]
        plateData1[plateIndex].operator = d[2]
        plateData1[plateIndex].maxSpeed = Math.max(plateData1[plateIndex].maxSpeed, Number(d[6]));
        if (plateData1[plateIndex].ViolationSpeed < Number(d[6])) {
          plateData1[plateIndex].ViolationSpeed = Number(d[6]);
          plateData1[plateIndex].ViolationPlace = d[4];
          plateData1[plateIndex].ViolationTime = d[7];
        }
        plateData1[plateIndex].ViolationCount += Number(d[5]);
      });


      plateData1.map(plate => {
        datad.map(d => {
          if (d[0] === plate.plateno) {
            let dataRow = Drift.addRow(d);
            dataRow.outlineLevel = 1;
            dataRow.hidden = true;
          }
        });

        let groupRow = Drift.addRow([plate.plateno, plate.group, plate.operator, plate.date, plate.ViolationPlace, plate.ViolationCount, plate.ViolationSpeed, plate.ViolationTime, plate.maxSpeed]);
        groupRow.outlineLevel = 0;
        groupRow.eachCell({ includeEmpty: true }, (cell) => {

          cell.font = {
            bold: true,
            size: 12
          }
        });

        
        Drift.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (row.outlineLevel === 1) {
            let groupSymbolCell = Drift.getCell(`A${rowNumber}`);
            row.hidden = true;
          }
        });

      });

      Drift.getColumn(1).width = 20;
      Drift.getColumn(2).width = 20;
      Drift.getColumn(3).width = 20;
      Drift.getColumn(4).width = 30;
      Drift.getColumn(5).width = 20;
      Drift.getColumn(6).width = 20;
      Drift.getColumn(7).width = 20;
      Drift.getColumn(8).width = 20;
      Drift.getColumn(9).width = 20;
      Drift.getColumn(10).width = 20;
      Drift.getColumn(11).width = 20;
      Drift.getColumn(12).width = 20;
    }
    let titledate: any;
    worksheet.addRow([]);
    let date = new Date()
    let formattedDate = date.toISOString().split('T')[0];
    if (title == "Consolidate summary report" || title === "تقرير ملخص") {
      titledate = this.trans.instant(title) + ' ' + formattedDate
    } else {
      titledate = this.trans.instant(title) + ' ' + formattedDate
    }

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, titledate + '.xlsx');
    })

  }
  convertTimeToSeconds(time) {
    let timeParts = time.split(':');
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
    let seconds = parseInt(timeParts[2]);

    return hours * 3600 + minutes * 60 + seconds;

  }
  // Function to convert seconds to Days, Hours, Minutes, and Seconds
  convertSecondsToDHMS(seconds) {
    seconds = Number(seconds);

    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor(seconds / 3600) % 24;
    const minutes = Math.floor(seconds / 60) % 60;
    const secondsOutput = Math.floor(seconds % 60);

    const dayDisplay = days > 0 ? days + (days === 1 ? " day " : " days ") : "";
    const hourDisplay = hours > 0 ? (hours < 10 ? "0" + hours : hours) + (hours === 1 ? "" : "") : "00";
    const minuteDisplay = minutes > 0 ? (minutes < 10 ? "0" + minutes : minutes) + (minutes === 1 ? "" : "") : "00";
    const secondDisplay = secondsOutput > 0 ? (secondsOutput < 10 ? "0" + secondsOutput : secondsOutput) + (secondsOutput === 1 ? "" : "") : "00";


    const formattedTime = `${dayDisplay}${hourDisplay}` + ":" + `${minuteDisplay}` + ":" + `${secondDisplay}`.trim();

    return formattedTime;
  }

}
