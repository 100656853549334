import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { AjaxService } from 'src/app/services/ajax.service';
import { ActivatedRoute } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { serverUrl, app, storageVariable, companyReportData, languageInitializer } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '../../services/translate-config.service';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
@Component({
  selector: 'app-overall-settings',
  templateUrl: './overall-settings.component.html',
  styleUrls: ['./overall-settings.component.scss'],
})
export class OverallSettingsComponent implements OnInit {
  isImmovable:Boolean = false;
  @Input() fromGrid;
  @Input() pageType;
  @Input() gridLiveChange;
  plateNumber;
  data: any;
  watchStatus: boolean;
  googleMap: boolean;
  googleMapEnable: boolean = false;
  notification: boolean;
  liveData: any;
  inItLoading = {
    gridView: false,
    dashboard: false,
    mapView: false
  }
  watchMode = {
    powerCut: false,
    enginStatus: false,
    sos: false
  };
  airCommand = [{ display: "Power off", value: "powerOFF" }, { display: "Vibrator off", value: "vibratorOFF" },
  { display: "Restart", value: "restart" }, { display: "Vibrator on", value: "vibratorON" },
  { display: "Led on", value: "LEDON" }, { display: "Led off", value: "LEDOFF" }]
  // myPlatform: any;
  appName: string;
  isCompanyAdmin: string;
  companyMenuShow: any;
  entryPoint: any;
  dashboardData: any = [];
  @ViewChild("plateNoList", { static: false }) plateNoList: jqxComboBoxComponent;
  @ViewChild("airCmdList", { static: false }) airCmdList: jqxComboBoxComponent;
  harshAcceleration: any = "0.0";
  harshBraking: any = "0.0";
  harshCornering: any = "0.0";
  directory = languageInitializer.directory
  setOdometer: any = "";
  setHourMeter: any = "";
  TUV: Boolean = false;
  TuvDate: any;
  AVP: boolean = false;
  AvpDate: any;
  oldvalue: Object;
  oldharshAcceleration: any;
  oldharshBraking: any;
  oldharshCornering: any;
  constructor(
    private commonService: CommonService,
    public ajaxService: AjaxService,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private platform: Platform,
    public router: Router,
    private menuController: MenuController,
    private authenticationService: AuthenticationService,
    public translateConfigService: TranslateConfigService,
    public trans: TranslateService
  ) { }

  inItLoader(changer) {
    if (changer == "dashboard" && this.inItLoading.dashboard == true) {
      changer = "gridView";
      this.inItLoading.gridView = true;
    } else if (changer == "gridView" && this.inItLoading.gridView == true) {
      changer = "dashboard";
      this.inItLoading.dashboard = true;
    }
    const data = {
      "emailAddress": localStorage.userName,
      "companyId": localStorage.corpId,
      "initialPage": changer
    }
    const url = serverUrl.web + '/user/update/initialpage';
    this.ajaxService.ajaxPostWithBody(url, data)
      .subscribe(res => {
        if (res = "success") {
          localStorage.setItem('inItPage', changer);
          if (changer == "gridView") {
            this.inItLoading.dashboard = false;
          } else {
            this.inItLoading.gridView = false;
          }
        }

      })
    this.savedetails('initialpage')

  }

  async changeMap() {
    var mapName = 'OpenLayer';
    if (localStorage.mapAllowed != "undefined") {
      let map: any = JSON.parse(localStorage.mapAllowed);
      if (map[0].toLowerCase() == "googlemap") {
        const alert = await this.alertController.create({
          //header: 'Are you sure?',
          header: this.trans.instant('Are you sure?'),
          backdropDismiss: false,
          // message: "The application get restart",
          message: this.trans.instant("The application get restart"),
          buttons: [{
            //text: 'Cancel',
            text: this.trans.instant('Cancel'),
            role: 'cancel',
            handler: data => {
              if (localStorage.map == "GoogleMap") {
                this.googleMap = true;
              } else {
                this.googleMap = false;
              }
            }
          },
          {
            //text: 'Confirm',
            text: this.trans.instant('Confirm'),
            handler: data => {
              if (localStorage.map == "GoogleMap") {
                this.googleMap = false;
                //   localStorage.map = "OpenLayer"
                mapName = 'OpenLayer';
                //  document.location.href = 'index.html';
              } else {
                mapName = 'GoogleMap';
                //  localStorage.map = "GoogleMap"

              }
              const url = serverUrl.web + '/login/change/mapview?company_id=' + localStorage.getItem("corpId") + '&user_id=' + localStorage.getItem("userName") + '&maptype=' + mapName
              this.ajaxService.ajaxGetObject(url)
                .subscribe(res => {
                  //    document.location.href = 'index.html';
                  //  this.router.navigateByUrl('/ tabs');

                  this.menuController.enable(false);
                  localStorage.clear();
                  sessionStorage.setItem('login', 'false')
                  localStorage.setItem('login', 'false')
                  // this.router.navigateByUrl('login');
                  this.authenticationService.logout();
                  localStorage.clear();
                  companyReportData.reportData = undefined;
                  companyReportData.recurrenceData = undefined;
                  storageVariable.upDatedJsonData = undefined;
                  storageVariable.dashboardData = undefined;
                }), err => {
                  //  this.commonService.presentToast('contact support team')
                  this.commonService.presentToast(this.trans.instant('contact support team'))
                }
            }
          }]
        });
        await alert.present();
      } else {
        this.googleMap = true;
        //this.commonService.presentAlert('Warning', "Sorry you are not able to use this function.")
        this.commonService.presentAlert(this.trans.instant('Warning'), this.trans.instant("Sorry you are not able to use this function."))

      }
    } else {
      this.commonService.presentAlert(this.trans.instant('Warning'), this.trans.instant("Sorry you are not able to use this function."))
      this.googleMap = true;

    }
  }

  watchModeNeed(selected, status, event, json) {
    let powercut = '';
    let enginStatus = '';
    let sos = '';
    let statusUpdate = ''
    if (json.powerCut) {
      powercut = '1';
    }
    if (json.enginStatus) {
      enginStatus = '2';
    }
    if (json.sos) {
      sos = '3';
    }
    if (!json.powerCut && !json.enginStatus && !json.sos) {
      statusUpdate = '0';
      this.watchStatus = false;
    } else {
      statusUpdate = powercut + enginStatus + sos;
    }

    let url: string = serverUrl.web + "/device/updatewatchmode?vin=" + this.data.vin + "&value=" + parseInt(statusUpdate).toString();
    let body = {
      "vin": this.data.vin,
      "value": parseInt(statusUpdate)
    };
    this.ajaxService.ajaxGetWithString(url)
      .subscribe(res => {
        this.data["watchmode"] = statusUpdate;
        localStorage.setItem("selectedVin", JSON.stringify(this.data));
        let data = storageVariable.upDatedJsonData;
        data.liveDatas[this.data.vin]["watchmode"] = statusUpdate
        data = storageVariable.dashboardData;
        data.liveDatas[this.data.vin]["watchmode"] = statusUpdate
      })
  }

  toggleWatchmode(event) {
    this.watchMode = {
      powerCut: false,
      enginStatus: false,
      sos: false
    };
    let statusBitWatchmode: number = 0;
    if (event.currentTarget.checked != true) {
      statusBitWatchmode = 1;
      this.watchStatus = false;
    } else {
      this.watchStatus = true;
    }
    let url: string = serverUrl.web + "/device/updatewatchmode?vin=" + this.data.vin + "&value=" + statusBitWatchmode.toString()
    let body = {
      "vin": this.data.vin,
      "value": statusBitWatchmode.toString()
    };
    this.commonService.presentLoader();
    this.ajaxService.ajaxGetWithString(url)
      .subscribe(res => {
        if (res.length > 1) {
          this.commonService.dismissLoader();
          statusBitWatchmode = 0;
          if (this.watchStatus == true) {
            statusBitWatchmode = 1;
          }
          this.data["watchmode"] = statusBitWatchmode;
          localStorage.setItem("selectedVin", JSON.stringify(this.data));
          let data = storageVariable.upDatedJsonData;
          data.liveDatas[this.data.vin]["watchmode"] = statusBitWatchmode
        } else {
          this.watchStatus = !this.watchStatus;
          this.commonService.dismissLoader();
          this.commonService.presentToast('Invalid credential');
        }
        this.watchModeUpdate();
      });

  }

  async notificationSetting(enDis) {
    this.notification = enDis;
    if (!this.notification) {
      localStorage.setItem('notificationStatus', 'true')
      var pushDetails = {
        "imeiNo": localStorage.imeiNo,
        "appName": app.appName, // "Arabian Dynamics",    
        "deviceToken": localStorage.deviceToken,
        "companyID": localStorage.getItem("corpId"),
        "userId": localStorage.getItem("userName"),
        "pushStatus": "true"
      };
      if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
        pushDetails['os'] = "ionic4"
      } else if (/(android)/i.test(navigator.userAgent)) {
        pushDetails['os'] = "ionic4ios"
      }
      const url = serverUrl.web + "/alert/pushnotification";
      this.ajaxService.ajaxPostWithBody(url, pushDetails)
        .subscribe(res => {
          localStorage.setItem('pushStatus', 'persisted');
          //console.log('push presisted');

        })
    }
    else {
      const alert = await this.alertController.create({
        header: this.trans.instant('Warning'),
        backdropDismiss: false,
        message: this.trans.instant("Are you sure you want to disable the app notification"),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {
            this.notification = !this.notification;
          }
        },
        {
          text: this.trans.instant('Confirm'),
          handler: data => {
            localStorage.setItem('notificationStatus', 'true')
            var pushDetails = {
              "imeiNo": localStorage.imeiNo,
              "appName": app.appName, // "Arabian Dynamics",    
              "deviceToken": localStorage.deviceToken,
              "companyID": localStorage.getItem("corpId"),
              "userId": localStorage.getItem("userName"),
              "pushStatus": "false"
            };
            if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
              pushDetails['os'] = "ionic4"
            } else if (/(android)/i.test(navigator.userAgent)) {
              pushDetails['os'] = "ionic4ios"
            }
            const url = serverUrl.web + "/alert/pushnotification";
            this.ajaxService.ajaxPostWithBody(url, pushDetails)
              .subscribe(res => {
                localStorage.setItem('pushStatus', 'persisted');
                //console.log('push presisted');

              })
          }
        }]
      });

      await alert.present();
    }

  }

  watchModeUpdate() {
    this.data = JSON.parse(localStorage.selectedVin);
    if (this.data.hasOwnProperty("watchmode") && this.data.watchmode == 0) {
      this.watchStatus = false;
    }
    else {
      this.watchStatus = true;
      for (let i = 0; i < this.data.watchmode.toString().length; i++) {
        //console.log(this.data.watchmode.toString()[i])
        if (this.data.watchmode.toString()[i] == 1) {
          this.watchMode.powerCut = true;
        } else if (this.data.watchmode.toString()[i] == 2) {
          this.watchMode.enginStatus = true;
        } else if (this.data.watchmode.toString()[i] == 3) {
          this.watchMode.sos = true;
        }
      }
    }
  }

  sendAirCommand() {
    if (this.plateNoList.getSelectedItem() == null || this.airCmdList.getSelectedItem().value == null) {
      this.commonService.presentToast("Please select required fields");
      return null;
    }
    this.commonService.presentToast("The command was send it will take time to reflect on devices");
    let imei = this.plateNoList.getSelectedItem().value;
    let cmd = this.airCmdList.getSelectedItem().value;
    let json = {
      "imeiNo": imei,
      "command": cmd,
      "manufacturer": "queclink",
      "pass": localStorage.password,
      "model": this.plateNoList.getSelectedItem().originalItem.model
    }
    let url = serverUrl.Admin + "/api/device/command"
    this.ajaxService.ajaxPostWithBody(url, json)
      .subscribe(res => {
        this.commonService.presentToast(cmd + " successfully");
      })
  }

  ngOnChanges() {
    if(JSON.parse(localStorage.selectedVin) != undefined){
      let immoAssets = JSON.parse(localStorage.selectedVin);
      if(immoAssets.icon == "LIGHT TOWERS" || immoAssets.icon == "COMPRESSORS" || immoAssets.icon =="WELDING MACHINES" ||  immoAssets.icon =="GENERATOR")
          this.isImmovable = true;
        else this.isImmovable = false;
    }
    
    if (localStorage.selectedVin) {
      this.plateNumber = JSON.parse(localStorage.selectedVin);
      this.androidDetailView();
      this.getCommands();
    }
    this.pageType = this.pageType;
    if (this.pageType != "All") {
      this.watchModeUpdate();
    }
    if (localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) {
      this.inItLoading.dashboard = true;
    } else if (localStorage.inItPage == 'gridView') {
      this.inItLoading.gridView = true;
    }
    if (localStorage.map == "GoogleMap") {
      this.googleMap = true;
    } else {
      this.googleMap = false;
    }


    let url = serverUrl.web + '/login/getPreferences?key=pushNotificationStatus&companyId=' + localStorage.corpId;
    this.ajaxService.ajaxGetWithString(url)
      .subscribe(res => {
        if (res == "Enable") {
          this.notification = true;
        } else {
          this.notification = false;
        }
      })
  }

  getGoogleMapEnable() {
    let url = serverUrl.web + '/login/getPreferences?key=GoogleMapEnable&companyId=' + localStorage.corpId;
    this.ajaxService.ajaxGetWithString(url)
      .subscribe(res => {
        if (res == 0) {
          this.googleMapEnable = false;
        } else {
          this.googleMapEnable = true;
        }

        //  this.googleMapEnable = true;
      })
  }

  setOdometerValue(arg, value) {
    if (!value) {
      this.commonService.presentToast("Please add some values in respected fields")
      return null;
    }
    const Exp = new RegExp(/^\d*\.?\d*$/);

    if (Exp.test(value) && (value < 11 && arg !== 'setOdometer'||'setHourMeter') || Exp.test(value) && arg === 'setOdometer'||'setHourMeter') {
      let send
      if (arg == "setOdometer") {
        send = "ResetOdometre-" + value;
      }
      if (arg == "setHourMeter") {
        if(value.includes("." || ":") || isNaN(value)){
          this.commonService.presentToast("Please enter the valid details")
          return 
        }

        send = "ResetHourMeter-" + value * 3600;
      }
      else {
        send = arg + "-" + value;
      }
      let data = JSON.parse(localStorage.selectedVin);
      const deviceConfig = {
        "imeiNo": data.imeiNo,
        "manufacturer": data.make,
        "command": send,
        "pass": "1234",
        "model": data.model,
      }
      if (data.devicetype == "deepseaNew")
        deviceConfig["commandType"] = "slaveId";
      // const url = serverUrl.Admin + '/device/initial/smsCommands';

      // main code
      const url = serverUrl.Admin + '/api/device/command';
      this.commonService.presentLoader();
      this.ajaxService.ajaxPostMethod(url, JSON.stringify(deviceConfig))
        .subscribe(res => {
          if (res.error.text == "pending" || "Sended") {
            this.commonService.alarmPopup("Status", 'The configuration was successfully updated in the device');
            this.setOdometer = "";
            this.setHourMeter = "";
            this.sendCommands(arg, value);
            this.commonService.dismissLoader();
            this.savedetails(arg);
            this.data.finalOdometer = value
          } else {
            this.commonService.alarmPopup("Status", 'Command not worked!');
            this.commonService.dismissLoader();
          }
        })
    } else {
      this.commonService.presentToast("Please Enter Valid Numbers")
    }



  }

  sendCommands(arg, value) {
    let selectedVehicle = JSON.parse(localStorage.selectedVin);
    const url = serverUrl.web + '/alert/insertDataOfConfig';
    const send = {
      "vin": selectedVehicle.vin,
    }
    send[arg] = arg + "-" + value;
    this.ajaxService.ajaxPostMethod(url, send)
      .subscribe(res => {
      })
  }

  getCommands() {
    const url = serverUrl.web + "/alert/getDataOfConfig/" + JSON.parse(localStorage.selectedVin).vin;
    this.ajaxService.ajaxGet(url).subscribe((res: Object) => {
      (Object.keys(res).includes("harshAcceleration")) ? this.harshAcceleration = res["harshAcceleration"] : this.harshAcceleration = "0.0";
      (Object.keys(res).includes("harshAcceleration")) ? this.oldharshAcceleration = res["harshAcceleration"] : this.oldharshAcceleration = "0.0";
      (Object.keys(res).includes("harshBraking")) ? this.harshBraking = res["harshBraking"] : this.harshBraking = "0.0";
      (Object.keys(res).includes("harshBraking")) ? this.oldharshBraking = res["harshBraking"] : this.oldharshBraking = "0.0";
      (Object.keys(res).includes("harshCornering")) ? this.harshCornering = res["harshCornering"] : this.harshCornering = "0.0";
      (Object.keys(res).includes("harshCornering")) ? this.oldharshCornering = res["harshCornering"] : this.oldharshCornering = "0.0";
    })
  }
  androidDetailView() {
    let selectedVin = JSON.parse(localStorage.selectedVin).vin
    let url: string = serverUrl.web + "/site/vehicleInfo?vin=" + selectedVin;
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.liveData = res;
        let arr = []
        Object.keys(this.liveData).map(el => {
          if (el == "TUV-ExpriyDate") {
            arr.push("TUV-ExpriyDate");
          } if (el == "AVP-ExpriyDate") {
            arr.push("AVP-ExpriyDate");
          }
        })
        if (arr.includes("TUV-ExpriyDate" && this.liveData['TUV-ExpriyDate'] != "---")) {
          this.TUV = true;
          this.TuvDate = this.liveData['TUV-ExpriyDate'];
        }
        if (arr.includes("AVP-ExpriyDate" && this.liveData['AVP-ExpriyDate'] != "---")) {
          this.AVP = true;
          this.AvpDate = this.liveData['AVP-ExpriyDate'];
        }
        if (arr.length == 0) {
          this.AVP = false; this.TUV = false;
        }
        // if (this.liveData['AVP-ExpriyDate'] === "---") {
        //   this.AVP = false;
        // }
        // if (this.liveData['TUV-ExpriyDate'] === "---") {
        //   this.TUV = false;
        // }
      })
  }
  changeToArabic(letter) {
    return this.commonService.numberToArabic(letter.toString());
  }
  incrementValue(iconType) {

    if (iconType === 'acceleration' && parseFloat(this.harshAcceleration) < 11) {
      this.harshAcceleration = (parseFloat(this.harshAcceleration) + 0.1).toFixed(1);
    }

    if (iconType === 'braking' && parseFloat(this.harshBraking) < 11) {
      this.harshBraking = (parseFloat(this.harshBraking) + 0.1).toFixed(1);
    }



    if (iconType === 'Cornering' && parseFloat(this.harshCornering) < 11) {
      this.harshCornering = (parseFloat(this.harshCornering) + 0.1).toFixed(1);
    }
  }
  decrementValue(iconType) {
    if (iconType === 'acceleration' && parseFloat(this.harshAcceleration) > 0) {
      this.harshAcceleration = (parseFloat(this.harshAcceleration) - 0.1).toFixed(1);
    }
    if (iconType === 'braking' && parseFloat(this.harshBraking) > 0) {
      this.harshBraking = (parseFloat(this.harshBraking) - 0.1).toFixed(1);
    }
    if (iconType === 'Cornering' && parseFloat(this.harshCornering) > 0) {
      this.harshCornering = (parseFloat(this.harshCornering) - 0.1).toFixed(1);
    }
  }


  savedetails(data) {
    let url: string = serverUrl.web + "/api/saveDetails"
    let status;
    let oldvalue;
    if(data == "harshAcceleration"){
      status=this.oldharshAcceleration
      oldvalue="{\"primaryData\":{\"Vin\":\" " + this.data.vin + " \",\"PlateNo\":\" " + this.data.plateNo + "\",\Status\":\" " + status + "\"}}"
      this.oldharshAcceleration=this.harshAcceleration
    }else if(data == "harshBraking"){
      status=this.oldharshBraking
      oldvalue="{\"primaryData\":{\"Vin\":\" " + this.data.vin + " \",\"PlateNo\":\" " + this.data.plateNo + "\",\Status\":\" " + status + "\"}}"
      this.oldharshBraking=this.harshBraking
    }
    else if(data == "harshCornering"){
      status=this.oldharshCornering
      oldvalue="{\"primaryData\":{\"Vin\":\" " + this.data.vin + " \",\"PlateNo\":\" " + this.data.plateNo + "\",\Status\":\" " + status + "\"}}"
      this.oldharshCornering=this.harshCornering
    }else if(data == "setOdometer"){
      status=this.data.finalOdometer
      oldvalue="{\"primaryData\":{\"Vin\":\" " + this.data.vin + " \",\"PlateNo\":\" " + this.data.plateNo + "\",\Status\":\" " + status + "\"}}"
    }else if(data == "setHourMeter"){
      status=this.data.hourMeterValue
      oldvalue="{\"primaryData\":{\"Vin\":\" " + this.data.vin + " \",\"PlateNo\":\" " + this.data.plateNo + "\",\Status\":\" " + status + "\"}}"
    }
    else if(data == "initialpage"){
      status=this.inItLoading.dashboard == false ? "Gridview" : "Dashboard"
      oldvalue= "{\initialpage\":\" " + status + "\"}"
    }
    let body = {
      "companyID": localStorage.corpId,
      "userID": localStorage.userName,
      "type": "edit",
      "oldValue": oldvalue,
      "name": data
    };
    this.ajaxService.ajaxPostWithString(url, body)
      .subscribe(res => {
        console.log(res);
      });
  }



  ngOnInit() {
    if (localStorage.selectedVin) {
      this.plateNumber = JSON.parse(localStorage.selectedVin);
      this.androidDetailView();
      this.getCommands();
    }
    // this.myPlatform = this.platform.platforms()[0]
    // if (this.myPlatform == 'tablet') {
    //   this.myPlatform = 'desktop';
    // }
    this.pageType = this.pageType;
    this.appName = app.appName;
    this.entryPoint = app.entryPoint;
    this.isCompanyAdmin = localStorage.companyRole
    this.getGoogleMapEnable()
    this.companyMenuShow = JSON.parse(localStorage.getItem('mainMenu'));
    this.dashboardData = Object.values(storageVariable.upDatedJsonData.liveDatas)
    this.dashboardData.map((res, index) => {
      this.dashboardData[index]['arabicPlateNo'] = this.dashboardData[index].plateNo.split("@,@").length >= 2 ? this.dashboardData[index].plateNo.split("@,@")[0] + ` (${this.dashboardData[index].plateNo.split("@,@")[1]})` : this.dashboardData[index].plateNo.split("@,@")[0]
    })

    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res;
      });
    // if (this.pageType != "All") {
    //   this.watchModeUpdate();
    // }
    // if (localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) {
    //   this.inItLoading.dashboard = true;
    // } else if (localStorage.inItPage == 'gridView') {
    //   this.inItLoading.gridView = true;
    // }
    // if (localStorage.map == "GoogleMap") {
    //   this.googleMap = true;
    // } else {
    //   this.googleMap = false;
    // }


    // let url = serverUrl.web + "/api/vts/company/preference/{'companyID':" + localStorage.corpId + ",'key':'pushNotificationStatus'}";
    // this.ajaxService.ajaxGetWithString(url)
    //   .subscribe(res => {
    //     if (res == "Enable") {
    //       this.notification = true;
    //     } else {
    //       this.notification = false;
    //     }
    //   })

  }

}
