import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { SimDeviceModalComponent } from 'src/app/components/sim-device-modal/sim-device-modal.component';
import { GeofenceModelPage } from 'src/app/geofence/geofence-model/geofence-model.page';
import { AjaxService } from 'src/app/services/ajax.service';
import { AuthMapService } from 'src/app/services/auth-map.service';
import { CommonService } from 'src/app/services/common.service';
import { OpenlayerMapService } from 'src/app/services/openlayer-map.service';
import { app, serverUrl, storageVariable, languageInitializer } from 'src/environments/environment';
import { OperatorformComponent } from '../operator/operatorform/operatorform.component';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-adv-manage-vehicle',
  templateUrl: './adv-manage-vehicle.component.html',
  styleUrls: ['./adv-manage-vehicle.component.scss'],
})
export class AdvManageVehicleComponent implements OnInit {
  @Input() groupsAndVin;
  imei: any = [];
  @Input() mode;
  analogGridTable = false;
  @Input() selectedRow;
  @ViewChild('mapElement', { static: false }) mapElement;
  @ViewChild('myComboBox', { static: false }) myComboBox: jqxComboBoxComponent;
  @ViewChild('geofenceTree', { static: false }) geofenceTree: jqxTreeComponent;
  @ViewChild('groupTreeStu', { static: false }) groupTreeStu: jqxTreeComponent;
  @ViewChild('colorTreeStu', { static: false }) colorTreeStu: jqxTreeComponent;
  @ViewChild("multiplateNoComobox", { static: false }) multiplateNoComobox: jqxComboBoxComponent;
  @ViewChild("operatorComobox", { static: false }) operatorComobox: jqxComboBoxComponent;
  @ViewChild("fleetManagersComobox", { static: false }) fleetManagersComobox: jqxComboBoxComponent;
  @ViewChild("vehicleTypeComboBox", { static: false }) vehicleTypeComboBox: jqxComboBoxComponent;
  @ViewChild('vehicleModelComboBox', { static: false }) vehicleModelComboBox: jqxComboBoxComponent;
  @ViewChild('vehicleManufactureComboBox', { static: false }) vehicleManufactureComboBox: jqxComboBoxComponent;
  @ViewChild('vehicleFuelTypeComboBox', { static: false }) vehicleFuelTypeComboBox: jqxComboBoxComponent;
  @ViewChild('digitalInput1Combobox', { static: false }) digitalInput1Combobox: jqxComboBoxComponent;
  @ViewChild('digitalInput2Combobox', { static: false }) digitalInput2Combobox: jqxComboBoxComponent;
  @ViewChild('digitalInput3Combobox', { static: false }) digitalInput3Combobox: jqxComboBoxComponent;
  @ViewChild('digitalInput4Combobox', { static: false }) digitalInput4Combobox: jqxComboBoxComponent;
  @ViewChild('digitalOutput1Combobox', { static: false }) digitalOutput1Combobox: jqxComboBoxComponent;
  @ViewChild('digitalOutput2Combobox', { static: false }) digitalOutput2Combobox: jqxComboBoxComponent;
  @ViewChild('digitalOutput3Combobox', { static: false }) digitalOutput3Combobox: jqxComboBoxComponent;
  @ViewChild('digitalOutput4Combobox', { static: false }) digitalOutput4Combobox: jqxComboBoxComponent;
  @ViewChild('gensetCapacityUnitComboBox', { static: false }) gensetCapacityUnitComboBox: jqxComboBoxComponent;
  @ViewChild('analogCategories', { static: false }) analogCategories: jqxComboBoxComponent;
  @ViewChild('analogType', { static: false }) analogType: jqxComboBoxComponent;
  @ViewChild('analogCategInput', { static: false }) analogCategInput: jqxComboBoxComponent;
  @ViewChild('Debuggingmode', { static: false }) Debuggingmode: jqxComboBoxComponent;
  @ViewChild('LoadSensorImplement', { static: false }) LoadSensorImplement: jqxComboBoxComponent;
  @ViewChild('yearselect', { static: false }) yearselect: jqxComboBoxComponent;
  callsign:String="";




  dashboardJson: any[] = Object.values(storageVariable.dashboardData.liveDatas);
  isDisabledAnalog = false;
  checkBoxIODetails = {
    digitalInput2: false,
    digitalInput3: false,
    digitalInput4: false,
    digitalOutput1: false,
    digitalOutput2: false,
    digitalOutput3: false,
    digitalOutput4: false,
  };
  color: boolean = false;
  assetMove: string = "";
  assetNewAdd: string = "";



  multipleAppend = {
    fleetManager: false,
    geofence: false
  }
  analogCategoryAndType = {
    categories: ["Analog input 1", "Analog input 2", "Analog input 3", "Analog input 4"],
    type: ["Battery", "Fuel", "Load Sensor", "Oil", "Temperature"],
    input: ["MV", "Liter", "C", "PSI"]
  }

  visiblity = ["plateNo", "device", "fleetManager", "operator", "category", "model", "type", "group", "geofence", "height", "fuelCap", "kmLit", "odometerVal", "fuelType", "cost", "ioDetails", "ioDetailsAnalog", "gensetCapacityValue", "gensetCapacityUnit", "preventiveDue", "dueLimits", "regNo", "branch", "workingStatus", "loadSensor"]
  digitalInputResponceData = {}
  operatorId: any;
  operatorGrid: any;
  assetCategory = ["GENERATOR", "ATM", "FORKLIFTS", "LIGHT TOWERS", "WELDING MACHINES", "DEEPSEA GENERATOR",
  "COMPRESSORS", "BUS", "CAR", "SMALL TRUCK COVERED", "BIKE", "AUTO", "BATTERY", "BOBCAT", "PICKUP – 1 CABIN",
  "TANKER", "LOADER",  "DUMPER TRUCK", "STREET SWEEPER", "TOWED STREET SWEEPER", "ROAD ROLLER COMPACTOR", 
  "HOOK LIFT", "CRANE", "SMALL TRUCK UNCOVERED", "PICKUP – 2 CABIN","THERMOWARE","SNOWARE", "PRISONERS", "CONCRETE CARRIER",
   "CONCRETE MIXER",'GARPAGE COMPACTOR',"VAN","SMALL BUS","VAN DELIVERY","SCOOTY","HEAVY TRUCK",
   "DIESEL GENERATOR", "CONCRETE PUMING EQUIPMENT","EMPLOYEE", "CEMENT BULKER", 'STREET SWEEPER',"WAREHOUSE",
   ];
  //  "WASHING MACHINE","IMMOVABLE ASSETS LIKE",
   assetModel = [];
  assetType = [];
  debuggmode = [true, false]
  treeGeofenceObject: any = {};
  geofenceGridForm: any = [];
  geofenceGrid;
  geoJsonId: number = 0;
  geofence: any;
  map: any;
  fuelType = ["Petrol", "Diesel", "Gas"];
  fleetLogin: any;
  vin: any;
  groupColor = [];
  groupYear = [];
  fleetManagers: any = [];
  vehicleAssFleets: any;
  groupGrid: any;
  groupTree: any;
  colorGrid: any;
  colorTree: any;
  geoFenceTreeSize = 122;
  geoFenceColorSize = 122;
  openModalAppReplace: any = false;
  openGeofenceModal: any = false;
  openModalGeoManage: any = [];
  appendReplace = {
    Circle: false,
    Freeform: false,
    Road: false,
    Landmark: false
  }
  analogValues: any;
  multiplateNos: any[];
  analogTableValue: any;
  renderer: (row: number, column: any, value: string) => string;
  source: { localdata: any[]; };
  dataAdapter: any;
  columns: ({ text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; columntype?: undefined; buttonclick?: undefined; } | { text: string; datafield: string; columntype: string; cellsrenderer: () => string; buttonclick: (row: number) => void; })[];
  analogGrid: any;
  analogTableArrayWholeValue = [];
  analogAssociatedVin: any;
  debugmode = false;
  @Input() overallData;
  gensetUnit = ["KW", "CFM", "AMPS", "TON"];
  groupTreeElement: any = [];
  countGroupData: number;
  editedDataArray: any = [];
  type: boolean = false;
  model: boolean = false;
  year: boolean = false;
  vinmulti: any;
  category: any;
  yearshow: any[] = [];
  layerModal = false;
  directory: string = languageInitializer.directory;
  entryPoint: any;
  form: FormGroup;
  atmIndex: number;
  inputAndOutputJson: any;
  analogInput: any;
  zone: any;
  body: any;
  vehicleFuel: any;
  data: any;
  fenceChangeValue: any;
  fenceEdit: any;
  operator: any;
  groupColor1: any;
  vehicleFuel1: any;
  checkboxData: any;
  submitbtn: boolean=false;



  constructor(
    private ajaxService: AjaxService,
    public mapService: AuthMapService,
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private modalController: ModalController,
    private cdr: ChangeDetectorRef,
    private websocketService:WebsocketService
  ) {
    this.mapService = new OpenlayerMapService();
  }

  switchMap() {
    this.layerModal = !this.layerModal
  this.map.getLayers().item(3).setVisible(this.layerModal);
    this.map.getLayers().item(0).setVisible(!this.layerModal);
  }
  multiplateNochange($event) {
    this.selectedRow = []
    let data = this.multiplateNoComobox.getCheckedItems()
    data.map(res => {
      if (res.originalItem.plateNo != "Select all")
        this.selectedRow.push(res.originalItem)
    })

  }

  addVehicleInToCompany() {
    this.submitbtn=true;
    if (this.entryPoint != "ATM") {
      let imeiList = this.imei.map(rr => rr.imeiNo)
      if ((this.fleetLogin.value.plateNo == "" || this.fleetLogin.value.imei == "" || (this.fleetLogin.value.imei == undefined || !imeiList.includes(this.fleetLogin.value.imei)) || this.fleetManagersComobox.getCheckedItems().length == 0 || (this.vehicleTypeComboBox.getSelectedItem() == null)) && this.mode != "multiEdit") {
        this.commonService.presentToast("Please fill the valid details")
        this.submitbtn=false;
        return 0;
      }
      if (this.vehicleModelComboBox.getSelectedItem() == null && this.mode != "multiEdit" && this.entryPoint != 'ATM') {
        this.commonService.presentToast("Please fill the Model details")
        this.submitbtn=false;
        return 0;
      }

      if ((this.digitalOutput1Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput1CheckBox == true) || (this.digitalOutput2Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput2CheckBox == true) || (this.digitalOutput3Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput3CheckBox == true) || (this.digitalOutput4Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput4CheckBox == true)) {
        this.commonService.presentToast("Please fill the valid details in Digital Output for reverse settings ");
        this.submitbtn=false;
        return 0;
      }
      if ((this.digitalInput2Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalInput2CheckBox == true) || (this.digitalInput3Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalInput3CheckBox == true) || (this.digitalInput4Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalInput4CheckBox == true)) {
        this.commonService.presentToast("Please fill the valid details in Digital Input for reverse settings");
        this.submitbtn=false;
        return 0;
      }
    } else {
      let imeiList = this.imei.map(rr => rr.imeiNo)
      if ((this.fleetLogin.value.plateNo == "" || this.fleetLogin.value.imei == "" || (this.fleetLogin.value.imei == undefined || !imeiList.includes(this.fleetLogin.value.imei)) || this.fleetManagersComobox.getCheckedItems().length == 0) && this.mode != "multiEdit") {
        this.commonService.presentToast("Please fill the valid details ")
        this.submitbtn=false;
        return 0;
      }
    }
    if (this.mode != "multiEdit")
      this.commonService.presentLoader();
    if (this.mode == "add")
      this.generateVin();
    if (this.mode == "edit")
      this.editVehicle();
    if (this.mode == "multiEdit")
      this.openModalGeofenceConfirm();
     
    // this.multiEditVehicle();
  }



  configalerts(vindata) {
    let method = "alertconfigCacheByMultiVins";
    var json = {};
    json["method"] = method;
    json["companyId"] = localStorage.corpId;
    json["multivin"] = true;
    json["userId"] = localStorage.userName
    if (vindata.length > 1) {
      let vin = ''
      for (let i = 0; i < vindata.length; i++) {
        if (vindata.length - 1 == i)
          vin += "\'" + vindata[i].vin + "\'"
        else
          vin += "\'" + vindata[i].vin + "\',"
      }
      json["vin"] = vin;
    } else {
      json["vin"] = "\'" + vindata[0].vin + "\'";
    }
    const url = serverUrl.Admin + '/api/alerts/config';
    this.ajaxService.ajaxPostWithString(url, json)
      .subscribe(res => {
        // console.log("res",res);

      }, err => {
        this.commonService.presentToast("failed")
      });
  }

  openModalGeofenceConfirm() {
    if (this.entryPoint != "ATM") {
      if ((this.digitalOutput1Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput1CheckBox == true) || (this.digitalOutput2Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput2CheckBox == true) || (this.digitalOutput3Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput3CheckBox == true) || (this.digitalOutput4Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalOutput4CheckBox == true)) {
        this.commonService.presentToast("Please fill the valid details in Digital Output for reverse settings ");
        return 0;
      }
      if ((this.digitalInput2Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalInput2CheckBox == true) || (this.digitalInput3Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalInput3CheckBox == true) || (this.digitalInput4Combobox.getSelectedItem() == null && this.fleetLogin.value.digitalInput4CheckBox == true)) {
        this.commonService.presentToast("Please fill the valid details in Digital Input for reverse settings");
        return 0;
      }
      let zone = {
        circle: [],
        landmark: [],
        freeform: [],
        road: []
      }
      let open: boolean = false
      let id = this.geofenceTree.getCheckedItems();
      for (let i = 0; i < id.length; i++) {
        if (this.treeGeofenceObject[id[i].value].json) {
          if (this.treeGeofenceObject[id[i].value].json.type == "Circle") {
            zone.circle.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
            this.openModalGeoManage.push("Circle")
            open = true;
          }
          if (this.treeGeofenceObject[id[i].value].json.type == "Freeform") {
            zone.freeform.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
            open = true;
            this.openModalGeoManage.push("Freeform")
          }
          if (this.treeGeofenceObject[id[i].value].json.type == "Road") {
            zone.road.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
            open = true;
            this.openModalGeoManage.push("Road")
          }
          if (this.treeGeofenceObject[id[i].value].json.type == "Landmark") {
            zone.landmark.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
            open = true;
            this.openModalGeoManage.push("Landmark")
          }
        }
      }
      this.openModalGeoManage = [...new Set(this.openModalGeoManage)];
      if (!open)
        this.multiEditVehicle();
      else {
        this.openGeofenceModal = true
      }
    }
    if (this.entryPoint == "ATM") {
      this.multiEditVehicle();
    }
  }

  closeModal() {
    this.modalController.dismiss()
  }

  checkChange(data, boolean) {
    this.appendReplace[data] = boolean;
  }

  multiEditVehicle() {
    if (this.selectedRow == undefined) {
      this.commonService.presentToast("Please Select Plate No")
      return null;
    } else {
      this.commonService.presentLoader();
      let multiVehicleEdit = [];
      for (let count = 0; count < this.selectedRow.length; count++) {
        if (this.entryPoint != "ATM") {
          this.zone = {
            circle: [],
            landmark: [],
            freeform: [],
            road: []
          }
          this.fenceEdit = {
            circle: [],
            landmark: [],
            freeform: [],
            road: []
          };

          this.fenceChangeValue = {
            circle: -1,
            landmark: -1,
            freeform: -1,
            road: -1
          }



          let id = this.geofenceTree.getCheckedItems();

          for (let i = 0; i < id.length; i++) {

            if (this.treeGeofenceObject[id[i].value].json) {
              if (this.treeGeofenceObject[id[i].value].json.type == "Circle")
                this.zone.circle.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
              if (this.treeGeofenceObject[id[i].value].json.type == "Freeform")
                this.zone.freeform.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
              if (this.treeGeofenceObject[id[i].value].json.type == "Road")
                this.zone.road.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
              if (this.treeGeofenceObject[id[i].value].json.type == "Landmark")
                this.zone.landmark.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
            }
          }

          if ((this.selectedRow[count].geozones_id != "" && this.zone.circle.length > 0) || (this.selectedRow[count].geozones_id != "" && this.zone.circle.length == 0))
            this.fenceChangeValue.circle = 1
          if ((this.selectedRow[count].landmark != "" && this.zone.landmark.length > 0) || (this.selectedRow[count].landmark != "" && this.zone.landmark.length == 0))
            this.fenceChangeValue.landmark = 1
          if ((this.selectedRow[count].routeid != "" && this.zone.road.length == 0) || (this.selectedRow[count].routeid != "" && this.zone.road.length == 0))
            this.fenceChangeValue.road = 1
          if ((this.selectedRow[count].freeformid != "" && this.zone.freeform.length > 0) || (this.selectedRow[count].freeformid != "" && this.zone.freeform.length == 0))
            this.fenceChangeValue.freeform = 1
          if (this.selectedRow[count].geozones_id) {
            if (this.selectedRow[count].geozones_id != "" && this.selectedRow[count].geozones_id.split(",").length > 0)
              for (let i = 0; i < this.selectedRow[count].geozones_id.split(",").length; i++) {
                if (this.selectedRow[count].geozones_id.split(",").length < this.zone.circle.length || this.selectedRow[count].geozones_id.split(",").length > this.zone.circle.length)
                  this.fenceChangeValue.circle = 1;
                if (this.zone.circle.includes(this.selectedRow[count].geozones_id.split(",")[i])) {
                  console.log(this.selectedRow[count].geozones_id.split(",")[i]);
                } else {
                  this.fenceChangeValue.circle = 1;
                  this.fenceEdit.circle.push(this.selectedRow[count].geozones_id.split(",")[i])
                }
              }
          }

          if (this.selectedRow[count].landmark) {
            if (this.selectedRow[count].landmark != "" && this.selectedRow[count].landmark.split(",").length > 0)
              for (let i = 0; i < this.selectedRow[count].landmark.split(",").length; i++) {
                if (this.selectedRow[count].landmark.split(",").length < this.zone.landmark.length || this.selectedRow[count].landmark.split(",").length > this.zone.landmark.length)
                  this.fenceChangeValue.landmark = 1;
                if (this.zone.landmark.includes(this.selectedRow[count].landmark.split(",")[i])) {
                  console.log(this.selectedRow[count].landmark.split(",")[i]);
                } else {
                  this.fenceChangeValue.landmark = 1;
                  this.fenceEdit.landmark.push(this.selectedRow[count].landmark.split(",")[i])
                }
              }
          }

          if (this.selectedRow[count].routeid) {
            if (this.selectedRow[count].routeid != "" && this.selectedRow[count].routeid.split(",").length > 0)
              for (let i = 0; i < this.selectedRow[count].routeid.split(",").length; i++) {
                if (this.selectedRow[count].routeid.split(",").length < this.zone.road.length || this.selectedRow[count].routeid.split(",").length > this.zone.road.length)
                  this.fenceChangeValue.road = 1;
                if (this.zone.road.includes(this.selectedRow[count].routeid.split(",")[i])) {
                  console.log(this.selectedRow[count].routeid.split(",")[i]);
                } else {
                  this.fenceChangeValue.road = 1;
                  this.fenceEdit.road.push(this.selectedRow[count].routeid.split(",")[i])
                }
              }
          }
          if (this.selectedRow[count].freeformid) {
            if (this.selectedRow[count].freeformid != "" && this.selectedRow[count].freeformid.split(",").length > 0)
              for (let i = 0; i < this.selectedRow[count].freeformid.split(",").length; i++) {
                if (this.selectedRow[count].freeformid.split(",").length < this.zone.freeform.length || this.selectedRow[count].freeformid.split(",").length > this.zone.freeform.length)
                  this.fenceChangeValue.freeform = 1;
                if (this.zone.freeform.includes(this.selectedRow[count].freeformid.split(",")[i])) {
                  console.log(this.selectedRow[count].freeformid.split(",")[i]);
                } else {
                  this.fenceChangeValue.freeform = 1;
                  this.fenceEdit.freeform.push(this.selectedRow[count].freeformid.split(",")[i])
                }
              }
          }
          // let operator = this.operatorComobox.getSelectedItem() != null ? this.operatorComobox.getSelectedItem().value : ""
          if (!this.appendReplace.Circle)
            this.zone.circle = this.selectedRow[count].geozones_id == "" || this.selectedRow[count].geozones_id == undefined || this.selectedRow[count].geozones_id == null ? this.zone.circle : [...this.selectedRow[count].geozones_id.split(","), ...this.zone.circle]
          if (!this.appendReplace.Freeform)
            this.zone.freeform = this.selectedRow[count].freeformid == "" || this.selectedRow[count].freeformid == undefined || this.selectedRow[count].freeformid == null ? this.zone.freeform : [...this.selectedRow[count].freeformid.split(","), ...this.zone.freeform]
          if (!this.appendReplace.Landmark)
            this.zone.landmark = this.selectedRow[count].landmark == "" || this.selectedRow[count].landmark == undefined || this.selectedRow[count].landmark == null ? this.zone.landmark : [...this.selectedRow[count].landmark.split(","), ...this.zone.landmark]
          if (!this.appendReplace.Road)
            this.zone.road = this.selectedRow[count].routeid == "" || this.selectedRow[count].routeid == undefined || this.selectedRow[count].routeid == null ? this.zone.road : [...this.selectedRow[count].routeid.split(","), ...this.zone.road]


          this.vehicleFuel1 = this.vehicleFuelTypeComboBox.getSelectedItem()

          if (this.colorTreeStu.getSelectedItem() != null)
            this.groupColor1 = this.colorTreeStu.getSelectedItem().value;
          else
            this.selectedRow[count].group_Color == "" ? "" : this.selectedRow[count].group_Color;
          // (this.selectedRow[count].ioDetails.split(",").length > 0 ? (this.selectedRow[count].ioDetails.split(",")[0]).split("|")[1] : "")
          this.inputAndOutputJson = {
            digitalInput1: this.digitalInput1Combobox.getSelectedItem() != null ? this.digitalInput1Combobox.getSelectedItem().label : "",
            digitalInput2: this.digitalInput2Combobox.getSelectedItem() != null ? this.digitalInput2Combobox.getSelectedItem().label : "",
            digitalInput3: this.digitalInput3Combobox.getSelectedItem() != null ? this.digitalInput3Combobox.getSelectedItem().label : "",
            digitalInput4: this.digitalInput4Combobox.getSelectedItem() != null ? this.digitalInput4Combobox.getSelectedItem().label : "",
            digitalOutput1: this.digitalOutput1Combobox.getSelectedItem() != null ? this.digitalOutput1Combobox.getSelectedItem().label : "",
            digitalOutput2: this.digitalOutput2Combobox.getSelectedItem() != null ? this.digitalOutput2Combobox.getSelectedItem().label : "",
            digitalOutput3: this.digitalOutput3Combobox.getSelectedItem() != null ? this.digitalOutput3Combobox.getSelectedItem().label : "",
            digitalOutput4: this.digitalOutput4Combobox.getSelectedItem() != null ? this.digitalOutput4Combobox.getSelectedItem().label : "",
            analogInput1: "", analogInput2: "", analogInput3: "", analogInput4: ""
          }

          if (this.selectedRow[count].ioDetails) {
            let spliter = this.selectedRow[count].ioDetails.split(",");
            for (let is = 0; is < spliter.length; is++) {
              if (spliter[is].split("|")[0] == "1" && this.digitalInput1Combobox.getSelectedItem() == null) {
                this.inputAndOutputJson["digitalInput1"] = spliter[is].split("|")[1]
              }
              if (spliter[is].split("|")[0] == "2" && this.digitalInput2Combobox.getSelectedItem() == null) {
                this.inputAndOutputJson["digitalInput2"] = spliter[is].split("|")[1]
              }
              if (spliter[is].split("|")[0] == "3" && this.digitalInput3Combobox.getSelectedItem() == null) {
                this.inputAndOutputJson["digitalInput3"] = spliter[is].split("|")[1]
              }
              if (spliter[is].split("|")[0] == "4" && this.digitalInput4Combobox.getSelectedItem() == null) {
                this.inputAndOutputJson["digitalInput4"] = spliter[is].split("|")[1]
              }


            }
          }
          document.getElementsByName("digitalInputData").forEach((res: any) => {
            if (res.checked == true)
              this.inputAndOutputJson[res.value] = this.inputAndOutputJson[res.value] + "|1"
            else {
              this.inputAndOutputJson[res.value] = this.inputAndOutputJson[res.value]
            }
          });
          this.checkBoxIODetails = {
            digitalInput2: this.fleetLogin.value.digitalInput2CheckBox,
            digitalInput3: this.fleetLogin.value.digitalInput3CheckBox,
            digitalInput4: this.fleetLogin.value.digitalInput4CheckBox,
            digitalOutput1: this.fleetLogin.value.digitalOutput1CheckBox,
            digitalOutput2: this.fleetLogin.value.digitalOutput2CheckBox,
            digitalOutput3: this.fleetLogin.value.digitalOutput3CheckBox,
            digitalOutput4: this.fleetLogin.value.digitalOutput4CheckBox,
          }

          this.checkboxData = (this.checkBoxIODetails["digitalInput2"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalInput3"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalInput4"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput1"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput2"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput3"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput4"] == true ? "1" : "0")

          if (this.checkboxData == "0|0|0|0|0|0|0" || this.checkboxData == "0|0|0")
            this.checkboxData = this.selectedRow[count].reverseSetting;

          this.analogInput = {
            "Analog input 1": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
            "Analog input 2": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
            "Analog input 3": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
            "Analog input 4": { type: "", input: "", min: "", max: "", mv: [], liter: [] }
          };
          if (this.analogTableArrayWholeValue.length > 0) {
            console.log(this.analogInput)
            for (let i = 0; i < this.analogTableArrayWholeValue.length; i++) {
              let analogOfType = this.analogInput[this.analogTableArrayWholeValue[i].analog]
              analogOfType["type"] = this.analogTableArrayWholeValue[i].type;
              analogOfType["input"] = this.analogTableArrayWholeValue[i].input;
              analogOfType["min"] = this.analogTableArrayWholeValue[i].min;
              analogOfType["max"] = this.analogTableArrayWholeValue[i].max;
              analogOfType["mv"].push(this.analogTableArrayWholeValue[i].mv)
              analogOfType["liter"].push(this.analogTableArrayWholeValue[i].liter)
            }
          }
        }
        let fleet = []
        for (let i = 0; i < this.fleetManagersComobox.getCheckedItems().length; i++) {
          fleet.push(this.fleetManagersComobox.getCheckedItems()[i]["value"])
        }
        if (!this.multipleAppend.fleetManager)
          fleet = [...new Set(([...this.vehicleAssFleets[this.selectedRow[count]["Vehicle Number"]].split(","), ...fleet]))]

        var debug = this.selectedRow[count]['assetInformation'] == "" ? { debuggingMode: false } : JSON.parse(this.selectedRow[count]['assetInformation']);
        if (this.entryPoint != "ATM") {
          this.data = {
            "vin": this.selectedRow[count]["Vehicle Number"],
            "replace": this.multipleAppend.fleetManager,
            "multiselect": true,
            "companyID": localStorage.corpId,
            "branchID": localStorage.corpId,
            "username": localStorage.userName,
            "emailId": localStorage.password,
            "userEntry": "",
            "groupColor": this.groupColor1,//
            "fleetUser": fleet.toString() + "," + localStorage.userName,
            "plateNo": this.selectedRow[count]["Plate Number"],
            "oldCheckRoad": this.fenceChangeValue.road,//
            "oldcheckFreeForm": this.fenceChangeValue.freeform,//
            "oldcheckGeoZone": this.fenceChangeValue.circle,//
            "oldchecklandMark": this.fenceChangeValue.landmark,//
            "oldcheckshift": -1,//
            "assetCode": "",//
            "type": this.vehicleManufactureComboBox.getSelectedItem() == null ? this.selectedRow[count]["Type"] : this.vehicleManufactureComboBox.getSelectedItem().value,//
            "icon": this.vehicleTypeComboBox.getSelectedItem() == null ? this.selectedRow[count]["iconUrl"] : this.vehicleTypeComboBox.getSelectedItem().value,
            "height": this.fleetLogin.value.vehicleHeight != "" ? this.fleetLogin.value.vehicleHeight : this.selectedRow[count]["height"],
            "model": this.vehicleModelComboBox.getSelectedItem() == null ? this.selectedRow[count]["Make"] : this.vehicleModelComboBox.getSelectedItem().value,
            "group": this.groupTreeStu.getSelectedItem() != null ? this.groupTreeStu.getSelectedItem().value : this.selectedRow[count]["Group"] = "" ? "true" : this.selectedRow[count]["Group"],
            "dateofpurchase": "true",
            "insuranceExpiry": "true",
            "expectedvehiclemilage": this.fleetLogin.value.kmPerLiter != "" ? this.fleetLogin.value.kmPerLiter : this.selectedRow[count]["expectedMileage"] == 0 ? "true" : this.selectedRow[count]["mileageInit"].split("-")[0],
            "contactNo": "true",
            "fuelTanklit": this.fleetLogin.value.fuelCapacity != "" ? this.fleetLogin.value.fuelCapacity : this.selectedRow[count].fuelTanklit == "" ? "true" : this.selectedRow[count].fuelTanklit,
            "additionalWarranty": "true",
            "dateofreg": "true",
            "regexpiry": "true",
            "mileageInit": (this.fleetLogin.value.kmPerLiter == "" ? this.selectedRow[count]["mileageInit"].split("-")[0] : this.fleetLogin.value.kmPerLiter) + "-" + (this.vehicleFuel1 == null ? this.selectedRow[count]["mileageInit"].split("-")[1] == undefined ? "Petrol" : this.selectedRow[count]["mileageInit"].split("-")[1] : this.vehicleFuel1.value) + "-" + (this.fleetLogin.value.pricePerLiter == "" ? this.selectedRow[count]["mileageInit"].split("-")[2] != null ? this.selectedRow[count]["mileageInit"].split("-")[2] : "0" : this.fleetLogin.value.pricePerLiter),
            "odometer": this.fleetLogin.value.odometerValue != "" ? this.fleetLogin.value.odometerValue : this.selectedRow[count]["Odometer"] == "" ? "true" : this.selectedRow[count]["Odometer"],
            "preventiveselect": 3000,
            "preventivelimitselect": 30,
            "scheduleselect": 3000,
            "schedulelimitselect": 30,
            "PreventiveMaintenanceType": "hours",
            "reverseSetting": this.checkboxData,
            "digitalInput1": this.inputAndOutputJson.digitalInput1,
            "digitalInput2": this.inputAndOutputJson.digitalInput2,
            "digitalInput3": this.inputAndOutputJson.digitalInput3,
            "digitalInput4": this.inputAndOutputJson.digitalInput4,
            "DigitalOutput": this.inputAndOutputJson.digitalOutput1 + "|" + this.inputAndOutputJson.digitalOutput2 + "|" + this.inputAndOutputJson.digitalOutput3 + "|" + this.inputAndOutputJson.digitalOutput4,

            "analoginput1": "|||",
            "analoginput2": "|||",
            "analoginput3": "|||",
            "analoginput4": "|||",
            "onewiredinput": "",
            "actual1": this.analogInput["Analog input 1"].liter,
            "mv1": this.analogInput["Analog input 1"].mv,
            "actual2": this.analogInput["Analog input 2"].liter,
            "mv2": this.analogInput["Analog input 2"].mv,
            "actual3": this.analogInput["Analog input 3"].liter,
            "mv3": this.analogInput["Analog input 3"].mv,
            "actual4": this.analogInput["Analog input 4"].liter,
            "mv4": this.analogInput["Analog input 4"].mv,
            "operatorID": this.selectedRow[count]["OperatorId"],
            "imeiNo": this.selectedRow[count]["IMEI Number"],
            "checkDevice": 0,
            "checkOperator": 0,

            "checkshift": 1,
            "checkGeoZone": this.fenceChangeValue.circle,
            "checklandMark": this.fenceChangeValue.landmark,
            "checkRoadGeo": this.fenceChangeValue.road,
            "checkFreeForm": this.fenceChangeValue.road,
            "fuelTankCapacity": (this.fleetLogin.value.fuelTankCapacity) ? this.fleetLogin.value.fuelTankCapacity : "",
            "isLandArray": this.zone.landmark.length == 0 ? false : true,
            "isRoadArray": this.zone.road.length == 0 ? false : true,
            "isFreeformArray": this.zone.freeform.length == 0 ? false : true,
            "isCirlrectArray": this.zone.circle.length == 0 ? false : true,


            "landMark": this.zone.landmark.length != 0 ? this.zone.landmark : [],
            "roadGeo": this.zone.road.length != 0 ? this.zone.road : [],
            "prefRest": this.zone.circle.length != 0 ? this.zone.circle : [],
            "freeForm": this.zone.freeform.length != 0 ? this.zone.freeform : [],



            "oldprefRest": [],
            "oldfreeForm": [],
            "oldlandMark": [],
            "oldroadGeo": [],
            "oldshift": [],


            "isOldFreeFormArray": this.fenceEdit.freeform.length == 0 ? false : true,//
            "isOldPrefRestArray": this.fenceEdit.circle.length == 0 ? false : true,
            "isOldLandMarkArray": this.fenceEdit.landmark.length == 0 ? false : true,
            "isOldRoadGeoArray": this.fenceEdit.road.length == 0 ? false : true,
            "debuggingMode": JSON.stringify({ debuggingMode: debug.debuggingMode }),
            "showHierarchy": false,

            "regNo": this.selectedRow[count]["regNo"],
            "branch": this.selectedRow[count]["branch"],
            "workingStatus": this.selectedRow[count]["workingStatus"],
            "vName": (this.fleetLogin.value.vehicleName) ? this.fleetLogin.value.vehicleName : this.selectedRow[count]["vName"],
            "remarks": this.assetMove,
            "loadSensorImplements": this.LoadSensorImplement.getSelectedItem() == null ? "false" : this.LoadSensorImplement.getSelectedItem().value,
            "year": this.yearselect.getSelectedItem() == null ? this.selectedRow[count]["year"] : this.yearselect.getSelectedItem().value,
          }
        } else {
          this.data = {
            "companyID": localStorage.corpId,
            "branchID": localStorage.corpId,
            "username": localStorage.userName,
            "emailId": this.fleetManagers.toString(),
            "userEntry": "",
            "groupColor": "",
            "fleetUser": fleet.toString() + "," + localStorage.userName,
            "replace": this.multipleAppend.fleetManager,
            "multiselect": true,
            "plateNo": this.selectedRow[count]["Plate Number"],
            "oldCheckRoad": 0,
            "oldcheckFreeForm": 0,
            "oldcheckGeoZone": 0,
            "oldchecklandMark": 0,
            "oldcheckshift": 0,
            // "assetCode": this.fleetLogin.value.assertCode == undefined ? "" : this.fleetLogin.value.assertCode,
            "type": "",
            "icon": "ATM",
            "height": "",
            "model": "ATM",
            "group": this.groupTreeStu.getSelectedItem() != null ? this.groupTreeStu.getSelectedItem().value : this.selectedRow[count]["Group"] = "" ? "true" : this.selectedRow[count]["Group"], //
            "dateofpurchase": new Date().toJSON().split("T")[0],
            "insuranceExpiry": "true",
            "expectedvehiclemilage": "0",
            "contactNo": "true",
            "fuelTanklit": "true",
            "additionalWarranty": "true",
            "dateofreg": "true",
            "regexpiry": "true",
            "mileageInit": "",
            "odometer": "true",
            "PreventiveMaintenanceType": "hours",
            "reverseSetting": "0|0|0|0|0|0|0",
            "digitalInput1": "",
            "digitalInput2": "",
            "digitalInput3": "",
            "digitalInput4": "",
            "fuelTankCapacity": "",
            "DigitalOutput": "|||",
            "analoginput1": "|||",
            "analoginput2": "|||",
            "analoginput3": "|||",
            "analoginput4": "|||",
            "onewiredinput": "",
            "actual1": [],
            "mv1": [],
            "actual2": [],
            "mv2": [],
            "actual3": [],
            "mv3": [],
            "actual4": [],
            "mv4": [],
            "imeiNo": this.selectedRow[count]["IMEI Number"],
            "roadGeo": "true",
            "isRoadArray": false,
            "freeForm": "true",
            "isFreeformArray": false,
            "prefRest": "true",
            "isCirlrectArray": false,
            "landMark": "true",//
            "isLandArray": false,
            "showHierarchy": false,
            "vin": this.selectedRow[count]["Vehicle Number"],
            "debuggingMode": JSON.stringify({ debuggingMode: debug.debuggingMode }),
            "preventiveselect": 0,
            "preventivelimitselect": 0,
            "scheduleselect": 0,
            "schedulelimitselect": 0,
            "genCapacity": "0 KW",
            "regNo": "",
            "branch": "",
            "workingStatus": "",
            "vName": "",
            "remarks": "",
            "loadSensorImplements": "false",
            "year": "",
            "checkRoadGeo": -1,
            "checkFreeForm": -1,
            "checkGeoZone": -1,
            "checklandMark": -1,
            "checkOperator": 0,
            "checkshift": -1,
            "checkDevice": 0,
            "effFrom": "",
            "isOldPrefRestArray": false,
            "isOldLandMarkArray": false,
            "isOldFreeFormArray": false,
            "isOldRoadGeoArray": false,
            "oldfreeForm": [],
            "oldroadGeo": [],
            "oldprefRest": [],
            "oldlandMark": [],
          }
        }

        // let oldValueData = {}
        // for (let i = 0; i < this.editedDataArray.length; i++) {
        //   if (this.editedDataArray[i] !== "Geofence")
        //     oldValueData[this.editedDataArray[i]] = this.selectedRow[count][this.editedDataArray[i]]
        //   if (this.editedDataArray[i] == "Geofence") {
        //     oldValueData["Geofence"] = {
        //       "freeformid": this.selectedRow[count]["freeformid"],
        //       "routeid": this.selectedRow[count]["routeid"],
        //       "geozones_id": this.selectedRow[count]["geozones_id"],
        //       "landmark": this.selectedRow[count]["landmark"]
        //     }
        //   }
        //     if (this.editedDataArray[i] == "fleetManagers") {
        //       oldValueData["fleetManagers"] = this.vehicleAssFleets[this.selectedRow[count]["Vehicle Number"]]
        //     }

        // }
        // oldValueData["macIp"] = ""
        this.data["oldValue"] = JSON.stringify(this.commonService.userAdminControlEditDelete(this.editedDataArray, this.selectedRow[count], this.vehicleAssFleets));
        multiVehicleEdit.push(JSON.stringify(this.data))
        this.vinmulti = this.selectedRow[count]["Vehicle Number"]
        this.category = this.selectedRow[count]["iconUrl"]
      }

      const url = serverUrl.web + "/VehicleController/multieditvehdetails"
      this.ajaxService.ajaxPostMethod(url, multiVehicleEdit)
        .subscribe(res => {
          if (res.jsonfinalstatus == "persisted") {
            this.submitbtn=false;
            this.commonService.presentToast("Presisted successfully");
            if (this.websocketService.isAlive("livetrack")) {
              this.websocketService.reSendRequest(JSON.parse(localStorage.dashboardWebSocketData));
            }
            this.commonService.dismissLoader();
            this.modalController.dismiss()
            if (this.entryPoint != "ATM")
              if (this.category !== this.vehicleTypeComboBox.getSelectedItem().value) {
                let a = [{ vin: this.vinmulti }];
                this.configalerts(a);
                console.log("this.vehicleTypeComboBox.getSelectedItem().value", this.vinmulti, this.category, this.vehicleTypeComboBox.getSelectedItem().value);
              }
            this.modalController.dismiss()
            let data = ""
            multiVehicleEdit.map(res => data += res.vin)
            if (this.entryPoint != "ATM")
              this.ajaxService.ajaxPostWithBody(serverUrl.Admin + "/api/alerts/geoconfig", { vins: data.toString() })
                .subscribe(res => {

                })
            // const url = serverUrl.Admin + "/api/updateGroup"
            // let data = []
            // for (let count = 0; count < this.selectedRow.length; count++) {
            //   data.push({
            //     "appName": app.appName,
            //     "group": this.groupTreeStu.getSelectedItem() != null ? this.groupTreeStu.getSelectedItem().value : this.selectedRow[count]["Group"] = "" ? "true" : this.selectedRow[count]["Group"],
            //     "vin": this.selectedRow[count]["Vehicle Number"]
            //   })
            // }
            // this.ajaxService.ajaxPutAsText(url, data)
            //   .subscribe(res => {
            //     if (res == "Success") {
            //       this.commonService.presentToast("Group updated successfully")
            //     } else {
            //       this.commonService.presentToast("Group not updated successfully")
            //     }
            //   })

          }
          else{
            this.submitbtn=false;
            this.commonService.presentToast("Problem in persisting, Try after some time")
          }  
          this.commonService.dismissLoader()
          console.log(res)
        })
    }

  }

  fleetChange(event) {
    if (this.mode == "multiEdit" && this.fleetManagersComobox.getCheckedItems().length != 0)
      this.openModalAppReplace = true
  }
  conModalPopUp(eve) {
    if (eve == "cancel") {
      this.fleetManagersComobox.uncheckAll()
    } else if (eve == 'replace') {
      this.multipleAppend.fleetManager = true
    } else {
      this.multipleAppend.fleetManager = false
    }
    this.openModalAppReplace = false;
  }

  editVehicle() {
    if (this.entryPoint != "ATM") {
      this.zone = {
        circle: [],
        landmark: [],
        freeform: [],
        road: []
      }
      this.fenceEdit = {
        circle: [],
        landmark: [],
        freeform: [],
        road: []
      };
      this.checkBoxIODetails = {
        digitalInput2: this.fleetLogin.value.digitalInput2CheckBox,
        digitalInput3: this.fleetLogin.value.digitalInput3CheckBox,
        digitalInput4: this.fleetLogin.value.digitalInput4CheckBox,
        digitalOutput1: this.fleetLogin.value.digitalOutput1CheckBox,
        digitalOutput2: this.fleetLogin.value.digitalOutput2CheckBox,
        digitalOutput3: this.fleetLogin.value.digitalOutput3CheckBox,
        digitalOutput4: this.fleetLogin.value.digitalOutput4CheckBox,
      }



      this.fenceChangeValue = {
        circle: -1,
        landmark: -1,
        freeform: -1,
        road: -1
      }
      let id = this.geofenceTree.getCheckedItems();

      for (let i = 0; i < id.length; i++) {

        if (this.treeGeofenceObject[id[i].value].json) {
          if (this.treeGeofenceObject[id[i].value].json.type == "Circle")
            this.zone.circle.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
          if (this.treeGeofenceObject[id[i].value].json.type == "Freeform")
            this.zone.freeform.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
          if (this.treeGeofenceObject[id[i].value].json.type == "Road")
            this.zone.road.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
          if (this.treeGeofenceObject[id[i].value].json.type == "Landmark")
            this.zone.landmark.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
        }
      }



      if ((this.selectedRow.geozones_id == "" && this.zone.circle.length > 0) || (this.selectedRow.geozones_id != "" && this.zone.circle.length == 0))
        this.fenceChangeValue.circle = 1
      if ((this.selectedRow.landmark == "" && this.zone.landmark.length > 0) || (this.selectedRow.landmark != "" && this.zone.landmark.length == 0))
        this.fenceChangeValue.landmark = 1
      if ((this.selectedRow.routeid == "" && this.zone.road.length > 0) || (this.selectedRow.routeid != "" && this.zone.road.length == 0))
        this.fenceChangeValue.road = 1
      if ((this.selectedRow.freeformid == "" && this.zone.freeform.length > 0) || (this.selectedRow.freeformid != "" && this.zone.freeform.length == 0))
        this.fenceChangeValue.freeform = 1

      if (this.selectedRow.geozones_id != "" && this.selectedRow.geozones_id.split(",").length > 0)
        for (let i = 0; i < this.selectedRow.geozones_id.split(",").length; i++) {
          if (this.selectedRow.geozones_id.split(",").length < this.zone.circle.length || this.selectedRow.geozones_id.split(",").length > this.zone.circle.length)
            this.fenceChangeValue.circle = 1;
          if (this.zone.circle.includes(this.selectedRow.geozones_id.split(",")[i])) {
            console.log(this.selectedRow.geozones_id.split(",")[i]);
          } else {
            this.fenceChangeValue.circle = 1;
            this.fenceEdit.circle.push(this.selectedRow.geozones_id.split(",")[i])
          }
        }


      if (this.selectedRow.landmark != "" && this.selectedRow.landmark.split(",").length > 0)
        for (let i = 0; i < this.selectedRow.landmark.split(",").length; i++) {
          if (this.selectedRow.landmark.split(",").length < this.zone.landmark.length || this.selectedRow.landmark.split(",").length > this.zone.landmark.length)
            this.fenceChangeValue.landmark = 1;
          if (this.zone.landmark.includes(this.selectedRow.landmark.split(",")[i])) {
            console.log(this.selectedRow.landmark.split(",")[i]);
          } else {
            this.fenceChangeValue.landmark = 1;
            this.fenceEdit.landmark.push(this.selectedRow.landmark.split(",")[i])
          }
        }


      if (this.selectedRow.routeid != "" && this.selectedRow.routeid.split(",").length > 0)
        for (let i = 0; i < this.selectedRow.routeid.split(",").length; i++) {
          if (this.selectedRow.routeid.split(",").length < this.zone.road.length || this.selectedRow.routeid.split(",").length > this.zone.road.length)
            this.fenceChangeValue.road = 1;
          if (this.zone.road.includes(this.selectedRow.routeid.split(",")[i])) {
            console.log(this.selectedRow.routeid.split(",")[i]);
          } else {
            this.fenceChangeValue.road = 1;
            this.fenceEdit.road.push(this.selectedRow.routeid.split(",")[i])
          }
        }


      if (this.selectedRow.freeformid != "" && this.selectedRow.freeformid.split(",").length > 0)
        for (let i = 0; i < this.selectedRow.freeformid.split(",").length; i++) {
          if (this.selectedRow.freeformid.split(",").length < this.zone.freeform.length || this.selectedRow.freeformid.split(",").length > this.zone.freeform.length)
            this.fenceChangeValue.freeform = 1;
          if (this.zone.freeform.includes(this.selectedRow.freeformid.split(",")[i])) {
            console.log(this.selectedRow.freeformid.split(",")[i]);
          } else {
            this.fenceChangeValue.freeform = 1;
            this.fenceEdit.freeform.push(this.selectedRow.freeformid.split(",")[i])
          }
        }

      this.inputAndOutputJson = {
        digitalInput1: this.digitalInput1Combobox.getSelectedItem() != null ? this.digitalInput1Combobox.getSelectedItem().label : "",
        digitalInput2: this.digitalInput2Combobox.getSelectedItem() != null ? this.digitalInput2Combobox.getSelectedItem().label : "",
        digitalInput3: this.digitalInput3Combobox.getSelectedItem() != null ? this.digitalInput3Combobox.getSelectedItem().label : "",
        digitalInput4: this.digitalInput4Combobox.getSelectedItem() != null ? this.digitalInput4Combobox.getSelectedItem().label : "",
        digitalOutput1: this.digitalOutput1Combobox.getSelectedItem() != null ? this.digitalOutput1Combobox.getSelectedItem().label : "",
        digitalOutput2: this.digitalOutput2Combobox.getSelectedItem() != null ? this.digitalOutput2Combobox.getSelectedItem().label : "",
        digitalOutput3: this.digitalOutput3Combobox.getSelectedItem() != null ? this.digitalOutput3Combobox.getSelectedItem().label : "",
        digitalOutput4: this.digitalOutput4Combobox.getSelectedItem() != null ? this.digitalOutput4Combobox.getSelectedItem().label : "",
        analogInput1: "", analogInput2: "", analogInput3: "", analogInput4: ""
      };
      document.getElementsByName("digitalInputData").forEach((res: any) => {
        if (res.checked == true)
          this.inputAndOutputJson[res.value] = this.inputAndOutputJson[res.value] + "|1"
        else {
          this.inputAndOutputJson[res.value] = this.inputAndOutputJson[res.value]
        }
      })
      this.analogInput = {
        "Analog input 1": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
        "Analog input 2": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
        "Analog input 3": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
        "Analog input 4": { type: "", input: "", min: "", max: "", mv: [], liter: [] }
      };
      if (this.analogTableArrayWholeValue.length > 0) {
        console.log(this.analogInput)
        for (let i = 0; i < this.analogTableArrayWholeValue.length; i++) {
          let analogOfType = this.analogInput[this.analogTableArrayWholeValue[i].analog]
          analogOfType["type"] = this.analogTableArrayWholeValue[i].type;
          analogOfType["input"] = this.analogTableArrayWholeValue[i].input;
          analogOfType["min"] = this.analogTableArrayWholeValue[i].min;
          analogOfType["max"] = this.analogTableArrayWholeValue[i].max;
          analogOfType["mv"].push(this.analogTableArrayWholeValue[i].mv)
          analogOfType["liter"].push(this.analogTableArrayWholeValue[i].liter)
        }
      }

      if (this.colorTreeStu.getSelectedItem() != null)
        this.groupColor1 = this.colorTreeStu.getSelectedItem().value;
      else
        this.selectedRow.group_Color == "" ? "" : this.selectedRow.group_Color;
      this.operator = this.operatorComobox.getSelectedItem() != null ? this.operatorComobox.getSelectedItem().value : ""
      this.vehicleFuel1 = this.vehicleFuelTypeComboBox.getSelectedItem()
    }
    let fleet = []
    for (let i = 0; i < this.fleetManagersComobox.getCheckedItems().length; i++) {
      fleet.push(this.fleetManagersComobox.getCheckedItems()[i]["value"])
    }
    let deg = { debuggingMode: Boolean };
    if (localStorage.companyRole == "CompanyAdmin") {
      console.log(this.Debuggingmode.getSelectedItem())
      deg.debuggingMode = this.Debuggingmode.getSelectedItem() == null ? false : this.Debuggingmode.getSelectedItem().originalItem;
    }
    else
      deg = this.selectedRow['assetInformation'] ? { debuggingMode: JSON.parse(this.selectedRow['assetInformation']).debuggingMode } : { debuggingMode: false };



    if (this.entryPoint != "ATM") {
      this.data = {
        "companyID": localStorage.corpId,
        "branchID": localStorage.corpId,
        "username": localStorage.userName,
        "emailId": this.fleetManagers.toString(),
        "userEntry": "",
        "groupColor": this.groupColor1,
        "fleetUser": fleet.toString() + "," + localStorage.userName,
        "replace": false,
        "multiselect": false,
        "plateNo": this.fleetLogin.value.plateNo.trim(),
        "oldCheckRoad": 0,
        "oldcheckFreeForm": 0,
        "oldcheckGeoZone": 0,
        "oldchecklandMark": 0,
        "oldcheckshift": 0,
        "type": this.vehicleManufactureComboBox.getSelectedItem() == null ? "" : this.vehicleManufactureComboBox.getSelectedItem().value,
        "icon": this.vehicleTypeComboBox.getSelectedItem() == null ? "" : this.vehicleTypeComboBox.getSelectedItem().value,
        "model": this.vehicleModelComboBox.getSelectedItem() == null ? "" : this.vehicleModelComboBox.getSelectedItem().value,
        "group": this.groupTreeStu.getSelectedItem() == null ? "true" : this.groupTreeStu.getSelectedItem().value,//
        "dateofpurchase": this.selectedRow.Dop,//
        "insuranceExpiry": "true",//
        "expectedvehiclemilage": this.fleetLogin.value.kmPerLiter.trim() == "" ? "0" : this.fleetLogin.value.kmPerLiter.trim(),
        "contactNo": "true",//
        "fuelTanklit": this.fleetLogin.value.fuelCapacity.trim() == "" ? "true" : this.fleetLogin.value.fuelCapacity.trim(),
        "additionalWarranty": "true",//
        "dateofreg": "true",//
        "regexpiry": "true",//
        "mileageInit": (this.fleetLogin.value.kmPerLiter == "" ? "true" : this.fleetLogin.value.kmPerLiter) + "-" + (this.vehicleFuel1 == null ? "Petrol" : this.vehicleFuel1.value) + "-" + (this.fleetLogin.value.pricePerLiter == "" ? "0" : this.fleetLogin.value.pricePerLiter),
        "odometer": this.fleetLogin.value.odometerValue.trim() == "" ? "true" : this.fleetLogin.value.odometerValue.trim(),

        "PreventiveMaintenanceType": "hours",
        "reverseSetting": (this.checkBoxIODetails["digitalInput2"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalInput3"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalInput4"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput1"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput2"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput3"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput4"] == true ? "1" : "0"),
        "imeiNo": this.fleetLogin.value.imei,
        "digitalInput1": this.inputAndOutputJson.digitalInput1,
        "digitalInput2": this.inputAndOutputJson.digitalInput2,
        "digitalInput3": this.inputAndOutputJson.digitalInput3,
        "digitalInput4": this.inputAndOutputJson.digitalInput4,

        "DigitalOutput": this.inputAndOutputJson.digitalOutput1 + "|" + this.inputAndOutputJson.digitalOutput2 + "|" + this.inputAndOutputJson.digitalOutput3 + "|" + this.inputAndOutputJson.digitalOutput4,
        "analoginput1": this.analogInput["Analog input 1"].type + "|" + this.analogInput["Analog input 1"].min + "|" + this.analogInput["Analog input 1"].max + "|" + this.analogInput["Analog input 1"].input,
        "analoginput2": this.analogInput["Analog input 2"].type + "|" + this.analogInput["Analog input 2"].min + "|" + this.analogInput["Analog input 2"].max + "|" + this.analogInput["Analog input 2"].input,
        "analoginput3": this.analogInput["Analog input 3"].type + "|" + this.analogInput["Analog input 3"].min + "|" + this.analogInput["Analog input 3"].max + "|" + this.analogInput["Analog input 3"].input,
        "analoginput4": this.analogInput["Analog input 4"].type + "|" + this.analogInput["Analog input 4"].min + "|" + this.analogInput["Analog input 4"].max + "|" + this.analogInput["Analog input 4"].input,

        "onewiredinput": "",
        "actual1": this.analogInput["Analog input 1"].liter,
        "mv1": this.analogInput["Analog input 1"].mv,
        "actual2": this.analogInput["Analog input 2"].liter,
        "mv2": this.analogInput["Analog input 2"].mv,
        "actual3": this.analogInput["Analog input 3"].liter,
        "mv3": this.analogInput["Analog input 3"].mv,
        "actual4": this.analogInput["Analog input 4"].liter,
        "mv4": this.analogInput["Analog input 4"].mv,

        "checkDevice": 0,
        "checkOperator": this.selectedRow.OperatorId != this.operator ? 1 : 0,
        "effFrom": this.selectedRow.effFrom,
        "group_Color": this.colorTreeStu.getSelectedItem() == null ? "" : this.colorTreeStu.getSelectedItem().value,

        "checkshift": -1,//
        "checkRoadGeo": this.fenceChangeValue.road,
        "checkFreeForm": this.fenceChangeValue.freeform,
        "checkGeoZone": this.fenceChangeValue.circle,
        "checklandMark": this.fenceChangeValue.landmark,
        "fuelTankCapacity": (this.fleetLogin.value.fuelTankCapacity.trim()) ? this.fleetLogin.value.fuelTankCapacity.trim() : "",
        "oldfreeForm": this.fenceEdit.freeform,
        "oldroadGeo": this.fenceEdit.road,
        "oldprefRest": this.fenceEdit.circle,
        "oldlandMark": this.fenceEdit.landmark,

        "freeForm": this.zone.freeform.length != 0 ? this.zone.freeform : "true",
        "prefRest": this.zone.circle.length != 0 ? this.zone.circle : [],
        "landMark": this.zone.landmark.length != 0 ? this.zone.landmark : "true",
        "roadGeo": this.zone.road.length != 0 ? this.zone.road : "true",

        "isLandArray": this.zone.landmark.length == 0 ? false : true,
        "isCirlrectArray": this.zone.circle.length == 0 ? false : true,
        "isFreeformArray": this.zone.freeform.length == 0 ? false : true,
        "isRoadArray": this.zone.road.length == 0 ? false : true,

        "isOldPrefRestArray": this.fenceEdit.circle.length == 0 ? false : true,
        "isOldLandMarkArray": this.fenceEdit.landmark.length == 0 ? false : true,
        "isOldFreeFormArray": this.fenceEdit.freeform.length == 0 ? false : true,
        "isOldRoadGeoArray": this.fenceEdit.road.length == 0 ? false : true,

        "showHierarchy": false,

        "vin": this.selectedRow["Vehicle Number"],
        "debuggingMode": deg != undefined ? JSON.stringify(deg) : undefined,
        "preventiveselect": this.fleetLogin.value.preventiveDue != "" ? this.fleetLogin.value.preventiveDue * 60 : 3000,
        "preventivelimitselect": this.fleetLogin.value.dueLimits != "" ? this.fleetLogin.value.dueLimits * 60 : 30,
        "scheduleselect": 3000,
        "schedulelimitselect": 30,
        "genCapacity": this.fleetLogin.value.gensetCapacityValue.trim() != "" ? this.gensetCapacityUnitComboBox.getSelectedItem() == null ? this.fleetLogin.value.gensetCapacityValue.trim() + " KW" : this.fleetLogin.value.gensetCapacityValue.trim() + " " + this.gensetCapacityUnitComboBox.getSelectedItem().value : "0",
        "regNo": this.fleetLogin.value.vehicleRegNo.trim(),
        "branch": this.fleetLogin.value.vehicleBranch.trim(),
        "workingStatus": this.fleetLogin.value.workingStatus.trim(),
        "vName": this.fleetLogin.value.vehicleName.trim(),
        "remarks": this.assetMove,
        "loadSensorImplements": this.LoadSensorImplement.getSelectedItem() == null ? "false" : this.LoadSensorImplement.getSelectedItem().value,
        "year": this.yearselect.getSelectedItem() == null ? "" : this.yearselect.getSelectedItem().value,
      }
    } else {
      this.data = {
        "companyID": localStorage.corpId,
        "branchID": localStorage.corpId,
        "username": localStorage.userName,
        "emailId": this.fleetManagers.toString(),
        "userEntry": "",
        "groupColor": "",
        "fleetUser": fleet.toString() + "," + localStorage.userName,
        "replace": false,
        "multiselect": false,
        "plateNo": this.fleetLogin.value.plateNo.trim(),
        "oldCheckRoad": 0,
        "oldcheckFreeForm": 0,
        "oldcheckGeoZone": 0,
        "oldchecklandMark": 0,
        "oldcheckshift": 0,
        // "assetCode": this.fleetLogin.value.assertCode == undefined ? "" : this.fleetLogin.value.assertCode,
        "type": "",
        "icon": "ATM",
        "height": "",
        "model": "ATM",
        "group": this.groupTreeStu.getSelectedItem() == null ? "true" : this.groupTreeStu.getSelectedItem().value, //
        "dateofpurchase": new Date().toJSON().split("T")[0],
        "insuranceExpiry": "true",
        "expectedvehiclemilage": "0",
        "contactNo": "true",
        "fuelTanklit": "true",
        "additionalWarranty": "true",
        "dateofreg": "true",
        "regexpiry": "true",
        "mileageInit": "",
        "odometer": "true",
        "PreventiveMaintenanceType": "hours",
        "reverseSetting": "0|0|0|0|0|0|0",
        "digitalInput1": "",
        "digitalInput2": "",
        "digitalInput3": "",
        "digitalInput4": "",
        "fuelTankCapacity": "",
        "DigitalOutput": "|||",
        "analoginput1": "|||",
        "analoginput2": "|||",
        "analoginput3": "|||",
        "analoginput4": "|||",
        "onewiredinput": "",
        "actual1": [],
        "mv1": [],
        "actual2": [],
        "mv2": [],
        "actual3": [],
        "mv3": [],
        "actual4": [],
        "mv4": [],
        "imeiNo": this.fleetLogin.value.imei,
        "roadGeo": "true",
        "isRoadArray": false,
        "freeForm": "true",
        "isFreeformArray": false,
        "prefRest": "true",
        "isCirlrectArray": false,
        "landMark": "true",//
        "isLandArray": false,
        "showHierarchy": false,
        "vin": this.selectedRow["Vehicle Number"],
        "debuggingMode": deg != undefined ? JSON.stringify(deg) : undefined,
        "preventiveselect": 0,
        "preventivelimitselect": 0,
        "scheduleselect": 0,
        "schedulelimitselect": 0,
        "genCapacity": "0 KW",
        "regNo": "",
        "branch": "",
        "workingStatus": "",
        "vName": "",
        "remarks": "",
        "loadSensorImplements": "false",
        "year": "",
        "checkRoadGeo": -1,
        "checkFreeForm": -1,
        "checkGeoZone": -1,
        "checklandMark": -1,
        "checkOperator": 0,
        "checkshift": -1,
        "checkDevice": 0,
        "effFrom": "",
        "isOldPrefRestArray": false,
        "isOldLandMarkArray": false,
        "isOldFreeFormArray": false,
        "isOldRoadGeoArray": false,
        "oldfreeForm": [],
        "oldroadGeo": [],
        "oldprefRest": [],
        "oldlandMark": [],
      }

    }



    if (this.selectedRow["IMEI Number"] != this.fleetLogin.value.imei) {
      this.data["oldimeiNo"] = this.selectedRow["IMEI Number"]
      this.data["checkDevice"] = 1
    }
    if (this.entryPoint != "ATM") {
      if (this.operatorComobox.getSelectedItem() != null)
        this.data["operatorID"] = this.operatorComobox.getSelectedItem().value
      if (this.operatorComobox.getSelectedItem() != null) {
        if (this.selectedRow.OperatorId != this.operatorComobox.getSelectedItem().value)
          this.data["oldoperatorID"] = this.selectedRow.OperatorId;
      } else if (this.selectedRow.OperatorId && this.operatorComobox.getSelectedItem() == null) {
        this.data["oldoperatorID"] = this.selectedRow.OperatorId;
        this.data["operatorID"] = ""
      }
    }
    if (this.selectedRow.vehicleHeight != "")
      this.data["height"] = this.fleetLogin.value.vehicleHeight

    this.data["oldValue"] = JSON.stringify(this.commonService.userAdminControlEditDelete(this.editedDataArray, this.selectedRow, this.vehicleAssFleets));

    const url = serverUrl.web + "/VehicleController/editvehdetails"
    this.ajaxService.ajaxPostMethod(url, this.data)
      .subscribe(res => {
        if (res.jsonfinalstatus == "persisted") {
          this.submitbtn=false;
          this.commonService.presentToast("Updated successfully")
          if (this.websocketService.isAlive("livetrack")) {
            this.websocketService.reSendRequest(JSON.parse(localStorage.dashboardWebSocketData));
          }

          if (this.entryPoint != "ATM")
            if (this.selectedRow.iconUrl !== this.vehicleTypeComboBox.getSelectedItem().value) {
              let a = [{ vin: this.selectedRow["Vehicle Number"] }];
              this.configalerts(a);
            }
          this.modalController.dismiss();
          if (this.entryPoint != "ATM")
            this.ajaxService.ajaxPostWithBody(serverUrl.Admin + "/api/alerts/geoconfig", { vins: this.selectedRow["Vehicle Number"].toString() })
              .subscribe(res => {

              })
          // const url = serverUrl.Admin + "/api/updateGroup"
          // let data = []

          // data.push({
          //   "appName": app.appName,
          //   "group": this.groupTreeStu.getSelectedItem() != null ? this.groupTreeStu.getSelectedItem().value : this.selectedRow["Group"] = "" ? "true" : this.selectedRow["Group"],
          //   "vin": this.selectedRow["Vehicle Number"]
          // })

          // this.ajaxService.ajaxPutAsText(url, data)
          //   .subscribe(res => {
          //     if (res == "Success") {
          //       this.commonService.presentToast("Group updated successfully")
          //     } else {
          //       this.commonService.presentToast("Group not updated successfully")
          //     }
          //   })
        }
      })
  }

  checkBoxDetails(digital) {
    this.checkBoxIODetails[digital] = !this.checkBoxIODetails[digital]
  }


  assign() {
    var fleetUser;
    if (this.entryPoint != "ATM") {
      let id = this.geofenceTree.getCheckedItems();
      this.zone = {
        circle: [],
        landmark: [],
        freeform: [],
        road: []
      }


      this.checkBoxIODetails = {
        digitalInput2: this.fleetLogin.value.digitalInput2CheckBox,
        digitalInput3: this.fleetLogin.value.digitalInput3CheckBox,
        digitalInput4: this.fleetLogin.value.digitalInput4CheckBox,
        digitalOutput1: this.fleetLogin.value.digitalOutput1CheckBox,
        digitalOutput2: this.fleetLogin.value.digitalOutput2CheckBox,
        digitalOutput3: this.fleetLogin.value.digitalOutput3CheckBox,
        digitalOutput4: this.fleetLogin.value.digitalOutput4CheckBox,
      }


      this.inputAndOutputJson = {
        digitalInput1: this.digitalInput1Combobox.getSelectedItem() != null ? this.digitalInput1Combobox.getSelectedItem().label : "",
        digitalInput2: this.digitalInput2Combobox.getSelectedItem() != null ? this.digitalInput2Combobox.getSelectedItem().label : "",
        digitalInput3: this.digitalInput3Combobox.getSelectedItem() != null ? this.digitalInput3Combobox.getSelectedItem().label : "",
        digitalInput4: this.digitalInput4Combobox.getSelectedItem() != null ? this.digitalInput4Combobox.getSelectedItem().label : "",
        digitalOutput1: this.digitalOutput1Combobox.getSelectedItem() != null ? this.digitalOutput1Combobox.getSelectedItem().label : "",
        digitalOutput2: this.digitalOutput2Combobox.getSelectedItem() != null ? this.digitalOutput2Combobox.getSelectedItem().label : "",
        digitalOutput3: this.digitalOutput3Combobox.getSelectedItem() != null ? this.digitalOutput3Combobox.getSelectedItem().label : "",
        digitalOutput4: this.digitalOutput4Combobox.getSelectedItem() != null ? this.digitalOutput4Combobox.getSelectedItem().label : "",
        analogInput1: "", analogInput2: "", analogInput3: "", analogInput4: ""
      };


      for (let i = 0; i < id.length; i++) {
        var selectedRow = this.treeGeofenceObject[id[i].value].json;
        if (this.treeGeofenceObject[id[i].value].json) {
          if (this.treeGeofenceObject[id[i].value].json.type == "Circle")
            this.zone.circle.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
          if (this.treeGeofenceObject[id[i].value].json.type == "Freeform")
            this.zone.freeform.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
          if (this.treeGeofenceObject[id[i].value].json.type == "Road")
            this.zone.road.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
          if (this.treeGeofenceObject[id[i].value].json.type == "Landmark")
            this.zone.landmark.push(this.treeGeofenceObject[id[i].value].value.split("-")[0])
        }
      }

      this.vehicleFuel = this.vehicleFuelTypeComboBox.getSelectedItem()

      document.getElementsByName("digitalInputData").forEach((res: any) => {
        if (res.checked == true)
          this.inputAndOutputJson[res.value] = this.inputAndOutputJson[res.value] + "|1"
        else {
          this.inputAndOutputJson[res.value] = this.inputAndOutputJson[res.value]
        }
      })
      this.analogInput = {
        "Analog input 1": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
        "Analog input 2": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
        "Analog input 3": { type: "", input: "", min: "", max: "", mv: [], liter: [] },
        "Analog input 4": { type: "", input: "", min: "", max: "", mv: [], liter: [] }
      };
      if (this.analogTableArrayWholeValue.length > 0) {
        console.log(this.analogInput)
        for (let i = 0; i < this.analogTableArrayWholeValue.length; i++) {
          let analogOfType = this.analogInput[this.analogTableArrayWholeValue[i].analog]
          analogOfType["type"] = this.analogTableArrayWholeValue[i].type;
          analogOfType["input"] = this.analogTableArrayWholeValue[i].input;
          analogOfType["min"] = this.analogTableArrayWholeValue[i].min;
          analogOfType["max"] = this.analogTableArrayWholeValue[i].max;
          analogOfType["mv"].push(this.analogTableArrayWholeValue[i].mv)
          analogOfType["liter"].push(this.analogTableArrayWholeValue[i].liter)
        }
      }
    }
    let fleet = [];
    for (let i = 0; i < this.fleetManagersComobox.getCheckedItems().length; i++) {
      fleet.push(this.fleetManagersComobox.getCheckedItems()[i]["value"])
    }
    let deg = { debuggingMode: Boolean };
    if (localStorage.companyRole == "CompanyAdmin")
      deg.debuggingMode = this.Debuggingmode.getSelectedItem() == null ? false : this.Debuggingmode.getSelectedItem().originalItem;
    else
      deg = undefined;


    const url = serverUrl.web + "/VehicleController/addvehdetails";
    if (this.entryPoint != "ATM") {
      this.body = {
        "companyID": localStorage.corpId,
        "branchID": localStorage.corpId,
        "username": localStorage.userName,
        "emailId": localStorage.password,
        "userEntry": "",
        "groupColor": "",
        "fleetUser": fleet.toString() + "," + localStorage.userName,
        "replace": false,
        "multiselect": false,
        "plateNo": this.fleetLogin.value.plateNo.trim(),
        "oldCheckRoad": 0,
        "oldcheckFreeForm": 0,
        "oldcheckGeoZone": 0,
        "oldchecklandMark": 0,
        "oldcheckshift": 0,
        // "assetCode": this.fleetLogin.value.assertCode == undefined ? "" : this.fleetLogin.value.assertCode,
        "type": this.vehicleManufactureComboBox.getSelectedItem() == null ? "" : this.vehicleManufactureComboBox.getSelectedItem().value,
        "icon": this.vehicleTypeComboBox.getSelectedItem() == null ? "" : this.vehicleTypeComboBox.getSelectedItem().value,
        "height": this.fleetLogin.value.vehicleHeight.trim(),
        "model": this.vehicleModelComboBox.getSelectedItem() == null ? "" : this.vehicleModelComboBox.getSelectedItem().value,
        "group": this.groupTreeStu.getSelectedItem() == null ? "true" : this.groupTreeStu.getSelectedItem().value, //
        "dateofpurchase": new Date().toJSON().split("T")[0],
        "insuranceExpiry": "true",
        "expectedvehiclemilage": this.fleetLogin.value.kmPerLiter.trim() == "" ? "0" : this.fleetLogin.value.kmPerLiter.trim(),
        "contactNo": "true",
        "fuelTanklit": this.fleetLogin.value.fuelCapacity.trim() == "" ? "true" : this.fleetLogin.value.fuelCapacity.trim(),
        "additionalWarranty": "true",
        "dateofreg": "true",
        "regexpiry": "true",
        "mileageInit": (this.fleetLogin.value.kmPerLiter.trim() == "" ? "true" : this.fleetLogin.value.kmPerLiter.trim()) + "-" + (this.vehicleFuel == null ? "Petrol" : this.vehicleFuel.value) + "-" + (this.fleetLogin.value.pricePerLiter.trim() == "" ? "0" : this.fleetLogin.value.pricePerLiter.trim()),
        "odometer": this.fleetLogin.value.odometerValue.trim() == "" ? "true" : this.fleetLogin.value.odometerValue.trim(),
        "PreventiveMaintenanceType": "hours",
        "reverseSetting": (this.checkBoxIODetails["digitalInput2"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalInput3"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalInput4"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput1"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput2"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput3"] == true ? "1" : "0") + "|" + (this.checkBoxIODetails["digitalOutput4"] == true ? "1" : "0"),
        "digitalInput1": this.inputAndOutputJson.digitalInput1,
        "digitalInput2": this.inputAndOutputJson.digitalInput2,
        "digitalInput3": this.inputAndOutputJson.digitalInput3,
        "digitalInput4": this.inputAndOutputJson.digitalInput4,
        "fuelTankCapacity": (this.fleetLogin.value.fuelTankCapacity.trim()) ? this.fleetLogin.value.fuelTankCapacity.trim() : "",
        "DigitalOutput": this.inputAndOutputJson.digitalOutput1 + "|" + this.inputAndOutputJson.digitalOutput2 + "|" + this.inputAndOutputJson.digitalOutput3 + "|" + this.inputAndOutputJson.digitalOutput4,
        "analoginput1": this.analogInput["Analog input 1"].type + "|" + this.analogInput["Analog input 1"].min + "|" + this.analogInput["Analog input 1"].max + "|" + this.analogInput["Analog input 1"].input,
        "analoginput2": this.analogInput["Analog input 2"].type + "|" + this.analogInput["Analog input 2"].min + "|" + this.analogInput["Analog input 2"].max + "|" + this.analogInput["Analog input 2"].input,
        "analoginput3": this.analogInput["Analog input 3"].type + "|" + this.analogInput["Analog input 3"].min + "|" + this.analogInput["Analog input 3"].max + "|" + this.analogInput["Analog input 3"].input,
        "analoginput4": this.analogInput["Analog input 4"].type + "|" + this.analogInput["Analog input 4"].min + "|" + this.analogInput["Analog input 4"].max + "|" + this.analogInput["Analog input 4"].input,
        "onewiredinput": "",
        "actual1": this.analogInput["Analog input 1"].liter,
        "mv1": this.analogInput["Analog input 1"].mv,
        "actual2": this.analogInput["Analog input 2"].liter,
        "mv2": this.analogInput["Analog input 2"].mv,
        "actual3": this.analogInput["Analog input 3"].liter,
        "mv3": this.analogInput["Analog input 3"].mv,
        "actual4": this.analogInput["Analog input 4"].liter,
        "mv4": this.analogInput["Analog input 4"].mv,
        "imeiNo": this.fleetLogin.value.imei,
        "roadGeo": this.zone.road.length != 0 ? this.zone.road : "true",
        "isRoadArray": this.zone.road.length == 0 ? false : true,
        "freeForm": this.zone.freeform.length != 0 ? this.zone.freeform : "true",
        "isFreeformArray": this.zone.freeform.length == 0 ? false : true,
        "prefRest": this.zone.circle.length != 0 ? this.zone.circle : "true",
        "isCirlrectArray": this.zone.circle.length == 0 ? false : true,
        "landMark": this.zone.landmark.length != 0 ? this.zone.landmark : "true",//
        "isLandArray": this.zone.landmark.length == 0 ? false : true,
        "shift": "true",
        "showHierarchy": false,
        "vin": this.vin,
        "debuggingMode": deg != undefined ? JSON.stringify(deg) : undefined,
        "preventiveselect": this.fleetLogin.value.preventiveDue != "" ? this.fleetLogin.value.preventiveDue * 60 : 3000,
        "preventivelimitselect": this.fleetLogin.value.dueLimits != "" ? this.fleetLogin.value.dueLimits * 60 : 30,
        "scheduleselect": 3000,
        "schedulelimitselect": 30,
        "genCapacity": this.fleetLogin.value.gensetCapacityValue.trim() != "" ? this.gensetCapacityUnitComboBox.getSelectedItem() == null ? this.fleetLogin.value.gensetCapacityValue.trim() + "KW" : this.fleetLogin.value.gensetCapacityValue.trim() + " " + this.gensetCapacityUnitComboBox.getSelectedItem().value : "0",
        "regNo": this.fleetLogin.value.vehicleRegNo.trim(),
        "branch": this.fleetLogin.value.vehicleBranch.trim(),
        "workingStatus": this.fleetLogin.value.workingStatus.trim(),
        "vName": this.fleetLogin.value.vehicleName.trim(),
        "remarks": this.assetMove,
        "loadSensorImplements": this.LoadSensorImplement.getSelectedItem() == null ? "false" : this.LoadSensorImplement.getSelectedItem().value,
        "year": this.yearselect.getSelectedItem() == null ? "" : this.yearselect.getSelectedItem().value,
      }

      if (this.operatorComobox.getSelectedItem() != null)
        this.body["operatorID"] = this.operatorComobox.getSelectedItem().value
      if (this.colorTreeStu.getSelectedItem() != null)
        this.body["groupColor"] = this.colorTreeStu.getSelectedItem().value
    } else {
      this.body = {
        "companyID": localStorage.corpId,
        "branchID": localStorage.corpId,
        "username": localStorage.userName,
        "emailId": localStorage.password,
        "userEntry": "",
        "groupColor": "",
        "fleetUser": fleet.toString() + "," + localStorage.userName,
        "replace": false,
        "multiselect": false,
        "plateNo": this.fleetLogin.value.plateNo.trim(),
        "oldCheckRoad": 0,
        "oldcheckFreeForm": 0,
        "oldcheckGeoZone": 0,
        "oldchecklandMark": 0,
        "oldcheckshift": 0,
        // "assetCode": this.fleetLogin.value.assertCode == undefined ? "" : this.fleetLogin.value.assertCode,
        "type": "",
        "icon": "ATM",
        "height": "",
        "model": "ATM",
        "group": this.groupTreeStu.getSelectedItem() == null ? "true" : this.groupTreeStu.getSelectedItem().value, //
        "dateofpurchase": new Date().toJSON().split("T")[0],
        "insuranceExpiry": "true",
        "expectedvehiclemilage": "0",
        "contactNo": "true",
        "fuelTanklit": "true",
        "additionalWarranty": "true",
        "dateofreg": "true",
        "regexpiry": "true",
        "mileageInit": "",
        "odometer": "true",
        "PreventiveMaintenanceType": "hours",
        "reverseSetting": "0|0|0|0|0|0|0",
        "digitalInput1": "",
        "digitalInput2": "",
        "digitalInput3": "",
        "digitalInput4": "",
        "fuelTankCapacity": "",
        "DigitalOutput": "|||",
        "analoginput1": "|||",
        "analoginput2": "|||",
        "analoginput3": "|||",
        "analoginput4": "|||",
        "onewiredinput": "",
        "actual1": [],
        "mv1": [],
        "actual2": [],
        "mv2": [],
        "actual3": [],
        "mv3": [],
        "actual4": [],
        "mv4": [],
        "imeiNo": this.fleetLogin.value.imei,
        "roadGeo": "true",
        "isRoadArray": false,
        "freeForm": "true",
        "isFreeformArray": false,
        "prefRest": "true",
        "isCirlrectArray": false,
        "landMark": "true",//
        "isLandArray": false,
        "shift": "true",
        "showHierarchy": false,
        "vin": this.vin,
        "debuggingMode": deg != undefined ? JSON.stringify(deg) : undefined,
        "preventiveselect": 0,
        "preventivelimitselect": 0,
        "scheduleselect": 0,
        "schedulelimitselect": 0,
        "genCapacity": "0",
        "regNo": "",
        "branch": "",
        "workingStatus": "",
        "vName": "",
        "remarks": "",
        "loadSensorImplements": "false",
        "year": "",
      }
      //this.body["operatorID"] = ""
    }
    this.ajaxService.ajaxPostMethod(url, this.body)
      .subscribe(res => {
        if (res.response == "persisted") {
          this.submitbtn=false;
          if(this.entryPoint != "ATM")
          this.commonService.presentToast("Vehicle added successfully!")
        else
        this.commonService.presentToast("ATM added successfully!")
          let a = [{ vin: this.vin }];
          if (this.websocketService.isAlive("livetrack")) {
            this.websocketService.reSendRequest(JSON.parse(localStorage.dashboardWebSocketData));
          }
          this.configalerts(a);
          this.modalController.dismiss();
          this.ajaxService.ajaxPostWithBody(serverUrl.Admin + "/api/alerts/geoconfig", { vins: this.vin.toString() })
            .subscribe(res => {

            })
        }
      })
  }

  generateVin() {
    if (this.fleetLogin.value.imei != "" || this.fleetLogin.value.imei != " ") {
      const url = serverUrl.web + '/login/getPreferences?key=vinGenerationValue&companyId=' + localStorage.corpId;
      this.ajaxService.ajaxGetPerference(url)
        .subscribe(res => {
          const vehicleVin = localStorage.corpId + (Math.floor(Math.random() * res) + 100);
          const url = serverUrl.web + '/device/validate/vin?vin=' + vehicleVin;
          this.ajaxService.ajaxGetWithString(url)
            .subscribe(res => {
              if (res == "available") {
                this.generateVin();
              } else if (res == "notavailable") {
                this.vin = vehicleVin;
                this.assign();
              }
            });
        })

    } else {
      this.commonService.presentToast('Please assign imei properly');
    }
  }

  getFleetManager() {
    const url = serverUrl.web + '/user/fleetmanagers?companyId=' + localStorage.getItem('corpId') + '&branchId=' + localStorage.getItem('corpId');
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.fleetManagers = res;
        if (this.mode == "edit")
          setTimeout(res => {
            if (this.vehicleAssFleets)
              for (let g of this.vehicleAssFleets[this.selectedRow["Vehicle Number"].split(",")].split(",")) {
                // console.log("g", g);
                this.fleetManagersComobox.checkItem(g)
              }
          }, 500)
      })
    let assetArray = [];
    const url2 = serverUrl.web + '/VehicleController/getAllVehicleModelAndType';
    this.ajaxService.ajaxGet(url2)
      .subscribe(res => {
        assetArray = res.model;
        console.log(assetArray, "assetArray");

        this.assetModel = assetArray.map(element => {
          return element.toUpperCase().trim();
        });
        var index = this.assetModel.indexOf("");
        if (index !== -1)
          this.assetModel.splice(index, 1);
        this.assetType = res.type
        index = this.assetType.indexOf("");
        if (index !== -1)
          this.assetType.splice(index, 1);
        if (this.mode == "edit")
          setTimeout(() => {
            let makeModel = this.selectedRow["Make"].toUpperCase();
            if (this.entryPoint != "ATM")
              this.vehicleModelComboBox.selectItem(makeModel);
            let yearsec = this.selectedRow["year"];
            if (this.entryPoint != "ATM") {
              this.yearselect.selectItem(yearsec);
              this.vehicleManufactureComboBox.selectItem(this.selectedRow["Type"]);
              this.LoadSensorImplement.selectItem(this.selectedRow['loadSensorImplements'])
            }
            if (localStorage.companyRole == "CompanyAdmin") {
              if (this.selectedRow['assetInformation'] != "") {
                var debug = JSON.parse(this.selectedRow['assetInformation']);
                if (debug.debuggingMode == true)
                  this.Debuggingmode.selectItem(true)
                else
                  this.Debuggingmode.selectItem(false)
              } else
                this.Debuggingmode.selectItem(false)
            }





            if (this.vehicleAssFleets)
              for (let g of this.vehicleAssFleets[this.selectedRow["Vehicle Number"].split(",")].split(",")) {
                this.fleetManagersComobox.checkItem(g)
              }
            else {
              setTimeout(() => {
                this.vehicleModelComboBox.selectItem(this.selectedRow["Make"]);
                this.vehicleModelComboBox.selectItem(this.selectedRow["year"]);
                this.vehicleManufactureComboBox.selectItem(this.selectedRow["Type"]);
                if (this.vehicleAssFleets)
                  for (let g of this.vehicleAssFleets[this.selectedRow["Vehicle Number"].split(",")].split(",")) {
                    this.fleetManagersComobox.checkItem(g)
                  }
                this.editedDataArray = []
              }, 500);
            }
          }, 500)

      })



  }

  async openGeofence() {

    const modal = await this.modalController.create({
      component: GeofenceModelPage,
      cssClass: "geoFenceCreationModal",
      componentProps: {
        mode: 'add',
      }
    });
    modal.onDidDismiss().then(() => this.getGeofence());
    return await modal.present();
  }

  // vehicleDetails() {
  //   var engineHours = "00:00:00"
  //   var totalEngineHours = "00:00:00:00"
  //   if (this.fleetLogin.value.hours) {
  //     engineHours = this.fleetLogin.value.hours + ":" + this.fleetLogin.value.minutes + ":" + this.fleetLogin.value.seconds;
  //     totalEngineHours = this.fleetLogin.value.days + ":" + this.fleetLogin.value.totalhours + ":" + this.fleetLogin.value.totalMinutes + ":" + this.fleetLogin.value.totalSeconds;
  //   }

  //   var fleetManage = localStorage.getItem('userName');
  //   if (this.fleetLogin.value.fleetManager.includes("NoFleet")) {
  //     var fleetManager = localStorage.getItem('userName');
  //     //  this.fleetLogin.value.fleetManager.splice(this.fleetLogin.value.fleetManager.findIndex("NoFleet"), 1, fleetManage);
  //   }
  //   var checkedValues = [];
  //   var unCheckedValues = [];
  //   unCheckedValues = this.fleetManagers.filter(val => !this.fleetLogin.value.fleetManager.includes(val));
  //   for (var i = 0; i < unCheckedValues.length; i++) {
  //     if (unCheckedValues[i] === "NoFleet") {
  //       unCheckedValues.splice(i, 1);
  //       i--;
  //     }
  //   }
  //   if (checkedValues.length == 0) {

  //     (checkedValues).toString()

  //   }
  //   var addDetails = {
  //     "companyID": localStorage.getItem('corpId'), "branchID": localStorage.getItem('corpId'), "username": localStorage.getItem('userName'),
  //     "emailId": unCheckedValues.toString(), "imeiNo": JSON.stringify(this.fleetLogin.value.imei),
  //     "userEntry": "", "groupColor": "",
  //     "fleetUser": fleetManage + ',' + (this.fleetLogin.value.fleetManager).toString(),
  //     "plateNo": this.fleetLogin.value.plateNo, "oldCheckRoad": 0,
  //     "oldcheckFreeForm": 0, "oldcheckGeoZone": 0, "oldchecklandMark": 0,
  //     "oldcheckshift": 0, "type": "BikeApp", "icon": this.fleetLogin.value.vehicleType, "iconUrl": this.fleetLogin.value.vehicleType, "model": "Model",
  //     "group": "true", "dateofpurchase": "true", "insuranceExpiry": "true", "landMark": "true", "prefRest": "true",
  //     "expectedvehiclemilage": "0", "contactNo": "true", "fuelTanklit": "true", "roadGeo": "true", "freeForm": "true",
  //     "additionalWarranty": "true", "dateofreg": "true", "regexpiry": "true",
  //     "mileageInit": this.fleetLogin.value.km + '-' + this.fleetLogin.value.fuelType + '-' + this.fleetLogin.value.cost + '', "odometer": (this.fleetLogin.value.travelledKm).toString(), "preventiveselect": 3000,
  //     "preventivelimitselect": 30, "scheduleselect": 3000,
  //     "schedulelimitselect": 30,
  //     "PreventiveMaintenanceType": "hours", "operatorID": this.fleetLogin.value.operator,

  //     "reverseSetting": "0|0|0", "digitalInput1": "|",
  //     "digitalInput2": "|", "digitalInput3": "|", "digitalInput4": "|", "DigitalOutput": "|||",
  //     "analoginput1": "|||", "analoginput2": "|||", "analoginput3": "|||", "analoginput4": "|||",
  //     "onewiredinput": "", "actual1": "", "mv1": "", "actual2": "", "mv2": "", "actual3": "", "mv3": "",
  //     "actual4": "", "mv4": "", "checkDevice": 0, "checkOperator": 0, "checkshift": -1,
  //     "checkRoadGeo": -1, "checkFreeForm": -1, "checkGeoZone": -1, "checklandMark": -1,
  //     "showHierarchy": false, "vin": this.vin
  //   }
  //   // const url = serverUrl.web + '/site/add/vehicle';;

  //   // this.ajaxService.ajaxPostWithString(url, addDetails)
  //   //   .subscribe(res => {
  //   //     //console.log(res);
  //   //     if (res === "persisted") {
  //   //       this.commonService.presentToast(' Vehicle added successfully!');

  //   //     } else {
  //   //       this.commonService.presentToast(' Vehicle not added, Please check the details ');
  //   //     }
  //   //   });

  // }


  ngAfterViewInit() {
    var element = Array.from(document.getElementsByClassName("jqx-widget"))
    element.forEach(data => {
      data['style'].borderRadius = "8px"
    })
  }

  getImei() {
    const url = serverUrl.web + '/VehicleController/UnUsedDevice';
    this.ajaxService.ajaxPostWithBody(url, { "companyID": localStorage.corpId })
      .subscribe(res => {
        this.imei = res;
        if (this.mode == "edit")
          this.imei.push({ imeiNo: this.selectedRow["IMEI Number"] })
      })
  }


  getOperator() {
    const url = serverUrl.web + '/VehicleController/UnUsedOperator';
    this.ajaxService.ajaxPostWithBody(url, { "companyID": localStorage.corpId, "branchID": localStorage.corpId })
      .subscribe(res => {
        if (this.mode == "edit")
          if (this.selectedRow["Operator Name"] != "")
            res.push({ name: this.selectedRow["Operator Name"], operatorID: this.selectedRow.OperatorId })
        this.operatorId = res;
        var source = {
          datatype: 'json',
          datafields: [{ name: 'name' }, { name: 'operatorID' }],
          id: 'id',
          localdata: this.operatorId
        }
        this.operatorGrid = new jqx.dataAdapter(source);
        if (this.mode == "edit" && this.entryPoint != "ATM")
          setTimeout(() => this.operatorComobox.selectItem(this.selectedRow["OperatorId"]), 500)

      })
  }

  getGeofence() {
    this.treeGeofenceObject = {}
    this.geofenceGridForm = [];
    let url = serverUrl.web + "/Alert/GeozoneDatas?data=" + localStorage.corpId;
    this.ajaxService.ajaxGetJson(url)
      .subscribe(res => {
        for (let i = 0; i < Object.keys(res).length; i++) {

          this.treeGeofenceObject[Object.keys(res)[i]] = {
            id: this.geoJsonId.toString(),
            parentid: "-1",
            text: Object.keys(res)[i],
            value: Object.keys(res)[i]
          }
          this.geofenceGridForm.push({
            id: this.geoJsonId.toString(),
            parentid: "-1",
            text: Object.keys(res)[i],
            value: Object.keys(res)[i]
          })
          let selectedGeoValue: any = Object.values(res[Object.keys(res)[i]]);
          let selectedGeoKeys: any = Object.keys(res[Object.keys(res)[i]]);
          let currentParentId = this.geoJsonId
          for (let j = 0; j < selectedGeoValue.length; j++) {
            var newId = ++this.geoJsonId
            selectedGeoValue[j]["type"] = Object.keys(res)[i]
            selectedGeoValue[j]["zoneLatLng"] = JSON.stringify(selectedGeoValue[j].latlng)
            this.treeGeofenceObject[selectedGeoKeys[j]] = {
              parentid: currentParentId,
              id: newId.toString(),
              text: selectedGeoValue[j].name,
              value: selectedGeoKeys[j],
              json: selectedGeoValue[j]
            }
            this.geofenceGridForm.push({
              parentid: currentParentId,
              id: newId.toString(),
              text: selectedGeoValue[j].name,
              value: selectedGeoKeys[j]
            })
          }
          this.geoJsonId++;
        }

        var source = {
          datatype: 'json',
          datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
          id: 'id',
          localdata: this.geofenceGridForm
        }

        this.geofenceGrid = new jqx.dataAdapter(source, { autoBind: true });
        this.geofence = this.geofenceGrid.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);

        if (this.mode == 'edit') {
          setTimeout(() => {
            if (this.selectedRow.geozones_id != "" && this.entryPoint != "ATM") {
              for (let i = 0; i < this.selectedRow.geozones_id.split(",").length; i++) {
                let selectRow = document.getElementById(this.treeGeofenceObject[this.selectedRow.geozones_id.split(",")[i] + "-geoFence"].id)
                this.geofenceTree.checkItem(selectRow, true)
              }
            }
            if (this.selectedRow.freeformid != "" && this.entryPoint != "ATM") {
              for (let i = 0; i < this.selectedRow.freeformid.split(",").length; i++) {
                let selectRow = document.getElementById(this.treeGeofenceObject[this.selectedRow.freeformid.split(",")[i] + "-freeForm"].id)
                this.geofenceTree.checkItem(selectRow, true)
              }
            }
            if (this.selectedRow.landmark != "" && this.entryPoint != "ATM") {
              for (let i = 0; i < this.selectedRow.landmark.split(",").length; i++) {
                let selectRow = document.getElementById(this.treeGeofenceObject[this.selectedRow.landmark.split(",")[i] + "-landMark"].id)
                this.geofenceTree.checkItem(selectRow, true)
              }
            }
            if (this.selectedRow.routeid != "" && this.entryPoint != "ATM") {
              for (let i = 0; i < this.selectedRow.routeid.split(",").length; i++) {
                let selectRow = document.getElementById(this.treeGeofenceObject[this.selectedRow.routeid.split(",")[i] + "-road"].id)
                this.geofenceTree.checkItem(selectRow, true)
              }
            }
            this.editedDataArray = [];
          }, 500)
        }
      })
  }

  selectAndCheck(event) {
    if (this.mode == "add")
      this.geofenceTree.checkItem(event.args.element, !this.geofenceTree.getItem(event.args.element).checked)
    this.selectGeofence(event);
  }

  selectGroup(event) {
    if (this.groupTreeStu.getItem(event.args.element)["level"] == 0) {
      this.groupTreeStu.selectItem(null);
      this.commonService.presentToast("Can't able to select the parent id")
    }
  }

  selectColor(event) {
    if (this.colorTreeStu.getItem(event.args.element)["level"] == 0) {
      this.colorTreeStu.selectItem(null);
      this.commonService.presentToast("Can't able to select the parent id")
    }
  }

  selectGeofence(event) {
    if (this.geofenceTree.getCheckedItems().length > 0) {
      this.mapService.clearLayers(this.map, [1, 2])
      var id = this.geofenceTree.getCheckedItems();
      for (let i = 0; i < id.length; i++) {
        var selectedRow = this.treeGeofenceObject[id[i].value].json
        if (selectedRow) {
          if (selectedRow.type == "Circle") {
            this.createCircle(selectedRow);
          } else if (selectedRow.type == "Road") {
            this.createRoadForm(selectedRow);
          } else if (selectedRow.type == "Freeform") {
            this.createFreeForm(selectedRow)
          } else if (selectedRow.type == "Landmark") {
            this.createLandMark(selectedRow)
          }
        }
      }
      this.mapService.fitBounds(this.map, 1)
      // this.mapService.fitBounds(this.map, 2)
    } else {
      if (this.map)
        this.mapService.clearLayers(this.map, [1, 2])
    }
  }

  createMap() {
    this.map = this.mapService.loadMap(this.mapElement.nativeElement, { lat: 24.440099, lng: 46.843498 }, false, false);
    this.mapService.setCenter(this.map, { lat: 24.440099, lng: 46.843498 });
  }

  createLandMark(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLng = { lat: parseFloat(data.split(",")[0]), lng: parseFloat(data.split(",")[1]) };
    this.mapService.addMarker(this.map, latLng, 'geoFence' + JSON.stringify(latLng), 'assets/vtstrackhisIcon/Idle.png');
  }

  createRoadForm(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLngData = []
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        'latitude': latlng.split(',')[0],
        'longitude': latlng.split(',')[1]
      });
    }
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  createFreeForm(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLngData = []
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        'latitude': latlng.split(',')[0],
        'longitude': latlng.split(',')[1]
      });
    }
    latLngData.push({
      'latitude': data[1].split(',')[0],
      'longitude': data[1].split(',')[1]
    })
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  createCircle(selectedRow) {
    let selectValue = 'Prefered';
    let latLng;
    let range;
    if (selectedRow.Area === 'Restricted') {
      selectValue = 'Restricted';
    }
    const detailsString = JSON.parse(selectedRow.zoneLatLng);
    const getLatLng = detailsString.split('|');
    const southwest = getLatLng[0].split(',');
    const northeast = getLatLng[1].split(',');
    const south = parseFloat(southwest[0].split(',')[0]);
    const west = parseFloat(southwest[1].split(',')[0]);
    const north = parseFloat(northeast[0].split(',')[0]);
    const east = parseFloat(northeast[1].split(',')[0]);
    latLng = { lat: south, lng: west };
    range = this.calculateRadius(north, east, south, west);
    this.mapService.addMarker(this.map, latLng, 'geoFence' + JSON.stringify(latLng), 'assets/vtstrackhisIcon/Idle.png');
    this.mapService.createCircle(this.map, latLng, range, selectValue);
  }

  calculateRadius = function (south, west, north, east) {
    const R = 6378.137; // Radius of earth in KM
    const dLat = (north - south) * Math.PI / 180;
    const dLon = (east - west) * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos(south * Math.PI / 180)
      * Math.cos(north * Math.PI / 180)
      * Math.sin(dLon / 2)
      * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math
      .sqrt(1 - a));
    const d = R * c;
    return Math.round(d * 1000);
  };
  check(control: AbstractControl, gyuih, dffa) {
    let parent: FormGroup = control['_parent'];
    let obj = { ...parent }
    // let control2:AbstractControl=parent['controls']['vehicleManufacture'];
    // console.log(control, 'control', parent, 'parent', obj['value']);
    if (control.value) {
      return true;
    } else return null;


  }

  formInitializer() {
    if (this.mode == "add") {
      this.fleetLogin = this.formBuilder.group({
        plateNo: ['', Validators.required],
        vehicleName: [""],
        imei: ['', Validators.required],
        multiplateNo: [],
        fleetManager: ['', Validators.required],
        vehicleType: ['', Validators.required],
        vehicleManufacture: [''],
        vehicleModel: [''],
        operator: [''],
        vehicleHeight: [""],
        vehicleBranch: [""],
        workingStatus: [""],
        vehicleRegNo: [""],
        fuelCapacity: [""],
        kmPerLiter: [""],
        odometerValue: [""],
        vehicleFuelType: [""],
        pricePerLiter: [""],
        addGroupInTree: [""],
        assetType: [""],
        assetModel: [""],
        assetYear: [""],
        assetColor: [""],
        assetMove: [""],
        analogMinActVal: [""],
        analogMaxActVal: [""],
        analogLiter: [""],
        analogMv: [""],
        digitalInput2CheckBox: [this.checkBoxIODetails.digitalInput2,],
        digitalInput3CheckBox: [this.checkBoxIODetails.digitalInput3],
        digitalInput4CheckBox: [this.checkBoxIODetails.digitalInput4],
        digitalOutput1CheckBox: [this.checkBoxIODetails.digitalOutput1],
        digitalOutput2CheckBox: [this.checkBoxIODetails.digitalOutput2],
        digitalOutput3CheckBox: [this.checkBoxIODetails.digitalOutput3],
        digitalOutput4CheckBox: [this.checkBoxIODetails.digitalOutput4],
        Debugmode: [""],
        preventiveDue: ['250', Validators.required],
        gensetCapacityValue: [""],
        gensetCapacityUnit: [""],
        dueLimits: ['50', Validators.required],
        loadSensor: [""],
        fuelTankCapacity: [""]

      });


    } else if (this.mode == "edit") {
      console.log(this.selectedRow)

      this.fleetLogin = this.formBuilder.group({
        plateNo: [this.selectedRow["Plate Number"], Validators.required],
        vehicleName: [this.selectedRow['vName']],
        imei: [this.selectedRow["IMEI Number"], Validators.required],
        fleetManager: [''],//
        vehicleType: ['', Validators.required],//
        vehicleManufacture: [''],//patch
        vehicleModel: [''],//
        operator: [''],
        vehicleHeight: [this.selectedRow.height],
        vehicleBranch: [this.selectedRow.branch],
        workingStatus: [this.selectedRow.workingStatus],
        vehicleRegNo: [this.selectedRow.regNo],
        fuelCapacity: [this.selectedRow.fuelTanklit],
        kmPerLiter: [(this.selectedRow.mileageInit.split("-")[0] == "true") ? "" : this.selectedRow.mileageInit.split("-")[0]],
        odometerValue: [this.selectedRow.Odometer],
        vehicleFuelType: [this.selectedRow.mileageInit.split("-")[1]],
        pricePerLiter: [this.selectedRow.mileageInit.split("-")[2]],
        addGroupInTree: [""],
        assetType: [""],
        assetModel: [""],
        assetYear: [this.selectedRow['year']],
        assetColor: [""],
        assetMove: [""],
        analogMinActVal: [""],
        analogMaxActVal: [""],
        analogLiter: [""],
        analogMv: [""],
        digitalInput2CheckBox: [this.checkBoxIODetails.digitalInput2, this.check],
        digitalInput3CheckBox: [this.checkBoxIODetails.digitalInput3],
        digitalInput4CheckBox: [this.checkBoxIODetails.digitalInput4],
        digitalOutput1CheckBox: [this.checkBoxIODetails.digitalOutput1],
        digitalOutput2CheckBox: [this.checkBoxIODetails.digitalOutput2],
        digitalOutput3CheckBox: [this.checkBoxIODetails.digitalOutput3],
        digitalOutput4CheckBox: [this.checkBoxIODetails.digitalOutput4],
        Debugmode: [''],
        preventiveDue: this.selectedRow.PreventiveMaintenance.split(",")[0] / 60,
        gensetCapacityValue: this.selectedRow.genCapacity != undefined ? this.selectedRow.genCapacity.split(" ")[0] : undefined,
        gensetCapacityUnit: this.selectedRow.genCapacity != undefined ? this.selectedRow.genCapacity.split(" ")[1] : undefined,
        dueLimits: this.selectedRow.PreventiveMaintenance.split(",")[1] == undefined ? "50" : this.selectedRow.PreventiveMaintenance.split(",")[1] / 60,
        loadSensor: [(this.selectedRow.loadSensorImplements) ? this.selectedRow.loadSensorImplements : ""],
        fuelTankCapacity: [this.selectedRow.fuelTankCapacity ? this.selectedRow.fuelTankCapacity : ""],
      });
      this.entryPoint = localStorage.getItem("entryPoint")
      if (this.entryPoint != "ATM") {
        if (this.operatorComobox)
          this.operatorComobox.checkItem(this.selectedRow["Operator Name"])
        else {
          setTimeout(res => {
            console.log(this.selectedRow["iconUrl"], "ggdf");

            this.vehicleTypeComboBox.selectItem(this.selectedRow["iconUrl"]);
            if (this.selectedRow.genCapacity.split(" ")[1])
              this.gensetCapacityUnitComboBox.selectItem(this.selectedRow.genCapacity.split(" ")[1])
            if (this.selectedRow.mileageInit.split("-")[1] && this.entryPoint != "ATM")
              this.vehicleFuelTypeComboBox.selectItem(this.selectedRow.mileageInit.split("-")[1].charAt(0).toUpperCase() + this.selectedRow.mileageInit.split("-")[1].slice(1))
          }, 500)
        }
      }

    } else if (this.mode == "multiEdit") {
      this.visiblity = ["multiplateNo", "fleetManager", "category", "model", "type", "group", "geofence", "height", "fuelCap", "kmLit", "fuelType", "cost", "ioDetails", "gensetCapacityValue", "gensetCapacityUnit", "preventiveDue", "dueLimits", "loadSensor"]

      this.fleetLogin = this.formBuilder.group({
        fleetManager: ['', Validators.required],
        vehicleType: ['', Validators.required],
        vehicleManufacture: [''],
        vehicleModel: [''],
        operator: [''],
        vehicleHeight: [""],
        fuelCapacity: [""],
        kmPerLiter: [""],
        odometerValue: [""],
        vehicleFuelType: [""],
        pricePerLiter: [""],
        addGroupInTree: [""],
        assetType: [""],
        assetModel: [""],
        assetYear: [""],
        assetColor: [""],
        assetMove: [""],
        analogMinActVal: [""],
        analogMaxActVal: [""],
        analogLiter: [""],
        analogMv: [""],
        digitalInput2CheckBox: [this.checkBoxIODetails.digitalInput2],
        digitalInput3CheckBox: [this.checkBoxIODetails.digitalInput3],
        digitalInput4CheckBox: [this.checkBoxIODetails.digitalInput4],
        digitalOutput1CheckBox: [],
        digitalOutput2CheckBox: [],
        digitalOutput3CheckBox: [],
        digitalOutput4CheckBox: [],
        Debugmode: [""],
        preventiveDue: [""],
        gensetCapacityValue: [""],
        gensetCapacityUnit: [""],
        dueLimits: [""],
        fuelTankCapacity: [""],
        vehicleName: [""],
      });
    }
  }
  // getImeiDetails(imei) {
  //   for (let i = 0; i < this.imei.length; i++) {
  //     if (imei == this.imei[i].imeiNo) {
  //       this.fleetLogin.patchValue({
  //         manufacture: this.imei[i].manufacturerName,
  //         model: this.imei[i].modelName,
  //         provider: this.imei[i].provider
  //       });
  //     }
  //   }
  // }

  // availableImei() {
  //   if ((this.fleetLogin.value.imei).toString().length >= 15) {
  //     const data = { "imeiNo": this.fleetLogin.value.imei.toString() }
  //     var url = serverUrl.web + '/device/validate/imei'
  //     this.ajaxService.ajaxPostWithString(url, data)
  //       .subscribe(async res => {
  //         res = JSON.parse(res)
  //         if (res.message == "Error") {
  //           this.commonService.presentToast("Your request wasn't solved, Kindly contact technical team");
  //         } else if (res.message == "Imei Already Available") {
  //           this.getImeiDetails(this.fleetLogin.value.imei);
  //         } else if (res.message == "Imei Not Available") {
  //           this.alertController.create({
  //             header: 'Imei not available!',
  //             message: 'Given imei number is not available! Can i go to device add page to add device imei?',
  //             buttons: [
  //               {
  //                 text: 'Cancel',
  //                 handler: () => {
  //                 }
  //               },
  //               {
  //                 text: 'Ok',
  //                 handler: async () => {
  //                   this.openDevice();
  //                 }
  //               }
  //             ]
  //           }).then(res => {
  //             res.present();
  //           });



  //         } if (res.message == 'Imei Available') {
  //           this.fleetLogin.patchValue({
  //             serialNo: res.simcardNo,
  //             manufacture: res.deviceManufacturer,
  //             model: res.deviceModel,
  //             provider: res.provider
  //           });
  //         }
  //       })
  //   }
  // }

  async openOperator() {
    const modal = await this.modalController.create({
      component: OperatorformComponent,
      cssClass: 'custom-modal'
    });
    modal.onDidDismiss().then(() => {
      this.getOperator();
    })
    return await modal.present();
  }

  async openDevice() {
    const model = await this.modalController.create({
      component: SimDeviceModalComponent,
      componentProps: {
        page: "Device",
        mode: "Add"
      }
    })
    model.onDidDismiss().then(() => {
      this.getImei();
    })
    await model.present();
  }

  getVehicleAssociatedFleetManager() {
    const url = serverUrl.web + "/user/vehicleAssociatedFleetmanagers?companyId=" + localStorage.corpId + "&branchId=" + localStorage.corpId
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.vehicleAssFleets = res;
      }
      )
  }

  companyGroup() {
    let allGroups = storageVariable.groupAndVin
    var liveDatas = [];
    allGroups.forEach(el => {
      liveDatas.push(el.Group)
    });
    let groupArrayPush = []
    for (let i = 0; i < liveDatas.length; i++) {
      if (liveDatas[i] != null)
        groupArrayPush.push(liveDatas[i])
    }
    var res: any = groupArrayPush;
    this.groupTreeElement = res
    this.groupTreeElement.splice(this.groupTreeElement.indexOf(null), 1);
    var objectFormation = {}
    if (res.length > 0) {
      let treeHead = [];
      treeHead.push({
        id: 0,
        parentid: "-1",
        text: localStorage.corpId,
        value: localStorage.corpId
      })
      this.countGroupData = 1;
      for (let i = 0; i < res.length; i++) {
        if (res[i] == null)
          continue;
        var createNewGrp = ""
        for (let j = 0; j < res[i].split(",").length; j++) {
          createNewGrp += (j == 0 ? "" : ",") + res[i].split(",")[j]

          if (objectFormation[createNewGrp])
            continue;
          objectFormation[createNewGrp] = this.countGroupData
          this.countGroupData++;
        }

        // if (res[i].split(",").length > 1) {
        //   var createNewGrp = ""
        //   for (let j = 0; j < res[i].split(",").length; j++) {
        //     createNewGrp += res[i].split(",")[j]
        //     // treeHead.push(
        //     //   {
        //     //     id: res[i].split(",")[j],
        //     //     parentid: j == 0 ? localStorage.corpId : res[i].split(",")[j],
        //     //     text: res[i].split(",")[j],
        //     //     value: createNewGrp + (j == 0 && j == res[i].split(",").length - 1 ? "" : ",")
        //     //   }
        //     // )
        //     if (!objectFormation[res[i].split(",")[j]])
        //       objectFormation[res[i].split(",")[j]] = {
        //         id: res[i].split(",")[j],
        //         parentid: j == 0 ? localStorage.corpId : res[i].split(",")[j - 1],
        //         text: res[i].split(",")[j],
        //         value: createNewGrp
        //       }
        //       if(res[i].split(",").length - 1 != j)
        //       createNewGrp += ","
        //   }

        // } else {
        //   // treeHead.push(
        //   //   {
        //   //     id: res[i],
        //   //     parentid: localStorage.corpId,
        //   //     text: res[i],
        //   //     value: res[i]
        //   //   }
        //   // )
        //   if (!objectFormation[res[i]])
        //     objectFormation[res[i]] = {
        //       id: res[i],
        //       parentid: localStorage.corpId,
        //       text: res[i],
        //       value: res[i]
        //     }
        // }
      }
      for (let j = 0; j < Object.keys(objectFormation).length; j++) {
        var parent = 0
        if (Object.keys(objectFormation)[j].split(",").length > 1) {
          var poped = Object.keys(objectFormation)[j].split(",")
          poped.pop();
          parent = objectFormation[poped.toString()]
        }
        treeHead.push(
          {
            id: objectFormation[Object.keys(objectFormation)[j]],
            parentid: parent,
            text: Object.keys(objectFormation)[j].split(',').pop(),
            value: Object.keys(objectFormation)[j]
          }
        )
      }
      // treeHead = [...Object.values(objectFormation), ...treeHead]
      var source = {
        datatype: 'json',
        datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
        id: 'id',
        localdata: treeHead
      }

      this.groupGrid = new jqx.dataAdapter(source, { autoBind: true });
      this.groupTree = this.groupGrid.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);

      // setTimeout(() => {
      //   this.groupTreeStu.expandAll();
      //   // this.selectedRow["Group"].split(',')
      //   if (this.mode == "edit")
      //     this.groupTreeStu.selectItem(document.getElementById(objectFormation[this.selectedRow["Group"]]))
      // }
      //   , 500)
      setTimeout(() => {
        this.groupTreeStu.expandAll();
        if (this.mode == "edit") {
          let afterSplit
          let id = [];
          for (let x in objectFormation) {
            afterSplit = (x.split(",").length == 1) ? x : x.split(",").pop()
            if (afterSplit == this.selectedRow["Group"]) {
              if (id.length == 0) {
                id = objectFormation[x]
              }
            }
          }
          this.groupTreeStu.selectItem(document.getElementById(id.toString()))

        }
      }
        , 500)
    } else {
      var treeHead = [];
      treeHead.push({
        id: localStorage.corpId,
        parentid: "-1",
        text: localStorage.corpId,
        value: localStorage.corpId
      })

      treeHead.push(
        {
          id: "NoneGroup",
          parentid: localStorage.corpId,
          text: "NoneGroup",
          value: "NoneGroup"
        }
      )
      var source = {
        datatype: 'json',
        datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
        id: 'id',
        localdata: treeHead
      }

      this.groupGrid = new jqx.dataAdapter(source, { autoBind: true });
      this.groupTree = this.groupGrid.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
      setTimeout(() => {
        this.groupTreeStu.expandAll();
        if (this.mode == "edit")
          this.groupTreeStu.selectItem(document.getElementById(this.selectedRow["Group"]))
      }
        , 500)
    }
    // })


  }

  addGroup() {
    if (this.fleetLogin.value.addGroupInTree == "") {
      this.commonService.presentToast("Please enter new group name")
      return null;
    }

    let oldArray = [];
    oldArray = this.groupGrid.loadedData
    oldArray.unshift(
      {
        id: this.countGroupData,
        parentid: this.groupTreeStu.getSelectedItem() == null ? 0 : this.groupTreeStu.getSelectedItem()['id'],
        text: this.fleetLogin.value.addGroupInTree.trim(),
        value: (this.groupTreeStu.getSelectedItem() ? this.groupTreeStu.getSelectedItem().value + "," : "") + this.fleetLogin.value.addGroupInTree.trim()
      })
    this.countGroupData++;
    var source = {
      datatype: 'json',
      datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
      id: 'id',
      localdata: oldArray
    }

    this.groupGrid = new jqx.dataAdapter(source, { autoBind: true });
    this.groupTree = this.groupGrid.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
    setTimeout(() => {
      this.groupTreeStu.selectItem(document.getElementById(this.fleetLogin.value.addGroupInTree));
      this.geoFenceTreeSize = 122;
      this.fleetLogin.value.addGroupInTree = "";
    }, 500)
  }

  colorYearSeperation() {
    var colorArray = [], yearArray = [];
    var colorYearArray = storageVariable.groupAndVin
    colorYearArray.forEach(value => {
      if (value.color) {
        colorArray.push(value.color);
        this.groupColor = [...new Set(colorArray)]
      }

      if (value.year) {
        yearArray.push(value.year)
        this.groupYear = [...new Set(yearArray)]
      }
    })


  }
  createColor() {
    let treeHead = [];
    treeHead.push({
      id: "Color",
      parentid: "-1",
      text: "Color",
      value: "Color"
    })
    for (let i = 0; i < this.groupColor.length; i++) {
      treeHead.push(
        {
          id: this.groupColor[i],
          parentid: "Color",
          text: this.groupColor[i],
          value: this.groupColor[i]
        }
      )
    }
    var source = {
      datatype: 'json',
      datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
      id: 'id',
      localdata: treeHead
    }

    this.colorGrid = new jqx.dataAdapter(source, { autoBind: true });
    this.colorTree = this.colorGrid.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
    if (this.entryPoint != "ATM")
      setTimeout(() => {
        this.colorTreeStu.expandAll();
        if (this.mode == "edit")
          this.colorTreeStu.selectItem(document.getElementById(this.selectedRow["group_Color"]))
      }
        , 500)
  }

  openGroup() {
    this.geoFenceTreeSize = this.geoFenceTreeSize == 122 ? 75 : 122;
  }

  getAnalogValue() {
    const body = {
      companyID: localStorage.corpId,
      branchID: localStorage.corpId,
    }
    const url = serverUrl.web + "/VehicleController/AnalogInfo"

    this.ajaxService.ajaxPostMethod(url, JSON.stringify(body))
      .subscribe(res => {
        this.analogValues = res
      })
  }

  selectMultipleChange(event) {
    console.log("select");
    
    if (event.args.item.originalItem["Plate Number"] == "Select all" && event.args.item.checked == false) {
      this.multiplateNoComobox.attrSource.map(res => this.multiplateNoComobox.selectItem(res["Vehicle Number"]))
    }


    this.selectedRow = []
    let data = this.multiplateNoComobox.getSelectedItems()
    data.map(res => {
      if (res.originalItem["Plate Number"] != "Select all")
        this.selectedRow.push(res.originalItem)
    })
  }
  Unselect(event) {
    console.log(event.args,"clear");
    
    if (event.args.item.value == "Select all"){
      this.multiplateNoComobox.clearSelection();
      console.log("all plate no removed");
    }
    else{
    this.multiplateNoComobox.unselectItem(event.args.item.value);
    console.log("plate NO removed:",event.args.item.label);
    }

    

  }

  getInputDetails() {
    let url = serverUrl.web + "/sensor/Generalparam/Analog";
    this.ajaxService.ajaxGetJson(url)
      .subscribe(res => {
        this.digitalInputResponceData = res;
        if (this.mode == "edit") {
          url = serverUrl.web + '/sensor/fuelconfig/{ "Vin": ' + this.selectedRow["Vehicle Number"] + ', "Status": "9-Analog input 1,10-Analog input 2,11-Analog input 3,19-Analog input 4" }'
          this.ajaxService.ajaxGet(url)
            .subscribe(responce => {
              this.analogAssociatedVin = responce;
              setTimeout(() => {
                if (this.selectedRow.reverseSetting != "0|0|0") {
                  // let singleIndex = this.selectedRow.reverseSetting.split("|").indexOf("1");
                  let singleIndex = this.selectedRow.reverseSetting.split("|");
                  // console.log(singleIndex.length, singleIndex, "single index");
                  let selectedValue = Object.keys(this.checkBoxIODetails)[singleIndex]
                  // console.log(selectedValue, "selectedValue");
                  // this.checkBoxIODetails[selectedValue] = true
                  this.fleetLogin.patchValue({
                    digitalInput2CheckBox: this.checkBoxIODetails.digitalInput2 = singleIndex[0] === '1' ? true : false,
                    digitalInput3CheckBox: this.checkBoxIODetails.digitalInput3 = singleIndex[1] === '1' ? true : false,
                    digitalInput4CheckBox: this.checkBoxIODetails.digitalInput4 = singleIndex[2] === '1' ? true : false,
                    digitalOutput1CheckBox: this.checkBoxIODetails.digitalOutput1 = singleIndex[3] === '1' ? true : false,
                    digitalOutput2CheckBox: this.checkBoxIODetails.digitalOutput2 = singleIndex[4] === '1' ? true : false,
                    digitalOutput3CheckBox: this.checkBoxIODetails.digitalOutput3 = singleIndex[5] === '1' ? true : false,
                    digitalOutput4CheckBox: this.checkBoxIODetails.digitalOutput4 = singleIndex[6] === "1" ? true : false,


                  });

                }

                if (this.selectedRow.opreAre != "|||") {
                  this.digitalOutput1Combobox.selectItem(this.selectedRow.opreAre.split("|")[0].replace("|", ""))
                  this.digitalOutput2Combobox.selectItem(this.selectedRow.opreAre.split("|")[1].replace("|", ""))
                  this.digitalOutput3Combobox.selectItem(this.selectedRow.opreAre.split("|")[2].replace("|", ""))
                  this.digitalOutput4Combobox.selectItem(this.selectedRow.opreAre.split("|")[3].replace("|", ""))
                }
                if (this.selectedRow.ioDetails) {
                  let spliter = this.selectedRow.ioDetails.split(",");
                  let analogJson = [];
                  let checkBox: any = document.getElementsByName("digitalInputData")
                  for (let i = 0; i < spliter.length; i++) {
                    if (spliter[i].split("|")[0] == "1") {

                      this.digitalInput1Combobox.selectItem(spliter[i].split("|")[1])
                      if (spliter[i].split("|")[5] != "null") {
                        checkBox[0].checked = true
                      }
                    }
                    if (spliter[i].split("|")[0] == "2") {
                      this.digitalInput2Combobox.selectItem(spliter[i].split("|")[1])
                      if (spliter[i].split("|")[5] != "null")
                        checkBox[1].checked = true
                    }
                    if (spliter[i].split("|")[0] == "3") {
                      this.digitalInput3Combobox.selectItem(spliter[i].split("|")[1])
                      if (spliter[i].split("|")[5] != "null")
                        checkBox[2].checked = true
                    }
                    if (spliter[i].split("|")[0] == "4") {
                      this.digitalInput4Combobox.selectItem(spliter[i].split("|")[1])
                      if (spliter[i].split("|")[5] != "null")
                        checkBox[3].checked = true
                    }
                    if (spliter[i].split("|")[0] == "9") {
                      this.analogGridTable = true;
                      for (let j = 0; j < this.analogAssociatedVin["Analog input 1"]["millivolts"].length; j++) {
                        analogJson.push({
                          analog: "Analog input 1", type: spliter[i].split("|")[1], input: spliter[i].split("|")[4],
                          min: spliter[i].split("|")[2], max: spliter[i].split("|")[3], liter: parseInt(this.analogAssociatedVin["Analog input 1"]["litres"][j]).toString(),
                          mv: this.analogAssociatedVin["Analog input 1"]["millivolts"][j]
                        })
                      };
                    }
                    if (spliter[i].split("|")[0] == "10") {
                      this.analogGridTable = true;
                      for (let j = 0; i < this.analogAssociatedVin["Analog input 2"]["millivolts"].length; j++) {
                        analogJson.push({
                          analog: "Analog input 2", type: spliter[i].split("|")[1], input: spliter[i].split("|")[4],
                          min: spliter[i].split("|")[2], max: spliter[i].split("|")[3], liter: parseInt(this.analogAssociatedVin["Analog input 2"]["litres"][j]).toString(),
                          mv: this.analogAssociatedVin["Analog input 2"]["millivolts"][j]
                        })
                      };
                    }
                    if (spliter[i].split("|")[0] == "11") {
                      this.analogGridTable = true;
                      for (let j = 0; j < this.analogAssociatedVin["Analog input 3"]["millivolts"].length; j++) {
                        analogJson.push({
                          analog: "Analog input 3", type: spliter[i].split("|")[1], input: spliter[i].split("|")[4],
                          min: spliter[i].split("|")[2], max: spliter[i].split("|")[3], liter: parseInt(this.analogAssociatedVin["Analog input 3"]["litres"][j]).toString(),
                          mv: this.analogAssociatedVin["Analog input 3"]["millivolts"][j]
                        })
                      }
                    }
                    if (spliter[i].split("|")[0] == "19") {
                      this.analogGridTable = true;
                      for (let j = 0; j < this.analogAssociatedVin["Analog input 4"]["millivolts"].length; j++) {

                        analogJson.push({
                          analog: "Analog input 4", type: spliter[i].split("|")[1], input: spliter[i].split("|")[4],
                          min: spliter[i].split("|")[2], max: spliter[i].split("|")[3], liter: parseInt(this.analogAssociatedVin["Analog input 4"]["litres"][j]).toString(),
                          mv: this.analogAssociatedVin["Analog input 4"]["millivolts"][j]
                        })
                      }
                    }
                  }
                  this.analogTableArrayWholeValue = analogJson;
                  this.renderer = (row: number, column: any, value: string,) => {
                    if (value == "" || null || undefined) {
                      return "----"
                    }
                    else {
                      return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
                    }
                  }
                  this.source = { localdata: this.analogTableArrayWholeValue };
                  this.analogTableValue = new jqx.dataAdapter(this.source);
                  this.columns = [
                    { text: 'Analog', datafield: 'analog', cellsrenderer: this.renderer },
                    { text: 'Type', datafield: 'type', cellsrenderer: this.renderer },
                    { text: 'Input', datafield: 'input', cellsrenderer: this.renderer },
                    { text: 'Min', datafield: 'min', cellsrenderer: this.renderer },
                    { text: 'Max', datafield: 'max', cellsrenderer: this.renderer },
                    { text: 'Liter', datafield: 'liter', cellsrenderer: this.renderer },
                    { text: 'Mv', datafield: 'mv', cellsrenderer: this.renderer },
                    {
                      text: 'Delete', datafield: 'Delete', columntype: 'button',
                      cellsrenderer: (): string => {
                        console.log('iam button');
                        return 'Delete';
                      },
                      buttonclick: (row): void => {
                        this.deleteAnalogRow(row);
                        this.editSaveData('ioDetails')
                      }
                    }
                  ]
                  //this.commonService.dismissLoader();
                  // this.analogGrid.unselectrow();
                }
                this.editedDataArray = [];
              }, 800)
              if (this.mode == 'add')
                this.commonService.dismissLoader();
            });
        }

      });
  }

  analogDisableToEnable(event) {
    this.fleetLogin.patchValue({
      analogLiter: "",
      analogMv: "",
      analogMinActVal: "",
      analogMaxActVal: ""
    });
    this.fleetLogin.controls['analogMinActVal'].enable();
    this.fleetLogin.controls['analogMaxActVal'].enable();
    this.isDisabledAnalog = false;
    this.analogType.unselectItem(this.analogType.getSelectedItem().value);
    this.analogCategInput.unselectItem(this.analogCategInput.getSelectedItem().value);
  }

  analogTableAdd() {
    this.editSaveData('ioDetails')
    this.analogGridTable = true;
    this.isDisabledAnalog = true;
    let detailValue = {
      analog: this.analogCategories.getSelectedItem().value,
      type: this.analogType.getSelectedItem().value,
      input: this.analogCategInput.getSelectedItem().value,
      min: this.fleetLogin.controls.analogMinActVal.value,
      max: this.fleetLogin.controls.analogMaxActVal.value,
      liter: this.fleetLogin.value.analogLiter,
      mv: this.fleetLogin.value.analogMv
    }
    this.fleetLogin.patchValue({
      analogLiter: "",
      analogMv: ""
    });
    this.fleetLogin.controls['analogMinActVal'].disable();
    this.fleetLogin.controls['analogMaxActVal'].disable();

    this.analogTableArrayWholeValue.push(detailValue)
    this.renderer = (row: number, column: any, value: string,) => {
      if (value == "" || null || undefined) {
        return "----"
      }
      else {
        return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
      }
    }
    this.source = { localdata: this.analogTableArrayWholeValue };
    this.analogTableValue = new jqx.dataAdapter(this.source);
    this.columns = [
      { text: 'Analog', datafield: 'analog', cellsrenderer: this.renderer },
      { text: 'Type', datafield: 'type', cellsrenderer: this.renderer },
      { text: 'Input', datafield: 'input', cellsrenderer: this.renderer },
      { text: 'Min', datafield: 'min', cellsrenderer: this.renderer },
      { text: 'Max', datafield: 'max', cellsrenderer: this.renderer },
      { text: 'Liter', datafield: 'liter', cellsrenderer: this.renderer },
      { text: 'Mv', datafield: 'mv', cellsrenderer: this.renderer },
      {
        text: 'Delete', datafield: 'Delete', columntype: 'button',
        cellsrenderer: (): string => {
          console.log('iam button');
          return 'Delete';
        },
        buttonclick: (row): void => {
          this.deleteAnalogRow(row);
        }
      }
    ]
    this.analogGrid.unselectrow();
  }

  deleteAnalogRow(row) {
    this.analogTableArrayWholeValue.splice(row, 1)
    this.source = { localdata: this.analogTableArrayWholeValue };
    this.analogTableValue = new jqx.dataAdapter(this.source);
  }

  editSaveData(data) {
    this.editedDataArray = this.commonService.userAdminEditArray(this.mode, data, this.editedDataArray);
  }
  vehicleyear() {
    const url = serverUrl.web + '/login/getPreferences?key=vehicleYears&companyId=' + localStorage.corpId;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        const yearRange = res.year.split('-');
        const firstFourDigits = parseInt(yearRange[0]);
        const secondFourDigits = parseInt(yearRange[1]);
        let year_ = []
        for (let year = firstFourDigits; year <= secondFourDigits; year++) {
          year_.push(year);
        }
        this.yearshow = year_
      });
  }

  ngOnInit() {
    this.vehicleyear();
    this.colorYearSeperation()
    if (this.mode == "edit")
      this.commonService.presentLoader()
    if (this.mode == "multiEdit") {
      this.multiplateNos = this.overallData;
      this.multiplateNos.unshift({ "Vehicle Number": "Select all", "Plate Number": "Select all", Group: "Select all" })
    }
    this.submitbtn=false;
    this.callsign = localStorage.callsign
    this.getImei();
    this.getOperator();
    this.formInitializer();
    this.getVehicleAssociatedFleetManager();
    this.getFleetManager();
    this.getAnalogValue();
    this.getInputDetails();

    if (localStorage.companyRole == "CompanyAdmin")
      this.debugmode = true

    setTimeout(() => {
      this.getGeofence();
      this.companyGroup();
      if (this.entryPoint != "ATM")
        this.createMap();
      this.createColor();
    }, 500);
    if (this.mode != "add")
      setTimeout(() => {
        this.editedDataArray = [];

      }, 1000);
    if (this.mode != "add")
      setTimeout(() => {
        console.log(this.editedDataArray)
        if (this.commonService.isLoading)
          this.commonService.dismissLoader()
      }, 5000);
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
    this.entryPoint = localStorage.getItem("entryPoint")
    this.atmIndex = this.assetCategory.indexOf("ATM");


  }

  addExtraInformation(data) {
    if (data == 'type') {
      this.type = !this.type;
    }
    if (data == 'color') {
      this.geoFenceColorSize = this.geoFenceColorSize == 122 ? 75 : 122;
    }
    if (data == 'model') {
      this.model = !this.model;
    }

  }
  addNewdataOnassetType(data) {
    if (data == 'color') {
      if (this.fleetLogin.value.assetColor != null) {
        this.groupColor.unshift(this.fleetLogin.value.assetColor.trim());
        setTimeout(() => {
          this.fleetLogin.get('assetColor').reset()
          this.geoFenceColorSize = 122;
          this.createColor()
        }, 500)

      }
    }
    if (data == 'model') {
      if (this.assetMove && this.assetNewAdd) {
        var Arraycheck = this.assetNewAdd.toUpperCase();
        if (this.assetModel.includes(Arraycheck)) {
          this.commonService.presentToast("This model is Already Added!");
          return null;
        }
        this.vehicleModelComboBox.insertAt(this.assetNewAdd.trim(), 0);
        setTimeout(() => {
          this.model = false;
          this.vehicleModelComboBox.selectItem(this.assetNewAdd);
        }, 500)
      }
      else {
        this.commonService.presentToast("Please fill the valid details to add!")
        return null;
      }
    }
    if (data = 'type') {
      if (this.fleetLogin.value.assetType.length !== 0) {
        this.vehicleManufactureComboBox.insertAt(this.fleetLogin.value.assetType.trim(), 0);
        setTimeout(() => {
          this.vehicleManufactureComboBox.selectIndex(0)
          this.fleetLogin.get('assetType').reset()
          this.type = false;
        }, 500)
      }
    }

  }

  validateNo(e: any): boolean {
    return this.commonService.validateNo(e);
  }
}
