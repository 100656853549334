import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { app, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { CommonService } from '../services/common.service';
import { WebsocketService } from '../services/websocket.service';
import { IonContent, ModalController, Platform } from '@ionic/angular';
import { VtslivetrackComponent } from '../livetrack/vtslivetrack/vtslivetrack.component';
import { VehicleOverviewComponent } from '../mapview-tab/vehicle-overview/vehicle-overview.component';
import { VtsTrackhistoryComponent } from '../trackhistory/vts-trackhistory/vts-trackhistory.component';
import { ModalPage } from '../gridview-tab/vtsgrid/modal/modal.page';
import { AjaxService } from '../services/ajax.service';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multitracking',
  templateUrl: './multitracking.component.html',
  styleUrls: ['./multitracking.component.scss'],
})
export class MultitrackingComponent implements OnInit {
  @ViewChildren('vtsLiveTrackList') vtsLiveTrackList: QueryList<VtslivetrackComponent>;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('myTree', { static: false }) myTree: jqxTreeComponent;
  multiTrackObj = {}
  carddata;
  dashboardData;
  count: number = 15;
  entryPoint: string;
  selectedVin: any;
  pageSeleter: string;
  liveTrackingChanges: any;
  updatedJsonData: any;
  alertData: any;
  alertCount: any;
  myPlatform: string;
  gridCardOption: any;
  liveDatas: any;
  showSkeletonLoader: boolean;
  headerValue: any;
  vehicleCount: any;
  statusBarOthers: string[];
  cacheAddress: any;
  filterValue;
  manualFilter;
  statusGrid: any[];
  searchInput: string = "";
  plateNumbers: string[] = [];
  checkboxStates: { [plateNo: string]: boolean } = {};
  vins: string[] = [];
  livetrack: boolean = false;
  liveChange: any;
  selectedData: any[] = [];
  multitrack: {} = {};
  disabled: string[] = [];
  multiTrackingArray: any[] = [];
  eventtriger: boolean = false;
  directory = languageInitializer.directory;
  alertDataLength: any = 0;
  renderedCards: boolean[] = [];
  maxIndex: number;
  shouldRender: boolean;
  dashboardJson = [];
  filterData: any = {};
  treeStructureObject: any = [];
  treeStructureJson: any = [];
  dataAdapter: any;
  storageVar = storageVariable.upDatedJsonData
  source = {
    datatype: 'json',
    datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }, { name: 'disabled' }],
    id: 'id',
    localdata: this.treeStructureJson
  }; records: any;
  search: any;
  showFilterView: boolean = false;
  all: any;
  checkitem: boolean = false;

  constructor(public websocketService: WebsocketService,
    public commonService: CommonService,
    public modalController: ModalController,
    public ajaxService: AjaxService,
    public trans: TranslateService
  ) { }
  loadMoreData = (event) => {
    setTimeout(() => {
      this.count += 15;
      event.target.complete();
      this.pipeFilter(this.filterValue, this.count, this.statusGrid, this.manualFilter, this.searchInput)
      if (JSON.parse(localStorage.gridData).length > localStorage.maxLength) {
        event.target.disabled = true;
        setTimeout(() => {
          event.target.disabled = false;
        }, 2000);
      }
    }, 500);
  }

  ionViewWillEnter() {
    if (this.plateNumbers.length != 0) {
      this.plateNumbers.forEach(plateNo => {
        this.checkboxStates[plateNo] = false;
      });
    }
    this.multiTrackObj = {};
    this.multitrack = {};
    this.plateNumbers = [];
    this.vins = [];
    this.websocketService.setProductService(this);
    this.websocketService.reSendRequest(JSON.parse(localStorage.dashboardWebSocketData));
  }

  setDisplayData() {
    if (this.carddata.length > this.count) {
      this.dashboardData.push(...this.carddata.slice(this.dashboardData.length, this.count));
    }
    else {
      this.dashboardData = this.carddata;
    }
  }

  changeGridView(data) {

    if (data) {
      if (data.count > 0) {
        if (data.type === "Notransmit") {
          this.filterValue = "No Transmission";
          this.pipeFilter(this.filterValue, this.count, this.statusGrid, this.manualFilter, this.searchInput)
        } else {
          this.filterValue = data.type;
          this.pipeFilter(this.filterValue, this.count, this.statusGrid, this.manualFilter, this.searchInput)
        }
      } else if (data.count == 0) {
        if (this.entryPoint == "ATM") {
          this.commonService.presentToast("No Atm to show")
        }
        else {
          this.commonService.presentToast("No Vehicles to show")
        }
      }
    }
  }

  livetrackWeb($event) {
    // this.selectedVin = $event;
  }
  async todayAlerts() {
    let date = new Date();
    let data = {
      "address": "false",
      "vin": "All",
      "userId": localStorage.userName,
      "companyId": localStorage.corpId,
      "fromDate": date.toJSON().split("T")[0] + " 00:00:00",
      "toDate": date.toJSON().split("T")[0] + " 23:59:59",
    };
    if (date.toJSON().split("T")[0], date.toJSON().split("T")[0]) {
      const url = serverUrl.web + "/alert/mobileAlert"
      await this.ajaxService.ajaxPostWithBody(url, data)
        .subscribe(res => {
          if (res != "" && res.length > 0) {
            this.alertDataLength = res.length;
          } else if (res.length == 0) {
            this.alertDataLength = 0
          } else {
            this.commonService.presentToast("No Alerts Found");
          }
        });
    }
  }

  radioGroupChange(event) {
    if (event.detail.value == "livetrack" || event.detail.value == "trackhistory") {
      this.pageSeleter = event.detail.value;
      this.eventtriger = false
    } else {
      this.eventtriger = true
    }
    if (this.pageSeleter == "livetrack" && !this.eventtriger) {
      if (this.plateNumbers.length != 0) {
        this.plateNumbers.forEach(plateNo => {
          this.checkboxStates[plateNo] = false;
        });
      }
      this.multiTrackObj = {};
      this.multitrack = {};
      this.plateNumbers = [];
      this.vins = [];
    } else if (this.pageSeleter == "trackhistory" && !this.eventtriger) {
      if (this.plateNumbers.length != 0) {
        this.plateNumbers.forEach(plateNo => {
          this.checkboxStates[plateNo] = false;
        });
      }
      this.multiTrackObj = {};
      this.multitrack = {};
      this.plateNumbers = [];
      this.vins = [];
    }
  }

  wsResponse(res) {
    console.log("multitracking");


    if (Array.isArray(res.alerts) && res.alerts.length !== 0 && this.entryPoint == "ATM") {
      if (res.alerts[0].vid.alerttype == "POWERCUT" || res.alerts[0].vid.alerttype == "MOVEMENT") {
        this.commonService.playAudio();;
      }
    }
    if (res != null) {
      var hasData = res.liveDatas;

      if (hasData) {
        Object.keys(this.multiTrackObj).forEach(livekey => {
          if (hasData[livekey])
            this.multiTrackObj[livekey] = { ...hasData[livekey] };
        })
        if (res.statusCount == null) {
          Object.values(res['liveDatas'])[0]["odometer"] = parseInt(Object.values(res['liveDatas'])[0]["odometer"]) / 1000
          this.updatedJsonData = storageVariable.upDatedJsonData;
          if (this.updatedJsonData["liveDatas"][Object.keys(res['liveDatas'])[0]]) {
            this.updatedJsonData["liveDatas"][Object.keys(res['liveDatas'])[0]] = Object.values(res['liveDatas'])[0]
          } else {
            delete res["liveDatas"][Object.keys(res['liveDatas'])[0]]
          }
          res.statusCount = this.updatedJsonData['statusCount']
        }
        else {
          this.updatedJsonData = res;
          for (let i = 0; i < Object.keys(this.updatedJsonData.liveDatas).length; i++) {
            Object.values(this.updatedJsonData.liveDatas)[i]["odometer"] = parseInt(Object.values(this.updatedJsonData.liveDatas)[i]["odometer"]) / 1000
          }
        }

        const data = Object.entries(this.updatedJsonData.liveDatas).map(([key, value]) => value['status']).sort()

        let current = null;
        let cnt = 0;
        for (let i = 0; i < data.length; i++) {
          if (data[i] != current) {
            if (cnt > 0) {

            }
            current = data[i];
            cnt = 1;
          } else {
            cnt++;
          }
          res.statusCount[current] = cnt
        }
        for (let i = 0; i < Object.keys(res.statusCount).length; i++) {
          if (!data.includes(Object.keys(res.statusCount)[i]) && Object.keys(res.statusCount)[i] != 'Total') {
            res.statusCount[Object.keys(res.statusCount)[i]] = 0
          }
        }
        if (cnt > 0) {
          res.statusCount[current] = cnt
        }
        res['statusCount']['Total'] = Object.keys(res.liveDatas).length;
        storageVariable.dashboardData = this.updatedJsonData;
        storageVariable.upDatedJsonData = this.updatedJsonData
        if (this.liveDatas) {
          Object.assign(this.liveDatas, res.liveDatas);
        } else {
          this.liveDatas = res.liveDatas;
        }
        res['statusCount']['Total'] = Object.keys(this.updatedJsonData.liveDatas).length;
        storageVariable.dashboardData = this.updatedJsonData;
        storageVariable.upDatedJsonData = this.updatedJsonData
        this.updateCache();
        let updatedData: any = storageVariable.upDatedJsonData;
        if (!localStorage.modalFilterData)
          this.vehicleCount = updatedData.statusCount;
        else if (localStorage.modalFilterData) {
          let filterCount: any = {}
          Object.keys(this.vehicleCount).map(res => filterCount[res] = 0);
          JSON.parse(localStorage.modalFilterData).map(res => {
            res = storageVariable.upDatedJsonData.liveDatas[res.vin]
            filterCount[res.status] = filterCount[res.status] + 1
          })
          filterCount['Total'] = JSON.parse(localStorage.modalFilterData).length
          this.vehicleCount = filterCount
        }
      }
    }
  }
  wsOnError() {
    setTimeout(() => {
      this.websocketService.connectSocket(JSON.parse(localStorage.dashboardWebSocketData), "livetrack");
    }, 60000);
  };

  ngDoCheck() {
    if (localStorage.updateGridData == "true") {
      localStorage.setItem("updateGridData", "false");
    }
  }

  pipeFilter(terms: string, count: number, statusGrid: string[], manualFilter: any[], search: string) {
    if (terms) {
      var loc;
      if (!this.carddata)
        loc = [];
      if (!search)
        loc = this.carddata;
      search = search.toLowerCase();
      loc = this.carddata
      var newloc = []
      if (this.plateNumbers.length == 0) {
        loc.filter(it => {
          if (it.plateNo != null)
            if (it.plateNo.replace(/ /g, '').toLowerCase().includes(search.replace(/ /g, ''))) {
              newloc.push(it)
            }
        });
      } else {
        loc.filter(it => {
          if (it.plateNo != null)
            if (it.plateNo.replace(/ /g, '').toLowerCase().includes(search.replace(/ /g, '')) || this.plateNumbers.includes(it.plateNo)) {
              newloc.push(it)
            }
        });
      }
      let data: Array<Object>;
      if (terms !== "All" && terms !== "Vin") {
        data = newloc.filter(it => {
          return it.status === terms || this.plateNumbers.includes(it.plateNo);
        });
      } else if (terms === "All") {
        data = newloc;
      }
      else {
        let vinData = [];
        for (let i = 0; i < statusGrid.length; i++) {
          this.carddata.filter(it => {
            if (it.vin === statusGrid[i]) {
              vinData.push(it);
            }
            return vinData;
          });
        }
        data = vinData;
      }
      if (!storageVariable.dashboardData)
        data.sort(function (a: any, b: any) {
          return new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime();
        });

      localStorage.setItem('maxLength', data.length.toString());

      let data1: Array<Object> = [];
      for (let i = 0; i < this.count; i++) {
        if (data[i]) {
          data1.push(data[i]);
        } else {
          break;
        }
      }
      if (localStorage.gridDataLength !== data1.length.toString()) {
        localStorage.setItem('updateGridData', 'true');
      }
      localStorage.setItem('gridDataLength', data1.length.toString());
      localStorage.setItem('gridData', JSON.stringify(data1));

      this.dashboardData = data1.slice()
    }
  }


  togglePlateNo(event: any, plateNo: any, vin: any, data: any, index) {
    const isChecked = event.target.checked;

    const selectedCount = this.plateNumbers.length;
    this.selectedVin = data;
    localStorage.setItem("selectedVin", JSON.stringify(data))
    if (!isChecked && selectedCount >= 6) {
      this.commonService.presentAlert("WARNING", "Maximum Vehicle Selection is 6 only");
      this.checkboxStates[plateNo] = true;
    } else {
      this.checkboxStates[plateNo] = isChecked;
      if (!isChecked) {

        if (!this.plateNumbers.includes(plateNo)) {
          this.plateNumbers.push(plateNo);
          if (this.myTree != undefined) {
            let selectRow = document.getElementById(this.treeStructureObject[this.storageVar.liveDatas[vin].plateNo].id)
            this.myTree.checkItem(selectRow, true);
          }
          this.vins.push(vin);
          this.multiTrackObj[vin] = data;
          this.multiTrackingArray.push(...Object.values(this.multiTrackObj));
          this.disabled = [];
        }
      } else {
        const index = this.plateNumbers.indexOf(plateNo);
        delete this.multiTrackObj[vin];
        if (index !== -1) {
          this.plateNumbers.splice(index, 1);
          if (this.myTree != undefined) {
            let selectRow = document.getElementById(this.treeStructureObject[this.storageVar.liveDatas[vin].plateNo].id)
            this.myTree.uncheckItem(selectRow);
          }
          this.disabled = this.plateNumbers;
          this.vins.splice(index, 1);
        }
      }
    }
  }

  dashboard(index: number, data: any): any {
    return data.vin;
  }

  removeAll() {
    if (this.plateNumbers.length != 0) {
      this.plateNumbers.forEach(plateNo => {
        this.checkboxStates[plateNo] = false;
      });
    }
    if (this.myTree != undefined)
      this.myTree.uncheckAll();
    this.multiTrackObj = {};
    this.multitrack = {};
    this.plateNumbers = [];
    this.vins = [];
    this.showFilterView=false
  }


  showTracking() {
    this.showFilterView=false
    this.content.scrollToTop()
    this.livetrack = true;
    this.shouldRender = true;
    this.renderedCards = [];
    if (this.carddata && this.carddata.length > 0 && this.plateNumbers && this.plateNumbers.length > 0) {
      const plateNumberMap = new Map();
      this.carddata.forEach(item => {
        plateNumberMap.set(item.plateNo, item);
      });
      const reorderedData = [];
      this.plateNumbers.forEach(plateNo => {
        if (plateNumberMap.has(plateNo)) {
          reorderedData.push(plateNumberMap.get(plateNo));
          plateNumberMap.delete(plateNo);
        }
      });
      plateNumberMap.forEach(value => {
        reorderedData.push(value);
      });
      this.carddata = reorderedData;
      this.count = 12
      this.pipeFilter(this.filterValue, this.count, this.statusGrid, this.manualFilter, this.searchInput)
      this.multitrack = { ...this.multiTrackObj };
    }

  }
  delayTrackHistory(index: number): boolean {
    this.maxIndex = (this.plateNumbers.length - 1)
    if (this.shouldRender) {
      if (index === 0) {
        this.renderedCards[index] = true;
      } else if (this.shouldRender && index <= this.maxIndex) {

        setTimeout(() => {
          this.renderedCards[index] = true;
        }, 3000 * (index));
        if (index == this.maxIndex)
          this.shouldRender = false;

        return this.renderedCards[index];
      } else {
        this.shouldRender = false;
        return true;
      }
    } else {
      return this.renderedCards[index];
    }
  }
  searchgroup() {
    const searchedValue = this.search.trim().toLowerCase();

    let data;
    if (this.myTree != undefined) {
        data = this.myTree.getItems();
    } else {
        return;
    }

    let foundItem = [];
    let expandedParents = new Set(); 

    data.forEach(res => {
        const labelText = res.label.toLowerCase();
        const textDiv = res.titleElement[0]; 

        textDiv.querySelectorAll('.highlightedText').forEach(span => {
            span.outerHTML = span.innerText;
        });

        if (searchedValue !== '' && labelText.includes(searchedValue)) {
            foundItem.push(res.element);
            this.myTree.expandItem(res.parentElement);
            expandedParents.add(res.parentElement); 
            textDiv.innerHTML = textDiv.innerHTML.replace(
                new RegExp(searchedValue, 'gi'),
                match => `<span class="highlightedText" style="font-weight: bolder;">${match}</span>`
            );
        }
    });

      data.forEach(res => {
          if (!expandedParents.has(res.parentElement) && !foundItem.includes(res.parentElement) && res.label != "Select all") {
                  if(res.parentElement.id != -1)
                  this.myTree.collapseItem(res.parentElement);
          }
      });
    

    

  if (foundItem.length > 0) {
    if(foundItem[0].id != -1){
      this.myTree.selectItem(foundItem[0]);
      this.myTree.ensureVisible(foundItem[0]);
    }
  }

  if (searchedValue === '') {
    for (const res of data) {
        foundItem = res; 
        this.myTree.expandAll();
        this.myTree.selectItem(null);
        this.myTree.ensureVisible(res.element);
        break;
    }
}
}
  renderPlateNo() {
    this.treeStructureJson.push(
      {
        id: -1,
        parentid: null,
        text: this.trans.instant("Select all"),
        value: "Select all"
      })

    this.treeStructureObject['Select All'] = {
      id: -1,
      parentid: null,
      text: "Select all",
      value: "Select all"
    }
    let grpJsonId = 1;
    for (let i = 0; i < this.dashboardJson.length; i++) {
      let groupArray: any = [];
      groupArray = this.dashboardJson[i].group == null || this.dashboardJson[i].group == undefined || this.dashboardJson[i].group == "" ? ["NoneGroup"] : this.dashboardJson[i].group.split(",")
      let filterTextFormat = ""
      for (let j = 0; j < groupArray.length; j++) {
        filterTextFormat += groupArray[j]
        if (this.filterData[filterTextFormat] == undefined) {
          this.filterData[filterTextFormat] = grpJsonId;
          grpJsonId++;
        }
        if (groupArray.length - 1 != j)
          filterTextFormat += ","
      }
      // groupArray = this.dashboardJson[i].group == null ? "None group" : this.dashboardJson[i].group
      this.treeStructureObject[this.dashboardJson[i].plateNo] = {
        id: grpJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i],
        disabled: false
      }

      this.treeStructureJson.push({
        id: grpJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i],
        disabled: false
      })

      grpJsonId++;
    }


    for (let i = 0; i < Object.keys(this.filterData).length; i++) {
      let loopedArry: any = Object.keys(this.filterData)[i].split(",").length - 1;
      loopedArry = Object.keys(this.filterData)[i].split(",")[loopedArry]
      var popData = Object.keys(this.filterData)[i].split(",")
      popData.pop()
      this.treeStructureObject[loopedArry] = {
        id: Object.values(this.filterData)[i].toString(),
        parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
        text: loopedArry,
        value: loopedArry,
        disabled: false
      }

      this.treeStructureJson.push({
        id: Object.values(this.filterData)[i].toString(),
        parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
        text: loopedArry,
        value: loopedArry,
        disabled: false
      })
    }


    this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
  }

  openGroupPlateNoTree() {
    this.commonService.presentLoader();
    this.showFilterView = !this.showFilterView;
    this.checkitem = false;
    setTimeout(() => {
      if (this.myTree != undefined) {
        this.myTree.expandAll();
        this.myTree.allowDrag(false);



        if (this.vins.length != 0) {
          for (let i = 0; i < this.vins.length; i++) {
            let selectRow = document.getElementById(this.treeStructureObject[this.storageVar.liveDatas[this.vins[i]].plateNo].id)
            this.myTree.checkItem(selectRow, true);
          }
        }
      }
      this.checkitem = true
    }, 1000);
    this.commonService.dismissLoader();
  }
  selectt(res) {
    console.log(res);

  }

  SelectTree(res) {
    console.log(res);
    
    if (this.checkitem) {
      let checked = this.myTree.getCheckedItems();
      console.log(checked.length);
      // const selectedCount = checked.length;
      if (checked.length <= 6) {
        checked.map((res: any) => {
          if (!this.plateNumbers.includes(res.label) && res.parentId > 0) {
            this.plateNumbers.push(res.label);
            this.vins.push(res.value.vin);
            this.checkboxStates[res.label] = true;
            this.multiTrackObj[res.value.vin] = res.value;
          }
        })
      } else {
        this.commonService.presentAlert("WARNING", "Maximum Vehicle Selection is 6 only");
        const lastCheckedItem = checked.pop();
        this.myTree.uncheckItem(res.args.element);
      }
      if (checked.length <= 6) {
        let unchecked = this.myTree.getUncheckedItems()
        unchecked.map((res: any) => {
          if (this.plateNumbers.includes(res.label) && res.parentId > 0) {
            const index = this.plateNumbers.indexOf(res.label);
            this.checkboxStates[res.label] = false
            delete this.multiTrackObj[res.value.vin];
            this.multitrack = { ...this.multiTrackObj }
            if (index !== -1) {
              this.plateNumbers.splice(index, 1);
              this.vins.splice(index, 1);
            }
          }
        })
      }
    }


  }


  removePlateNumber(index: number) {
    let removedPlateNo = this.plateNumbers[index];
    let removedvin = this.vins[index]

    this.checkboxStates[removedPlateNo] = false;

    this.plateNumbers.splice(index, 1);
    this.disabled = this.plateNumbers
    this.vins.splice(index, 1)
    if (this.myTree != undefined) {
    let selectRow = document.getElementById(this.treeStructureObject[this.storageVar.liveDatas[removedvin].plateNo].id)
    this.myTree.uncheckItem(selectRow);
    }
    delete this.multiTrackObj[removedvin];
    if (Object.keys(this.multitrack).length !== 0)
      this.multitrack = { ...this.multiTrackObj };
  }


  removevin(removedvin, plateno) {
    const index = this.plateNumbers.indexOf(plateno);
    this.checkboxStates[plateno] = false;
    this.plateNumbers.splice(index, 1);
    this.disabled = this.plateNumbers
    this.vins.splice(index, 1)
    if (this.myTree != undefined) {
    let selectRow = document.getElementById(this.treeStructureObject[this.storageVar.liveDatas[removedvin].plateNo].id)
    this.myTree.uncheckItem(selectRow);
    }
    delete this.multiTrackObj[removedvin];
    this.multitrack = { ...this.multiTrackObj };
  }

  async vehicleoverview(res) {
    localStorage.setItem("selectedVin", JSON.stringify(res))
    let model: any;
    let style
    if (this.myPlatform == "desktop") {
      style = "geoFenceCreationModal"
      style = app.entryPoint == "PEAX" && res.data.icon != "DEEPSEA GENERATOR" ? "geoFenceCreationModal2" : app.entryPoint == "WFT" && (res.data.icon == "Employee" || res.data.icon == "EMPLOYEE") ? "geoFenceCreationModal3" : style;
    } else {
      style = "vts-livetrack-mobile"
    }
    if (this.pageSeleter == "livetrack") {
      model = await this.modalController.create({
        component: VehicleOverviewComponent,
        cssClass: style,
        componentProps: { obj: res }
      })

      model.onDidDismiss().then(() => {
        this.commonService.presentLoader();
        // this.websocketService.setProductService(this);
        let vin = JSON.parse(localStorage.selectedVin).vin
        let appSettings = JSON.parse(localStorage.appSettings);
        const credentials = {
          dashboardVin: vin,
          defaultInterval: appSettings.liveTrackingDelay,
          make: storageVariable.upDatedJsonData.liveDatas[vin].make,
          model: storageVariable.upDatedJsonData.liveDatas[vin].model,
          delay: appSettings.liveTrackingDelay,
          ImeiNo: storageVariable.upDatedJsonData.liveDatas[vin].imeiNo,
          entryPoint: app.entryPoint,
          Check: false,
          emailId: localStorage.userName,
          branchID: localStorage.corpId,
          companyID: localStorage.corpId,
          // mode: "mobile"
          mode: 'liveTrackDisConnect'
        };
        // if (!this.restrictedCommandImei.includes(storageVariable.upDatedJsonData.liveDatas[vin].imeiNo))
        this.websocketService.reSendRequest(credentials);
        this.websocketService.setProductService(this);
        this.websocketService.reSendRequest(JSON.parse(localStorage.dashboardWebSocketData));
        if (this.commonService.isLoading)
          this.commonService.dismissLoader();
      })
      return await model.present();
    } else if (this.pageSeleter == "trackhistory") {
      style = "trackhistorymodal"
      model = await this.modalController.create({
        component: VtsTrackhistoryComponent,
        cssClass: "trackhistorymodal",
        componentProps: { gridLiveChange: res, type: "Track" }
      })
      model.onDidDismiss().then(() => {
      })
      return await model.present();
    }
  }
  updateCache = () => {
    if (this.cacheAddress && this.liveDatas) {
      Object.keys(this.cacheAddress).forEach(vin => {
        this.liveDatas[vin]["location"] = this.cacheAddress[vin];
      });
    }


    // if (this.searchInput == "" && this.filterValue == "Al")
    //   this.setDisplayData()
    // else
    if (this.plateNumbers.length == 0) {
      this.carddata = Object.values(this.liveDatas);
      this.pipeFilter(this.filterValue, this.count, this.statusGrid, this.manualFilter, this.searchInput)
    } else {
      Object.values(this.liveDatas).forEach((data: any) => {
        if (!this.plateNumbers.includes(data.plateNo)) {
          const dataIndex = this.carddata.findIndex(item => item.plateNo === data.plateNo);
          if (dataIndex !== -1) {
            this.carddata[dataIndex] = data;
          }
        }
      });
      this.pipeFilter(this.filterValue, this.count, this.statusGrid, this.manualFilter, this.searchInput)
    }

  }
  async presentPopup() {
    let eventEmitter = new EventEmitter();
    eventEmitter.subscribe(res => {
     // this.filterPage(null)
    });
    const modal = await this.modalController.create({
      component: ModalPage,
      cssClass: this.directory == 'ltr' ? 'my-custom-class' : 'my-custom-class-arabic',
      showBackdrop: false,
      componentProps: {
        filterReturn: eventEmitter
      }
    });
    //modal.onDidDismiss().then(() => this.filterPage(null));
    return await modal.present();
  }


  ngOnInit() {


    this.entryPoint = localStorage.entryPoint
    this.myPlatform = "desktop";
    this.filterValue = "All";
    this.manualFilter = [];
    this.statusGrid = [];
    if (storageVariable.dashboardData)
      this.carddata = Object.values(storageVariable.dashboardData.liveDatas)
    let data: any = storageVariable.upDatedJsonData;
    if (data)
      this.vehicleCount = data.statusCount;
    this.pipeFilter(this.filterValue, this.count, this.statusGrid, this.manualFilter, this.searchInput)
    //this.setDisplayData()
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      });
    let a: any[] = Object.values(storageVariable.dashboardData.liveDatas);
    this.dashboardJson = a
      .filter(item => !item['warrantyExpiry'])
      .map(item => ({ ...item }));
    this.renderPlateNo();
    this.websocketService.setProductService(this);
    this.websocketService.reSendRequest(JSON.parse(localStorage.dashboardWebSocketData));
  }

}
