import { Component, ViewChild, OnChanges, Input, OnInit, ElementRef } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { TranslateService } from '@ngx-translate/core';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { ExportExcelService } from '../../services/export-excel.service';
import { app, companyReportData, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  titles = 'jspdf-autotable-demo';
  title = 'angular-export-to-excel';
  @Input() reportTitle;
  @Input() tableValueOnChange;
  @Input() temperatureTitle;

  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  dataForExcel = [];
  tableData: any;
  reportsData;
  objValues = [];
  odj = [];
  data: any;
  myPanel: any;
  columngroups: any = [];
  w: string;
  alert: any = [];
  header: { text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; }[];
  splitHeader: string[];
  selectedTab = "Summary"
  clickmode: string;
  multiRows: string;
  localizationobj: {};
  directory = languageInitializer.directory;
  widthPercentage: any;


  constructor(

    private ete: ExportExcelService,
    private trans: TranslateService,
    private ajaxService: AjaxService,
    private commonService: CommonService
  ) {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      });
  }

  source: { localdata: any; };
  dataAdapter: any;
  columns: { text: string; datafield: string; cellalign: string; align: string; }[];
  renderer: (row: number, column: any, value: string) => string;
  head = [];
  exportTitle = [];
  excelTitle = {};
  newTitle = [];
  column = [];
  reportKeys: any;


  // ngOnChanges() {
  //   // this.tableDetails();
  // }
  // ngAfterViewInit() {
  //   this.myGrid.showloadelement();
  //   // this.tableDetails();
  // }
  changeTab(head) {
    this.selectedTab = head;
    this.tableDetails();
  }
  ngOnChanges() {
    // console.log("this.myGrid.getrows()",this.myGrid.getrows());

    this.tableDetails()
  }
  tableDetails() {
    this.renderer = (row: number, column: any, value: any,) => {
      if ((column == "empName" || column == "EmployeeName" || column == "employeeName" || column == 'plateNo') && value.split("@,@")[1]) {
        return `<div  style="vertical-align: middle;font-size:10px;padding:5px;">${value.split("@,@")[0]} ${value.split("@,@")[1] ? "(" + value.split("@,@")[1] + ")" : ""}</div>`;
      } else {
        // if (value == "" || null || undefined) {
        //   return "--"
        // } 
        if (value === "" || value === "null" || value === undefined || value === null) {
          return "--"; 
        }
        if (value == 0) {
          return '<div  style="vertical-align: middle;font-size:10px;padding:5px;">' + "0" + '</div>';
        }
        if (value == "{ 'alertCount': '' }") {
          return "--";
        }
        else {
          return '<div  style="vertical-align: middle;font-size:10px;padding:5px;">' + value + '</div>';
        }
      }
    }
    this.tableData = companyReportData.reportData
    this.clickmode = 'singlerow'
    if (this.reportTitle == "movementReport") {
      this.column = [
        {
          text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer
        },
        { text: this.trans.instant('Speed'), datafield: "speed", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "status", cellsrenderer: this.renderer },
        { text: this.trans.instant('Time Stamp'), datafield: "timeStamp", cellsrenderer: this.renderer },
        { text: this.trans.instant('Operator'), datafield: "operatorName", cellsrenderer: this.renderer, width: 100 },
        { text: this.trans.instant('Address'), datafield: "emailAddress", width: 300, cellsrenderer: this.renderer, }
      ];
    } else if (this.reportTitle == "Operator Report") {  
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "PlateNo", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Trip-Start Time'), datafield: "tripStartTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Trip-Start Date'), datafield: "tripStartdate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Trip-Start Time'), datafield: "tripStarttime", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Trip-Stop Time'), datafield: "tripStopTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Trip-Stop Date'), datafield: "tripStopdate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Trip-Stop Time'), datafield: "tripStoptime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Duration (Days:HH:MM:SS)'), datafield: "duration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Operator Name'), datafield: "operatorName", cellsrenderer: this.renderer },
      ];

    } else if (this.reportTitle == "vehicleMovementReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Operator Name'), datafield: "operatorName", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Time Stamp'), datafield: "timeStamp", cellsrenderer: this.renderer },
        { text: this.trans.instant('Event Date'), datafield: "date", cellsrenderer: this.renderer },
        { text: this.trans.instant('Event Time'), datafield: "time", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "status", cellsrenderer: this.renderer },
        { text: this.trans.instant('Day odometer'), datafield: "ioEvent", cellsrenderer: this.renderer, width: 100 },
        { text: this.trans.instant('Address'), datafield: "address", width: 300, cellsrenderer: this.renderer, }
      ];
    }
    else if (this.reportTitle == "overallSummaryReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Begin'), datafield: "begin", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Date'), datafield: "begindate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Time'), datafield: "begintime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Location'), datafield: "beginLocation", cellsrenderer: this.renderer },
        // { text: this.trans.instant('End'), datafield: "end", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Date'), datafield: "enddate", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Alerts Count'), datafield: "totalCount", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Location'), datafield: "endLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('Max Speed'), datafield: "maxSpeed", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "odometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Running'), datafield: "runningDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Stop'), datafield: "stopDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle'), datafield: "idleDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Towed'), datafield: "towedDuration", cellsrenderer: this.renderer },
      ];

      // this.tableData.map(res => {
      //   this.alert = Object.values(res.alertCount)
      //   if (this.alert.length > 0)
      //     res.alertCount = this.alert.reduce((a, b) => a + b);
      // })



    }

    else if (this.reportTitle == "EngineHoursSummaryReport") {
      this.column = [
        { text: this.trans.instant('Plate no'), datafield: "plateNo", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('Start time'), datafield: "startTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Date'), datafield: "Startdate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Time'), datafield: "Starttime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start location'), datafield: "startAddress", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('End time'), datafield: "endTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Date'), datafield: "enddate", cellsrenderer: this.renderer },
        { text: this.trans.instant('End time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End location'), datafield: "stopAddress", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "odometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Running'), datafield: "runningDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle'), datafield: "idleDuration", cellsrenderer: this.renderer }
      ];

    } else if (this.reportTitle == "stopReport") {
      this.column = [
        { text: this.trans.instant('Plate no'), datafield: "plateNo", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('Started Again At'), datafield: "begin", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('Stop At'), datafield: "end", cellsrenderer: this.renderer },
        { text: this.trans.instant('Stop At Date'), datafield: "begindate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Stop At Time'), datafield: "begintime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Started Again At Date'), datafield: "enddate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Started Again At Time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Duration'), datafield: "stopDur", cellsrenderer: this.renderer },
        { text: this.trans.instant('Location'), datafield: "endLocation", cellsrenderer: this.renderer }
      ];

    }
    // else if (this.reportTitle == "notransmissionreport") {
    //   this.column = [
    //     { text: this.trans.instant('Plate no'), datafield: "plateNumber", cellsrenderer: this.renderer },
    //     { text: this.trans.instant('Device imei no'), datafield: "imeiNo", cellsrenderer: this.renderer },
    //     { text: this.trans.instant('Sim card no'), datafield: "simNo", cellsrenderer: this.renderer },
    //     { text: this.trans.instant('Company name'), datafield: "companyId", cellsrenderer: this.renderer },
    //     { text: this.trans.instant('Last transmission'), datafield: "lastTransTime", cellsrenderer: this.renderer },
    //     { text: this.trans.instant('Operator name'), datafield: "operatorName", cellsrenderer: this.renderer },
    //   ];
    // }

    else if (this.reportTitle == "notransmissionreport") {
      if (app.entryPoint == "ATM") {
        this.column = [
          { text: this.trans.instant('ATM ID'), datafield: "AtmId", cellsrenderer: this.renderer },
          { text: this.trans.instant('Model'), datafield: "ModelName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Address'), datafield: "Address", cellsrenderer: this.renderer }, 
          { text: this.trans.instant('IMEI No'), datafield: "imeiNo", cellsrenderer: this.renderer },
          { text: this.trans.instant('Sim Card No'), datafield: "simNo", cellsrenderer: this.renderer },
          // { text: this.trans.instant('Company Name'), datafield: "companyId", cellsrenderer: this.renderer },
          { text: this.trans.instant('Last Transmission'), datafield: "lastTransmission", cellsrenderer: this.renderer },
          { text: this.trans.instant('Manufacturer'), datafield: "ManufacturerName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Date Of Purchase'), datafield: "DateOfpurchase", cellsrenderer: this.renderer },
        ]
      } else {
        this.column = [
          { text: this.trans.instant('Plate No'), datafield: "plateNumber", cellsrenderer: this.renderer },
          //{ text: this.trans.instant('Model'), datafield: "ModelName", cellsrenderer: this.renderer },  
         // { text: this.trans.instant('IMEI No'), datafield: "imeiNo", cellsrenderer: this.renderer },
          //{ text: this.trans.instant('Sim Card No'), datafield: "simNo", cellsrenderer: this.renderer },
          // { text: this.trans.instant('Company Name'), datafield: "companyId", cellsrenderer: this.renderer },
          // { text: this.trans.instant('Last Transmission'), datafield: "lastTransTime", cellsrenderer: this.renderer },
          { text: this.trans.instant('Last Transmission Date'), datafield: "lastTransTimedate", cellsrenderer: this.renderer },
          { text: this.trans.instant('Last Transmission Time'), datafield: "lastTransTimetime", cellsrenderer: this.renderer },
          //{ text: this.trans.instant('Operator Name'), datafield: "operatorName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Manufacturer'), datafield: "ManufacturerName", cellsrenderer: this.renderer },
         // { text: this.trans.instant('Date Of Purchase'), datafield: "DateOfpurchase", cellsrenderer: this.renderer },
        ];
      }
    } else if (this.reportTitle == "atmMovementReport") {
      this.column = [
        { text: this.trans.instant('ATM ID'), datafield: "atmID", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin At'), datafield: "begin", cellsrenderer: this.renderer },  
        { text: this.trans.instant('End At'), datafield: "end", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Location'), datafield: "beginLocation", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Company Name'), datafield: "companyId", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Location'), datafield: "endLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('Duration'), datafield: "moveDur", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Date Of Purchase'), datafield: "DateOfpurchase", cellsrenderer: this.renderer },
      ]
    } else if (this.reportTitle == "atmStatusSummaryReport") {
      this.column = [
        { text: this.trans.instant('ATM ID'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin At'), datafield: "begin", cellsrenderer: this.renderer },  
        { text: this.trans.instant('End At'), datafield: "end", cellsrenderer: this.renderer },
        { text: this.trans.instant('From Date'), datafield: "fromDate", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Company Name'), datafield: "companyId", cellsrenderer: this.renderer },
        { text: this.trans.instant('To Date'), datafield: "toDate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "status", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Date Of Purchase'), datafield: "DateOfpurchase", cellsrenderer: this.renderer },
      ]
    }
    else if (this.reportTitle == "alertmailreport") {
      if (app.entryPoint == "ATM") {
        this.column = [
          { text: this.trans.instant('ATM ID'), datafield: "PlateNo", cellsrenderer: this.renderer },
          { text: this.trans.instant('AlertType'), datafield: "alertType", cellsrenderer: this.renderer },
          { text: this.trans.instant('Date'), datafield: "Date", cellsrenderer: this.renderer },
          { text: this.trans.instant('Email1'), datafield: "Mail1", cellsrenderer: this.renderer },  
          { text: this.trans.instant('Email1 Status'), datafield: "Mail1Result", cellsrenderer: this.renderer },
          { text: this.trans.instant('Email2'), datafield: "Mail2", cellsrenderer: this.renderer },
          // { text: this.trans.instant('Company Name'), datafield: "companyId", cellsrenderer: this.renderer },
          { text: this.trans.instant('Email2 Status'), datafield: "Mail2Result", cellsrenderer: this.renderer },

        ]
      } else {
        this.column = [
          { text: this.trans.instant('Plate No'), datafield: "PlateNo", cellsrenderer: this.renderer },
          { text: this.trans.instant('AlertType'), datafield: "alertType", cellsrenderer: this.renderer },
          { text: this.trans.instant('Date'), datafield: "Date", cellsrenderer: this.renderer },
          { text: this.trans.instant('Email1'), datafield: "Mail1", cellsrenderer: this.renderer },  
          { text: this.trans.instant('Email1 Status'), datafield: "Mail1Result", cellsrenderer: this.renderer },
          { text: this.trans.instant('Email2'), datafield: "Mail2", cellsrenderer: this.renderer },
          // { text: this.trans.instant('Company Name'), datafield: "companyId", cellsrenderer: this.renderer },
          { text: this.trans.instant('Email2 Status'), datafield: "Mail2Result", cellsrenderer: this.renderer },
        ];
      }
    }

    else if (this.reportTitle == "tripSummaryReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start time'), datafield: "startTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start location'), datafield: "startAddress", cellsrenderer: this.renderer },
        { text: this.trans.instant('End time'), datafield: "endTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End location'), datafield: "stopAddress", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "odometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Running'), datafield: "runningDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle'), datafield: "idleDuration", cellsrenderer: this.renderer }
      ];

    }

    else if (this.reportTitle == 'Temperature Report') {
     
       
        
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "PlateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant(this.temperatureTitle), datafield: "TEMP", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start At'), datafield: "beginAt", cellsrenderer: this.renderer },
        { text: this.trans.instant('End At'), datafield: "endAt", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start location'), datafield: "beginLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('End location'), datafield: "endLocation", cellsrenderer: this.renderer },
      ];
    

  } else if (this.reportTitle == 'Temperature ReportBT') {
   
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "PlateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant(this.temperatureTitle), datafield: "TEMP", cellsrenderer: this.renderer },
        { text: this.trans.instant('Humidity'), datafield: "humidity", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start At'), datafield: "beginAt", cellsrenderer: this.renderer },
        { text: this.trans.instant('End At'), datafield: "endAt", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start location'), datafield: "beginLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('End location'), datafield: "endLocation", cellsrenderer: this.renderer },
      ];
  } else if (this.reportTitle == 'WorkdayEfficiencyReport') {
          
    this.column = [
      { text: this.trans.instant('Plate No'), datafield: "PlateNo", cellsrenderer: this.renderer },
      { text: this.trans.instant('Actual Working Hours'), datafield: "Actual WorkingHours", cellsrenderer: this.renderer },
      { text: this.trans.instant("Percentage"), datafield: "Percentage", cellsrenderer: this.renderer },
      { text: this.trans.instant('Working Days'), datafield: "scheduled WorkingDays", cellsrenderer: this.renderer },
      { text: this.trans.instant('Scheduled WorkingHours'), datafield: "scheduled WorkingHours", cellsrenderer: this.renderer }
    ];
  
}else if (this.reportTitle == "executiveSummaryReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: 'plateNo', cellsrenderer: this.renderer },
        //  { text: this.trans.instant('Begin'), datafield: "begin", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Date'), datafield: "begindate", cellsrenderer: this.renderer },
       // { text: this.trans.instant('Begin Time'), datafield: "begintime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Location'), datafield: "beginLocation", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('End'), datafield: "end", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Date'), datafield: "enddate", cellsrenderer: this.renderer },
        //{ text: this.trans.instant('End Time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Location'), datafield: "endLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('Alerts Count'), datafield: "totalCount", cellsrenderer: this.renderer },
        { text: this.trans.instant('Max Speed'), datafield: "maxSpeed", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "odometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Running'), datafield: "runningDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Stop'), datafield: "stopDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Towed'), datafield: "towedDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle'), datafield: "idleDuration", cellsrenderer: this.renderer }
      ];

    }
    else if (this.reportTitle == "commonReport") {
      this.tableData = companyReportData.reportData
      this.splitHeader = Object.keys(this.tableData)
      if (!this.selectedTab)
        this.selectedTab = this.splitHeader[0].includes("Summary") ? "Summary" : this.splitHeader[0];
      this.tableData = this.tableData[this.selectedTab]
      if (this.selectedTab == "Summary") {
        this.column = [
          { text: this.trans.instant('Plate no'), datafield: 'Plate No', cellsrenderer: this.renderer },
          //{ text: this.trans.instant('Group'), datafield: "Group", cellsrenderer: this.renderer },
          //{ text: this.trans.instant('Operator'), datafield: "Operator", cellsrenderer: this.renderer },
          { text: this.trans.instant('Date'), datafield: "Date", cellsrenderer: this.renderer },
          { text: this.trans.instant('Odometer'), datafield: "Odometer", cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert count'), datafield: "Alert Count", cellsrenderer: this.renderer },
          { text: this.trans.instant('Max speed'), datafield: "Max Speed", cellsrenderer: this.renderer },
          { text: this.trans.instant('Running duration'), datafield: "Running Duration", cellsrenderer: this.renderer },
          { text: this.trans.instant('Stop duration'), datafield: "Stop Duration", cellsrenderer: this.renderer },
          { text: this.trans.instant('Idle duration'), datafield: "Idle Duration", cellsrenderer: this.renderer },
          { text: this.trans.instant('Towed duration'), datafield: "Towed Duration", cellsrenderer: this.renderer },
          { text: this.trans.instant('Begin location'), datafield: "Begin Location", cellsrenderer: this.renderer },
          { text: this.trans.instant('End location'), datafield: "End Location", cellsrenderer: this.renderer }
        ];
      } else {
        this.column = [
          { text: this.trans.instant('Plate no'), datafield: 'Plate No', cellsrenderer: this.renderer },
          { text: this.trans.instant('Group'), datafield: "Group", cellsrenderer: this.renderer },
          { text: this.trans.instant('Operator'), datafield: "Operator", cellsrenderer: this.renderer },
          { text: this.trans.instant('Date'), datafield: "Date", cellsrenderer: this.renderer },
          { text: this.trans.instant('Violation Place'), datafield: "Violation Place", cellsrenderer: this.renderer },
          { text: this.trans.instant('Violation Count'), datafield: "Violation Count", cellsrenderer: this.renderer },
          { text: this.trans.instant('Violation Speed'), datafield: "Violation Speed", cellsrenderer: this.renderer },
          { text: this.trans.instant('Violation Time'), datafield: "Violation Time", cellsrenderer: this.renderer },
          { text: this.trans.instant('Max Speed'), datafield: "Max Speed", cellsrenderer: this.renderer }
        ];
      }
    }
    else if (this.reportTitle == "driverBehavior") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        //{ text: this.trans.instant('Driver Name'), datafield: "driverName", cellsrenderer: this.renderer },
       // { text: this.trans.instant('Group'), datafield: "Group", cellsrenderer: this.renderer },
        {
          text: this.trans.instant('Harsh Braking'), datafield: "HARSHBRAKING", cellsrenderer: function (row, column, value) {
            return "<div style='margin:4% 20%'><span style='padding:0 20%;background:brown;color:white'>+" + value + "</span></div>";
          }
        },
        {
          text: this.trans.instant('Seat Belt'), datafield: "SEATBELT", cellsrenderer: function (row, column, value) {
            return "<div style='margin:4% 15%'><span style='padding:0 15%;background:blue;color:white'>+" + value + "</span></div>";
          }
        },
        {
          text: this.trans.instant('Harsh Acceleration'), datafield: "HARSHACCELERATION", cellsrenderer: function (row, column, value) {
            return "<div style='margin:4% 15%'><span style='padding:0 15%;background:grey;color:white'>+" + value + "</span></div>";
          }
        },
        {
          text: this.trans.instant('Drift'), datafield: "DRIFT", cellsrenderer: function (row, column, value) {
            return "<div style='margin:4% 15%'><span style='padding:0 15%;background:black;color:white'>+" + value + "</span></div>";
          }
        },

        { text: this.trans.instant('Travelled Km'), datafield: "distanceTravelled", cellsrenderer: this.renderer },

        { text: this.trans.instant('Engine On'), datafield: "engineOnDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Running'), datafield: "runningDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Excessive Idle'), datafield: "excessiveIdling", cellsrenderer: this.renderer },
        {
          text: this.trans.instant('Average Speed'), datafield: "avgSpeed", cellsrenderer: function (row, column, value) {
            return "<div style='margin:4% 15%'><span style='padding:0 15%;background:yellow;'>+" + value + "</span></div>";
          }
        },
        {
          text: this.trans.instant('Top Speed'), datafield: "topSpeed", cellsrenderer: function (row, column, value) {
            return "<div style='margin:4% 20%'><span style='padding:0 20%;background:orange;color:white'>+" + value + "</span></div>";
          }
        },
        {
          text: this.trans.instant('Over All Score'), datafield: "overAllScore", cellsrenderer: function (row, column, value) {
            return "<div style='margin:4% 20%'><span style='padding:0 20%;background:green;color:white;'>+" + value + "</span></div>";
          }
        },
      ];
    }


    else if (this.reportTitle == "speedReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Date'), datafield: "date", cellsrenderer: this.renderer },
        { text: this.trans.instant('Time'), datafield: "time", cellsrenderer: this.renderer },
        { text: this.trans.instant('Speed'), datafield: "speed", cellsrenderer: this.renderer },
        { text: this.trans.instant('Operator'), datafield: "operator", cellsrenderer: this.renderer },
        { text: this.trans.instant('Limit Exceeds'), datafield: "descripition", cellsrenderer: this.renderer },
        { text: this.trans.instant('Location'), datafield: "latlng", cellsrenderer: this.renderer }
      ];

    }
    else if (this.reportTitle == "statusSummary") {
      this.column = [
        //{ text: this.trans.instant('Driver'), datafield: "operatorName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Plate No'), datafield: "Plate No", cellsrenderer: this.renderer },
        //{ text: this.trans.instant('Group'), datafield: "Group", cellsrenderer: this.renderer },
        { text: this.trans.instant('Distance'), datafield: "Odometer", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Start Date&Time'), datafield: "Begin", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Date'), datafield: "begindate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Time'), datafield: "begintime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Address'), datafield: "Begin At", cellsrenderer: this.renderer },
        // { text: this.trans.instant('End Date&Time'), datafield: "End", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Date'), datafield: "enddate", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Address'), datafield: "End At", cellsrenderer: this.renderer },
        { text: this.trans.instant('Stop'), datafield: "Stop", cellsrenderer: this.renderer },
        { text: this.trans.instant('Towed'), datafield: "Towed", cellsrenderer: this.renderer },
        { text: this.trans.instant('Running'), datafield: "Running", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle'), datafield: "Idle", cellsrenderer: this.renderer },
      ];
    }

    else if (this.reportTitle == "alertReport") {
      if (app.entryPoint != "WFT" && app.entryPoint != "ATM")
        this.column = [
          { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
         // { text: this.trans.instant('Operator Name'), datafield: "operatorName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert Types'), datafield: "alertTypes", cellsrenderer: this.renderer },
          { text: this.trans.instant('Address'), datafield: "address", cellsrenderer: this.renderer },
          { text: this.trans.instant('Additional info'), datafield: "additionalInfo", cellsrenderer: this.renderer },
          // { text: this.trans.instant('Date & Time'), datafield: "timeStamp", cellsrenderer: this.renderer }
          { text: this.trans.instant('Alert Date'), datafield: "date", cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert Time'), datafield: "time", cellsrenderer: this.renderer }
        ];
      else if (app.entryPoint == "ATM")
        this.column = [
          { text: this.trans.instant('ATM ID'), datafield: "plateNo", cellsrenderer: this.renderer },
          //{ text: this.trans.instant('Operator Name'), datafield: "operatorName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert Types'), datafield: "alertTypes", cellsrenderer: this.renderer },
          { text: this.trans.instant('Address'), datafield: "address", cellsrenderer: this.renderer },
          { text: this.trans.instant('Additional info'), datafield: "additionalInfo", cellsrenderer: this.renderer },
          { text: this.trans.instant('Date & Time'), datafield: "timeStamp", cellsrenderer: this.renderer }
        ];
      else
        this.column = [
          { text: this.trans.instant('Employee Name'), datafield: "plateNo", cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert Types'), datafield: "alertTypes", cellsrenderer: this.renderer },
          { text: this.trans.instant('Address'), datafield: "address", cellsrenderer: this.renderer },
          // { text: this.trans.instant('Date & Time'), datafield: "timeStamp", cellsrenderer: this.renderer }
          { text: this.trans.instant('Alert Date'), datafield: "date", cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert Time'), datafield: "time", cellsrenderer: this.renderer }
        ];

    }
    else if (this.reportTitle == "activeStatusReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: 'Plate Number', cellsrenderer: this.renderer },
        { text: this.trans.instant('Imei No'), datafield: "IMEI Number", cellsrenderer: this.renderer },
        { text: this.trans.instant('Group'), datafield: "Group", cellsrenderer: this.renderer },
        { text: this.trans.instant('icon'), datafield: "iconUrl", cellsrenderer: this.renderer },
        { text: this.trans.instant('regNo'), datafield: "regNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Branch'), datafield: "branch", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "workingStatus", cellsrenderer: this.renderer },

      ];

    }

    else if (this.reportTitle == "overspeedReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Over Speed Duration'), datafield: "duration", cellsrenderer: this.renderer },
        // { text: this.trans.instant('Start Time'), datafield: "start", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Date'), datafield: "startdate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Time'), datafield: "starttime", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('End Time'), datafield: "end", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Date'), datafield: "enddate", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Min Speed'), datafield: "min", cellsrenderer: this.renderer },
        { text: this.trans.instant('Max Speed'), datafield: "max", cellsrenderer: this.renderer },
        { text: this.trans.instant('Avg Speed'), datafield: "avg", cellsrenderer: this.renderer }


      ];

    } else if (this.reportTitle == "temprature") {
      // this.column = [
      //   { text: this.trans.instant('Plate No'), datafield: "plateNo",cellsrenderer:this.renderer },
      //   { text: "date", datafield: "date",cellsrenderer:this.renderer},
      //   { text: "max", datafield: "max",cellsrenderer:this.renderer },
      //   { text: "Min", datafield: "min",cellsrenderer:this.renderer },
      //   { text: "name", datafield: "name" ,cellsrenderer:this.renderer},
      //   { text: "value", datafield: "value",cellsrenderer:this.renderer }

      // ];

      this.column = [
        // { text: this.trans.instant('Plate No'), datafield: "PlateNo", cellsrenderer: this.renderer},
        { text: this.trans.instant('Timestamp'), datafield: "TimeStamp", cellsrenderer: this.renderer }

      ];
      if (Object.keys(this.tableData[0]).includes("TEMPERATURESENSOR1")) {
        this.column.push({ text: '<div style=" line-height: normal;">' + this.tableData[0].TEMPERATURESENSOR1.name + '<br /> (min:' + this.tableData[0].TEMPERATURESENSOR1.min + ', max: ' + this.tableData[0].TEMPERATURESENSOR1.max + ')</div>', datafield: "TEMPERATURESENSOR1value", cellsrenderer: this.renderer, width: 120 })
      }
      if (Object.keys(this.tableData[0]).includes("TEMPERATURESENSOR2")) {
        this.column.push({ text: '<div style=" line-height: normal;">' + this.tableData[0].TEMPERATURESENSOR2.name + '<br /> (min:' + this.tableData[0].TEMPERATURESENSOR2.min + ', max: ' + this.tableData[0].TEMPERATURESENSOR2.max + ')</div>', datafield: "TEMPERATURESENSOR2value", cellsrenderer: this.renderer, width: 120 })
      }
      if (Object.keys(this.tableData[0]).includes("TEMPERATURESENSOR3")) {
        this.column.push({ text: '<div style=" line-height: normal;">' + this.tableData[0].TEMPERATURESENSOR3.name + '<br /> (min:' + this.tableData[0].TEMPERATURESENSOR3.min + ', max: ' + this.tableData[0].TEMPERATURESENSOR3.max + ')</div>', datafield: "TEMPERATURESENSOR2value", cellsrenderer: this.renderer, width: 120 })
      }
      if (Object.keys(this.tableData[0]).includes("TEMPERATURESENSOR3")) {
        this.column.push({ text: '<div style=" line-height: normal;">' + this.tableData[0].TEMPERATURESENSOR4.name + '<br /> (min:' + this.tableData[0].TEMPERATURESENSOR4.min + ', max: ' + this.tableData[0].TEMPERATURESENSOR4.max + ')</div>', datafield: "TEMPERATURESENSOR2value", cellsrenderer: this.renderer, width: 120 })
      }
      // for (var i = 0; i < Object.keys(this.data[0]).sort().length; i++) {
      //   if (Object.keys(this.data[0]).length == i) {
      //     break;
      //   }
      //   var res = Object.keys(this.data[0])[i].split(" ")
      //   if (res.length >= 2) {
      //     this.column.push({ text: '<div style=" line-height: normal;">' + res[0] + '<br />' + res[1] + '</div>', datafield: Object.keys(this.data[0])[i], cellsalign: 'center', align: 'center', cellsrenderer: this.renderer, width: 120 })
      //   } else {
      //     this.column.push({ text: Object.keys(this.data[0])[i], datafield: Object.keys(this.data[0])[i], cellsalign: 'center', align: 'center', cellsrenderer: this.renderer })

      //   }
      // }
    } else if (this.reportTitle == "doorOpenReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Timestamp'), datafield: "timeStamp", cellsrenderer: this.renderer }

      ];
    } else if (this.reportTitle == "doorSummaryReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Door Status'), datafield: "DoorStatus", cellsrenderer: this.renderer },
        { text: this.trans.instant('Time Duration'), datafield: "timeDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Time'), datafield: "startTimeStamp", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Time'), datafield: "endTimeStamp", cellsrenderer: this.renderer }
      ];
    } else if (this.reportTitle == "doorCountReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Door Open Count'), datafield: "count", cellsrenderer: this.renderer }

      ];
    } else if (this.reportTitle == "alarmreport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "status", cellsrenderer: this.renderer },
        { text: this.trans.instant('AlertType'), datafield: "AlertType", cellsrenderer: this.renderer },
        { text: this.trans.instant('Start Time'), datafield: "startTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Time'), datafield: "endTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Time Duration'), datafield: "duration", cellsrenderer: this.renderer }
      ];
    } else if (this.reportTitle == "fuelreport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Consumer price'), datafield: "consumePrice", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "odometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Date'), datafield: "date", cellsrenderer: this.renderer },
        { text: this.trans.instant('Price'), datafield: "price", cellsrenderer: this.renderer },
        { text: this.trans.instant('Consumer quantity'), datafield: "consumeQuantity", cellsrenderer: this.renderer },
        { text: this.trans.instant('Mileage'), datafield: "mileage", cellsrenderer: this.renderer },
        { text: this.trans.instant('Fuel type'), datafield: "fuelType", cellsrenderer: this.renderer }
      ];
    } else if (this.reportTitle == "primitiveMaintanance") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateno", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "currentOdometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Last maintanance'), datafield: "lastmaintenance", cellsrenderer: this.renderer },
        { text: this.trans.instant('Last maintenance time'), datafield: "lastmaintenancetime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Due kilometer'), datafield: "duekilometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "status", cellsrenderer: this.renderer }
      ];
    } else if (this.reportTitle == "workSummaryReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "plateNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Mechine Type'), datafield: "type", cellsrenderer: this.renderer },
        { text: this.trans.instant('Work Start Time At Zone'), datafield: "startWorkTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Total Duration'), datafield: "totDur", cellsrenderer: this.renderer },
        { text: this.trans.instant('Lot Zone'), datafield: "LotData", cellsrenderer: this.renderer },
        { text: this.trans.instant('Neighborhood'), datafield: "zoneData", cellsrenderer: this.renderer },
        { text: this.trans.instant('The End Work Time'), datafield: "endWorkTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "totOdo", cellsrenderer: this.renderer }
      ];
    } else if (this.reportTitle == "Attendance report") {
      this.column = [
        { text: this.trans.instant('Roll No'), datafield: "rollno", cellsrenderer: this.renderer },
        { text: this.trans.instant('First Name'), datafield: "name", cellsrenderer: this.renderer },
        { text: this.trans.instant('Class'), datafield: "class", cellsrenderer: this.renderer },
        { text: this.trans.instant('Section'), datafield: "section", cellsrenderer: this.renderer },
        // { text: "Due kilometer", datafield: "duekilometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "status", cellsrenderer: this.renderer }
      ];

    } else if (this.reportTitle == "executiveSummaryReport") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: 'plateNo', cellsrenderer: this.renderer },
        //  { text: this.trans.instant('Begin'), datafield: "begin", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Date'), datafield: "begindate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Time'), datafield: "begintime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Location'), datafield: "beginLocation", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('End'), datafield: "end", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Date'), datafield: "enddate", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Location'), datafield: "endLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('Alerts Count'), datafield: "totalCount", cellsrenderer: this.renderer },
        { text: this.trans.instant('Max Speed'), datafield: "maxSpeed", cellsrenderer: this.renderer },
        { text: this.trans.instant('Odometer'), datafield: "odometer", cellsrenderer: this.renderer },
        { text: this.trans.instant('Running'), datafield: "runningDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Stop'), datafield: "stopDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Towed'), datafield: "towedDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle'), datafield: "idleDuration", cellsrenderer: this.renderer }
      ];

    }else if (this.reportTitle == "executiveSummaryReportatm") {
      this.column = [
        { text: this.trans.instant('ATM Id'), datafield: 'ATMId', cellsrenderer: this.renderer },
        //  { text: this.trans.instant('Begin'), datafield: "begin", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin AT'), datafield: "beginTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('End At'), datafield: "endTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Location'), datafield: "beginLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Location'), datafield: "endLocation", cellsrenderer: this.renderer },
        { text: this.trans.instant('Alerts Count'), datafield: "totalCount", cellsrenderer: this.renderer },
        { text: this.trans.instant('PowerON'), datafield: "PowerONDur", cellsrenderer: this.renderer },
        { text: this.trans.instant('PowerCut'), datafield: "PowerCutDur", cellsrenderer: this.renderer },
        { text: this.trans.instant('Movement'), datafield: "MovementDur", cellsrenderer: this.renderer },
        { text: this.trans.instant('Battery Drain'), datafield: "BatteryDrainDur", cellsrenderer: this.renderer },
        { text: this.trans.instant('Low Battery'), datafield: "LowBatteryDur", cellsrenderer: this.renderer },
      ];

    }
    else if (this.reportTitle == "fleetSummary") {
      this.column = [
        { text: this.trans.instant('Plate no'), datafield: "Plate No", cellsrenderer: this.renderer },
        { text: this.trans.instant('Operator name'), datafield: "Operator Name", cellsrenderer: this.renderer },
        { text: this.trans.instant('Date of purchase'), datafield: "Date Of Purchase", cellsrenderer: this.renderer },
        { text: this.trans.instant('Date Of Vehicle Associate'), datafield: "Date Of Vehicle Associate", cellsrenderer: this.renderer },

        { text: this.trans.instant('Location'), datafield: "Location", cellsrenderer: this.renderer }
      ];

    } else if (this.reportTitle == "Fuel Report") {
      this.column = [
        { text: this.trans.instant('Plate no'), datafield: "plateno", cellsrenderer: this.renderer },
        { text: this.trans.instant('Fuel Level'), datafield: "feul", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin At'), datafield: "startat", cellsrenderer: this.renderer },
        { text: this.trans.instant('End At'), datafield: "endat", cellsrenderer: this.renderer },
      ];

    } 
    else if (this.reportTitle == "Student Alert Report") {
      this.column.push(
        { text: this.trans.instant('Student Name'), datafield: "studentName", cellalign: "center", align: "center", cellsrenderer: this.renderer })

      var datafeild = [{ "studentName": "gokul", "contactNo": "9962139969", "MATA": true, "MBES": true, "EATA": true, "EBLS": true }]
      var attendance_datas = [{ "Morning Alerts": ["About to Arrive", "Bus Entered School", "gone"] },
      { "Evening Alerts": ["About To Arrive", "Bus Left School", "went"] }]

      // const dat =[[{"studentName":"gokul","contactNo":"9962139969","AbouttoArrive":"sent","AboutToArrive":"sent","BusEnteredSchool":"sent",
      // "BusLeftSchool":"sent",
      // },{"studentName":"raj","contactNo":"996239969","AbouttoArrive":"sent","AboutToArrive": "notsent","BusEnteredSchool":"notsent",
      // "BusLeftSchool":"sent",
      // }], [{"Morning Alerts":["About to Arrive","Bus Entered School","gone"]},
      // {"Evening Alerts":["About To Arrive","Bus Left School","went"]}]]

      for (var i = 0; i < Object.keys(attendance_datas).length; i++) {
        if (Object.keys(attendance_datas).length == i) {
          break;
        }
        this.columngroups.push(
          { text: Object.keys(attendance_datas[i]).toString(), align: 'center', name: Object.keys(attendance_datas[i]).toString() }
        )

        for (var j = 0; j < Object.values(attendance_datas[i])[0].length; j++) {
          this.column.push(
            { text: Object.values(attendance_datas[i])[0][j], columngroup: Object.keys(attendance_datas[i]).toString(), datafield: Object.values(attendance_datas[i])[0][j].replace(/\s+/g, ''), cellalign: "center", align: "center", cellsrenderer: this.renderer },
          )
        }

      }
      this.column.push({ text: this.trans.instant('Contact No'), datafield: 'contactNo', cellalign: "center", align: "center", cellsrenderer: this.renderer })


    } else if (this.reportTitle == "attendancereport") {
      this.column = [
        { text: this.trans.instant('Roll No'), datafield: "rollNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('First Name'), datafield: "firstName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Class'), datafield: "classId", cellsrenderer: this.renderer },
        { text: this.trans.instant('Section'), datafield: "sectionId", cellsrenderer: this.renderer },
        { text: this.trans.instant('Status'), datafield: "mode", cellsrenderer: this.renderer },
      ];

    }


    else if (this.reportTitle == "studentreport") {
      this.column.push(
        { text: this.trans.instant('Student Name'), datafield: "StudentName", cellalign: "center", align: "center", cellsrenderer: this.renderer },
        { text: this.trans.instant('Contact No'), datafield: "Contactno", cellalign: "center", align: "center", cellsrenderer: this.renderer });
      // var datafeild = [{ "studentName": "gokul", "contactNo": "9962139969", "MATA": true, "MBES": true, "EATA": true, "EBLS": true }]
      // var attendance_datas = [{ "Morning Alerts": ["About to Arrive", "Bus Entered School", "gone"] },
      // { "Evening Alerts": ["About To Arrive", "Bus Left School", "went"] }]

      // const dat =[[{"studentName":"gokul","contactNo":"9962139969","AbouttoArrive":"sent","AboutToArrive":"sent","BusEnteredSchool":"sent",
      // "BusLeftSchool":"sent",
      // },{"studentName":"raj","contactNo":"996239969","AbouttoArrive":"sent","AboutToArrive": "notsent","BusEnteredSchool":"notsent",
      // "BusLeftSchool":"sent",
      // }], [{"Morning Alerts":["About to Arrive","Bus Entered School","gone"]},
      // {"Evening Alerts":["About To Arrive","Bus Left School","went"]}]]

      const studentsData =
        [
          {
            "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
              "ABS(M)": "sent"
            }, {
              "SPFST(E)": "sent"
            }, {
              "BATAT(M)": "notsent"
            }, {
              "ABS(Y)": "sent"
            }, {
              "ABS(I)": "sent"
            }, {
              "ABS(E)": "sent"
            }
            ]
          }
          ,
          {
            "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
              type: "ABS(M)", status: "sent"
            }, {
              type: "ABS(R)", status: "sent"
            }, {
              type: "ABS(T)", status: "sent"
            }, {
              type: "ABS(Y)", status: "sent"
            }, {
              type: "ABS(U)", status: "sent"
            }, {
              type: "ABS(I)", status: "sent"
            }, {
              type: "ABS(O)", status: "notsent"
            }, {
              type: "ABS(E)", status: "sent"
            }
            ]
          },
          {
            "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
              type: "ABS(M)", status: "sent"
            }, {
              type: "ABS(R)", status: "sent"
            }, {
              type: "ABS(T)", status: "sent"
            }, {
              type: "ABS(Y)", status: "sent"
            }, {
              type: "ABS(U)", status: "sent"
            }, {
              type: "ABS(I)", status: "sent"
            }, {
              type: "ABS(O)", status: "sent"
            }, {
              type: "ABS(E)", status: "sent"
            }
            ]
          },
          {
            "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
              type: "ABS(M)", status: "sent"
            }, {
              type: "ABS(R)", status: "sent"
            }, {
              type: "ABS(T)", status: "sent"
            }, {
              type: "ABS(Y)", status: "sent"
            }, {
              type: "ABS(U)", status: "sent"
            }, {
              type: "ABS(I)", status: "sent"
            }, {
              type: "ABS(O)", status: "sent"
            }, {
              type: "ABS(E)", status: "sent"
            }
            ]
          }
        ]

      //need to commit this line

      var data = []
      var test = {};
      this.w = ''
      var b = {}
      for (var i = 0; i < studentsData.length; i++) {
        for (var j = 0; j < studentsData[i].Alerts.length; j++) {
          this.w = studentsData[i].Alerts[j]["type"]
          var a = studentsData[i].Alerts[j]["status"]
          b[this.w] = a;
          test = Object.assign(test, b)
        }

        data.push(Object.assign(test, { StudentName: studentsData[i].StudentName, Contactno: studentsData[i].Contactno }))
      }
      this.tableData = data;


      for (var i = 0; i < studentsData[0].Alerts.length; i++) {

        if (studentsData[0].Alerts.length == i) {
          break;
        }
        this.column.push({ text: Object.keys(studentsData[0].Alerts[i]).toString(), datafield: Object.keys(studentsData[0].Alerts[i]).toString(), cellalign: "center", align: "center", cellsrenderer: this.renderer })

      }

      // for (var i = 0; i < Object.keys(attendance_datas).length; i++) {
      //   if (Object.keys(attendance_datas).length == i) {
      //     break;
      //   }
      //   this.columngroups.push(
      //     { text: Object.keys(attendance_datas[i]).toString(), align: 'center', name: Object.keys(attendance_datas[i]).toString() }
      //   )

      //   for (var j = 0; j < Object.values(attendance_datas[i])[0].length; j++) {
      //     this.column.push(
      //       { text: Object.values(attendance_datas[i])[0][j], columngroup: Object.keys(attendance_datas[i]).toString(), datafield: Object.values(attendance_datas[i])[0][j].replace(/\s+/g, ''), cellalign: "center", align: "center", cellsrenderer: this.renderer },
      //     )
      //   }

      // }
      // this.column.push({ text: this.trans.instant('Contact No'), datafield: 'contactNo', cellalign: "center", align: "center", cellsrenderer: this.renderer })


    }
    //else if (this.reportTitle == "idleVoilation") {
    else if (this.reportTitle == "idleViolation") {
      this.column = [
        { text: this.trans.instant('Plate No'), datafield: "Plate No", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle'), datafield: "Idle", cellsrenderer: this.renderer },
        //  { text: this.trans.instant('Begin'), datafield: "Begin", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Date'), datafield: "begindate", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin Time'), datafield: "begintime", cellsrenderer: this.renderer },
        // { text: this.trans.instant('End'), datafield: "End", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Date'), datafield: "enddate", cellsrenderer: this.renderer },
        { text: this.trans.instant('End Time'), datafield: "endtime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin At'), datafield: "Begin At", cellsrenderer: this.renderer },
        { text: this.trans.instant('End At'), datafield: "End At", cellsrenderer: this.renderer },
      ];

    }
    else if (this.reportTitle == "maintenance") {
      this.column = [
        { text: this.trans.instant('Asset Id'), datafield: "Asset Id", cellsrenderer: this.renderer },
        { text: this.trans.instant('Asset Type'), datafield: "Asset Type", cellsrenderer: this.renderer },
        { text: this.trans.instant('Current Hours (HH:MM)'), datafield: "Current Hours (HH:MM)", cellsrenderer: this.renderer },
        { text: this.trans.instant('Preventive Maintenance Interval (HH:MM)'), datafield: "Preventive Maintenance Interval (HH:MM)", cellsrenderer: this.renderer },
        { text: this.trans.instant('PM Due (Interval) (HH:MM)'), datafield: "PM Due (Interval) (HH:MM)", cellsrenderer: this.renderer },
        { text: this.trans.instant('Last PM Hours (HH:MM)'), datafield: "Last PM Hours (HH:MM)", cellsrenderer: this.renderer },
        { text: this.trans.instant('Last PM Time'), datafield: "Last PM Time", cellsrenderer: this.renderer },
      ];

    }

    else if (this.reportTitle == "employeeNoTransmission") {
      this.tableData = companyReportData.reportData;
      this.column = [
        { text: this.trans.instant('Zone name'), datafield: "zoneName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee Name'), datafield: "empName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Imei no'), datafield: "imeiNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Sim number'), datafield: "simNo", cellsrenderer: this.renderer },
        { text: this.trans.instant('Company name'), datafield: "companyName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Last Transmission'), datafield: "lastTransmission", cellsrenderer: this.renderer },
        { text: this.trans.instant('Supervisor'), datafield: "supervisor", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee id'), datafield: "empId", cellsrenderer: this.renderer },
      ];

    } else if (this.reportTitle == "employeeMonthlyDiscount") {
      this.tableData = companyReportData.reportData;
      this.column = [
        { text: this.trans.instant('Employee Name'), datafield: "empName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee Id'), datafield: "empId", cellsrenderer: this.renderer },
        { text: this.trans.instant('Supervisor'), datafield: "supervisor", cellsrenderer: this.renderer },
        { text: this.trans.instant('Work zone'), datafield: "workZone", cellsrenderer: this.renderer },
        { text: this.trans.instant('date'), datafield: "date", cellsrenderer: this.renderer },
        { text: this.trans.instant('Shift Time'), datafield: "shiftTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Total work time'), datafield: "totalWotkTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Total Idle Time'), datafield: "totalIdleTime", cellsrenderer: this.renderer },
        { text: this.trans.instant('Geozone out'), datafield: "geoZoneDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Total Idle-out Time'), datafield: "towedDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Avarage salary'), datafield: "avgSalary", cellsrenderer: this.renderer }
      ];

    }

    else if (this.reportTitle == "employeeIdleStatus") {
      this.tableData = companyReportData.reportData

      this.column = [
        { text: this.trans.instant('Employee Name'), datafield: "empName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee id'), datafield: "empId", cellsrenderer: this.renderer },
        { text: this.trans.instant('Work zone'), datafield: "zone", cellsrenderer: this.renderer },
        { text: this.trans.instant('Idle duration'), datafield: "idleDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Supervisor'), datafield: "supervisor", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin'), datafield: "Begin", cellsrenderer: this.renderer },
        { text: this.trans.instant('Begin at'), datafield: "Begin At", cellsrenderer: this.renderer },
        { text: this.trans.instant('End'), datafield: "End", cellsrenderer: this.renderer },
        { text: this.trans.instant('End at'), datafield: "End At", cellsrenderer: this.renderer },
      ];

    }
    else if (this.reportTitle == "employeeDayOffReport") {
      this.tableData = companyReportData.reportData
      this.column = [
        { text: this.trans.instant('Employee Name'), datafield: "EmployeeName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee id'), datafield: "EmployeeId", cellsrenderer: this.renderer },
        { text: this.trans.instant('Imei no'), datafield: "Imei", cellsrenderer: this.renderer },
        { text: this.trans.instant('Supervisor'), datafield: "Supervisor", cellsrenderer: this.renderer },
        { text: this.trans.instant('Zone name'), datafield: "WorkZone", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee day off'), datafield: "RestDay", cellsrenderer: this.renderer }
      ];

    }
    else if (this.reportTitle == "notransmissionreportWFT") {
      this.tableData = companyReportData.reportData
      this.column = [
        { text: this.trans.instant('Employee Name'), datafield: "employeeName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee id'), datafield: "employeeId", cellsrenderer: this.renderer },
        { text: this.trans.instant('Imei no'), datafield: "date", cellsrenderer: this.renderer },
        { text: this.trans.instant('Zone name'), datafield: "zoneName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Slot'), datafield: "userId", cellsrenderer: this.renderer }
      ];

    }
    else if (this.reportTitle == "employeeWorkOutOffZone") {
      this.tableData = companyReportData.reportData;
      this.column = [
        { text: this.trans.instant('Employee Name'), datafield: "empName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Employee id'), datafield: "empId", cellsrenderer: this.renderer },
        { text: this.trans.instant('Zone name'), datafield: "zoneName", cellsrenderer: this.renderer },
        { text: this.trans.instant('Outoff zone duration'), datafield: "outOfZoneDuration", cellsrenderer: this.renderer },
        { text: this.trans.instant('Work in zone duration'), datafield: "workInIt", cellsrenderer: this.renderer },
        { text: this.trans.instant('Supervisor'), datafield: "supervisor", cellsrenderer: this.renderer }
      ];

    }
    else if (this.reportTitle == "recurrence") {
      this.clickmode = 'checkbox'
      this.tableData = companyReportData.recurrenceData;

      this.column = [
        { text: this.trans.instant('Status'), datafield: "status", cellsrenderer: this.renderer },
        { text: this.trans.instant('Created On'), datafield: "createdon", cellsrenderer: this.renderer },
        { text: this.trans.instant('Type'), datafield: "type", cellsrenderer: this.renderer },
        { text: this.trans.instant('Plate No'), datafield: "plateNoCon", cellsrenderer: this.renderer },
        { text: this.trans.instant('Email Address'), datafield: "emailAddress", cellsrenderer: this.renderer },
      ];

    } else if (this.reportTitle == "HiringCompany") {
      this.tableData = companyReportData.reportData
      this.column = [
        { text: this.trans.instant("Plate No"), datafield: "plateNo", cellsrenderer: this.renderer, },
        { text: this.trans.instant("Hiringcompany"), datafield: "hiringcompany", cellsrenderer: this.renderer, },
        { text: this.trans.instant("Eff From"), datafield: "effForm", cellsrenderer: this.renderer, },
        { text: this.trans.instant("Eff To"), datafield: "effTo", cellsrenderer: this.renderer, },

        { text: this.trans.instant("Last Upd By"), datafield: "lastUpdBy", cellsrenderer: this.renderer, },
        { text: this.trans.instant("Last Upd Dt"), datafield: "lastUpdDt", cellsrenderer: this.renderer, },

        // { text: this.trans.instant("Vin"), datafield: "vin", cellsrenderer: this.renderer, },
      ];
    }


    this.source = { localdata: this.tableData };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.columns = this.column;
    this.additionalcolumn()
    this.commonService.extracolumn
    if (this.column.length > 14){
      this.commonService.extracolumn=true
      this.widthPercentage = (8 * this.column.length) + '%';
    }
    else{
      this.commonService.extracolumn=false
      this.widthPercentage = "100%"
    }
      

    if (this.myGrid)
      this.myGrid.updatebounddata();
  }
  additionalcolumn() {
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('vg')) {
      const Exists = this.column.some(item => item.datafield === "vg");
      if (!Exists)
        this.column.push({ text: this.trans.instant('Group'), datafield: "vg", cellsrenderer: this.renderer });

    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('voc')) {
      const Exists = this.column.some(item => item.datafield === "voc");
      if (!Exists)
        this.column.push({ text: this.trans.instant('Vehicle Operation code'), datafield: "voc", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('on')) {
      const Exists = this.column.some(item => item.datafield === "on");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Operator'), datafield: "on", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('in')) {
      const Exists = this.column.some(item => item.datafield === "in");
      if (!Exists)
      this.column.push({ text: this.trans.instant('IMEI No'), datafield: "in", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('sn')) {
      const Exists = this.column.some(item => item.datafield === "sn");
      if (!Exists)
      this.column.push({ text: this.trans.instant('SIM No'), datafield: "sn", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('dm')) {
      const Exists = this.column.some(item => item.datafield === "dm");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Device Make'), datafield: "dm", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('dmn')) {
      const Exists = this.column.some(item => item.datafield === "dmn");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Device Model'), datafield: "dmn", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('dp')) {
      const Exists = this.column.some(item => item.datafield === "dp");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Date of Purchase'), datafield: "dp", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('iu')) {
      const Exists = this.column.some(item => item.datafield === "iu");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Asset category'), datafield: "iu", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('vm')) {
      const Exists = this.column.some(item => item.datafield === "vm");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Vehicle Model'), datafield: "vm", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('vt')) {
      const Exists = this.column.some(item => item.datafield === "vt");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Vehicle type'), datafield: "vt", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('vy')) {
      const Exists = this.column.some(item => item.datafield === "vy");
      if (!Exists)
      this.column.push({ text: this.trans.instant('vehicle Year'), datafield: "vy", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('gc')) {
      const Exists = this.column.some(item => item.datafield === "gc");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Color'), datafield: "gc", cellsrenderer: this.renderer })
    }
    if (this.tableData[0] && this.tableData[0].hasOwnProperty('dbm')) {
      const Exists = this.column.some(item => item.datafield === "dbm");
      if (!Exists)
      this.column.push({ text: this.trans.instant('Debugging Mode'), datafield: "dbm", cellsrenderer: this.renderer })
    }
  }
  updateFilteredRows() {
    const filteredRows = this.myGrid.getrows();
    this.commonService.filter = filteredRows;
  }
  myGridOnSort(event: any): void {
    this.myPanel.clearcontent();
    let sortinformation = event.args.sortinformation;
    let sortdirection = sortinformation.sortdirection.ascending ? 'ascending' : 'descending';
    if (!sortinformation.sortdirection.ascending && !sortinformation.sortdirection.descending) {
      sortdirection = 'null';
    }
    let eventData = 'Triggered "sort" event <div>Column:' + sortinformation.sortcolumn + ', Direction: ' + sortdirection + '</div>';
    this.myPanel.prepend('<div style="margin-top: 5px;">' + eventData + '</div>');
  };

  selectOpenNavigation(event) {
    if (event.args.row["Begin At"]) {
      window.open("http://www.google.com/maps/place/" + event.args.row["Begin At"].split(",")[0] + "," + event.args.row["Begin At"].split(",")[1])
    } else if (event.args.row["startLatLng"]) {
      window.open("http://www.google.com/maps/place/" + event.args.row["startLatLng"].split(",")[0] + "," + event.args.row["startLatLng"].split(",")[1])
    } else if (event.args.row["address"]) {
      window.open("http://www.google.com/maps/place/" + event.args.row["address"].split(",")[0] + "," + event.args.row["address"].split(",")[1])
    } else if (event.args.row["Begin Location"]) {
      window.open("http://www.google.com/maps/place/" + event.args.row["Begin Location"].split(",")[0] + "," + event.args.row["Begin Location"].split(",")[1])
    } else if (event.args.row["endLocation"]) {
      window.open("http://www.google.com/maps/place/" + event.args.row["endLocation"].split(",")[0] + "," + event.args.row["endLocation"].split(",")[1])
    }
    else if (event.args.row["Violation Place"]) {
      window.open("http://www.google.com/maps/place/" + event.args.row["Violation Place"].split(",")[0] + "," + event.args.row["Violation Place"].split(",")[1])
    } else if (event.args.row["Address"]) {
      window.open("http://www.google.com/maps/place/" + event.args.row["Address"].split(",")[0] + "," + event.args.row["Address"].split(",")[1])
    }

  }


  deleteRecurrenceData() {
    var detailsString = this.myGrid.getselectedrowindexes();
    if (detailsString.length > 0) {
      this.multiRows = "";
      for (var s in detailsString) {
        var row = this.myGrid.getrowdata(detailsString[s]);
        this.multiRows = row.id + "," + this.multiRows
      }
    }
    var multidlt = {};
    multidlt["companyId"] = localStorage.corpId;
    multidlt["branchId"] = localStorage.corpId;
    multidlt["userId"] = localStorage.userName;
    multidlt["id"] = this.multiRows;
    var jsondetails = JSON.stringify(multidlt);
    let url = serverUrl.web + "/ReportController/deletrecurrencedata/" + jsondetails;
    this.ajaxService.ajaxGetJson(url)
      .subscribe(res => {
        this.commonService.presentToast("Deleted successfully")
        this.getrecurrencedata();
      })
  }
  startRecurrence() {
    var detailsString = this.myGrid.getselectedrowindexes();
    if (detailsString.length > 0) {
      this.multiRows = "";
      for (var s in detailsString) {
        var row = this.myGrid.getrowdata(detailsString[s]);
        this.multiRows = row.id + "," + this.multiRows
      }
    }
    var multidlt = {};
    multidlt["companyId"] = localStorage.corpId;
    multidlt["branchId"] = localStorage.corpId;
    multidlt["userId"] = localStorage.userName;
    multidlt["start"] = "start";
    multidlt["id"] = this.multiRows;
    var jsondetails = JSON.stringify(multidlt);
    let url = serverUrl.web + "/ReportController/stoprecurrence/" + jsondetails;
    this.ajaxService.ajaxGetJson(url)
      .subscribe(res => {
        this.commonService.presentToast("Started successfully")
        this.getrecurrencedata();
      })
  }
  stopRecurrence() {
    var detailsString = this.myGrid.getselectedrowindexes();
    if (detailsString.length > 0) {
      this.multiRows = "";
      for (var s in detailsString) {
        var row = this.myGrid.getrowdata(detailsString[s]);
        this.multiRows = row.id + "," + this.multiRows
      }
    }
    var multidlt = {};
    multidlt["companyId"] = localStorage.corpId;
    multidlt["branchId"] = localStorage.corpId;
    multidlt["userId"] = localStorage.userName;
    multidlt["id"] = this.multiRows;
    var jsondetails = JSON.stringify(multidlt);
    let url = serverUrl.web + "/ReportController/stoprecurrence/" + jsondetails;
    this.ajaxService.ajaxGetJson(url)
      .subscribe(res => {
        this.commonService.presentToast("Stoped successfully")
        this.getrecurrencedata();
      })
  }

  getrecurrencedata() {
    var logindata = {};
    logindata["companyID"] = localStorage.corpId;
    logindata["branchID"] = localStorage.corpId;
    logindata["userID"] = localStorage.userName;
    var jsondetails = JSON.stringify(logindata);
    const url = serverUrl.web + "/ReportController/getRecurrencedata/" + jsondetails;
    this.ajaxService.ajaxGetObject(url)
      .subscribe(res => {
        companyReportData.recurrenceData = JSON.parse(res)
        companyReportData.recurrenceData.map(re => {
          var plateNodata = ""
          re.plateno.split(",").map(de => {
            plateNodata += storageVariable.dashboardData.liveDatas[de].plateNo + ","
          })
          re["plateNoCon"] = plateNodata
        })
        this.tableDetails();
        this.myGrid.updatebounddata();
        this.myGrid.clearselection();
      });
  }

  ngOnInit() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
        this.tableDetails();
      })
    this.tableDetails();
    this.localizationobj = {
      groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
    };
  }


}



